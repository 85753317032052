import React, { useState, useContext } from "react";
import { Modal, Input, Select, Checkbox, Button } from "antd";

import { AuthCountext } from "../AuthCountext";
import FetchInventory from "./FetchInventoryData";
import AlertComp from "../subcomponent/AlertComp";
import { DeleteFilled, EditFilled } from "@ant-design/icons";
import MyTable from "./MyTable";
import SearchItem from "./SearchItem";

const ItemUnitsComp = ({ openModal, setOpenModal }) => {
  const { theme, ACCOUNNTING_URL, setaccountData, userToken, branchId } =
    useContext(AuthCountext);
  const [isloading, setIsloading] = useState(true);
  const [disable, setDisable] = useState(false);
  const [isRefresh, setIsRefresh] = useState(false);
  const [alertMsg, setAlertMsg] = useState(null);
  const [alertName, setAlertName] = useState(null);
  const [isAlert, setIsAlert] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [confirmEditLoading, setConfirmEditLoading] = useState(false);
  const [openEditModal, setOpenEditModal] = useState(false);
  const unitList = FetchInventory().units;
  const [formData, setFormData] = useState({
    ItemUnitID: null,
    UnitID: null,
    ConversionRate: 1,
    IsMainUnit: true,
    company_id: branchId,
    created_by: userToken,
  });

  // insert data

  const addData = async () => {
    setDisable(true);
    setIsRefresh(!isRefresh);
    try {
      const response = await fetch(`${ACCOUNNTING_URL}/ItemUnits`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "x-api-key": userToken,
        },
        body: JSON.stringify(formData),
      });
      const json = await response.json();
      if (json.msg === "ItemUnits added!") {
        setIsRefresh(!isRefresh);
        setIsAlert(true);
        setAlertMsg("تمت اضافة السجل بنجاح");
        setAlertName("alert-success");
        alertShow();
        setIsRefresh(!isRefresh);
        setDisable(false);
        setaccountData(true);
      }
    } catch (error) {
      console.error(error);
      setIsAlert(true);
      setAlertMsg("ادخل جميع الحقول");
      setAlertName("alert-warning");
      alertShow();
    }
  };

  const validationFun = () => {
    if (
      formData.ConversionRate !== null ||
      formData.Name !== "" ||
      formData.company_id !== "" ||
      formData.UnitID !== ""
    ) {
      addData();
      setDisable(true);
      setConfirmLoading(true);
      setTimeout(() => {
        setOpenModal(false);
        setConfirmLoading(false);
      }, 2000);
    } else {
      console.log("no valid");
      setIsAlert(true);
      setAlertMsg("ادخل جميع الحقول");
      setAlertName("alert-warning");
      alertShow();
    }
  };

  // end insert data

  // delete
  const deleteData = async (id) => {
    try {
      const response = await fetch(
        `${ACCOUNNTING_URL}/ItemUnits/delete/${id}`,
        {
          method: "DELETE",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "x-api-key": userToken,
          },
        }
      );
      const json = await response.json();
      setIsRefresh(!isRefresh);
      setIsAlert(true);
      setAlertMsg("تم حذف السجل بنجاح");
      setAlertName("alert-success");
      alertShow();
      if (json.errore.errorInfo[0] === "23000") {
        setIsAlert(true);
        setAlertMsg("لا يمكنك حذف السجل لارتباطه بسجلات اخرى.");
        setAlertName("alert-danger");
      }
      alertShow();
    } catch (error) {
      console.error(error);
    }
  };

  const alertShow = () => {
    const timer = setTimeout(() => setIsAlert(false), 3000);
    return () => {
      clearTimeout(timer);
    };
  };

  // end delete

  // edit
  const [editFormData, setEditFormData] = useState({
    ItemUnitID: null,
    ItemID: null,
    UnitID: null,
    ConversionRate: null,
    IsMainUnit: false,
    company_id: branchId,
    updated_by: userToken,
  });

  const editData = async (id) => {
    try {
      const response = await fetch(`${ACCOUNNTING_URL}/ItemUnits/`, {
        method: "PUT",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "x-api-key": userToken,
        },
        body: JSON.stringify(editFormData),
      });
      const json = await response.json();
      if (json?.msg === "1 records updated successully") {
        setIsAlert(true);
        setAlertMsg("تم تعديل السجل بنجاح");
        setAlertName("alert-success");
        alertShow();
        setIsRefresh(!isRefresh);
        setConfirmEditLoading(true);
        setTimeout(() => {
          setOpenEditModal(false);
          setConfirmEditLoading(false);
        }, 1000);
      }
    } catch (error) {
      console.error(error);
      setIsAlert(true);
      setAlertMsg(`حدث خطأ ما`);
      setAlertName("alert-info");
      alertShow();
    }
  };

  const editValidationFun = () => {
    if (
      editFormData.ItemUnitID !== null &&
      editFormData.ItemUnitID !== null &&
      editFormData.ConversionRate !== null
    ) {
      editData(editFormData.ItemUnitID);
    } else {
      console.log("no valid");
      setIsAlert(true);
      setAlertMsg("ادخل جميع الحقول");
      setAlertName("alert-warning");
      setOpenEditModal(false);
      alertShow();
    }
  };
  // function handleEditSubmit(event) {
  //   event.preventDefault();
  //   editValidationFun();
  //   // setDisabledAria(false);
  // }

  function handleEdit(x, y) {
    setEditFormData((prev) => {
      return {
        ...prev,
        ItemUnitID: x.ItemUnitID,
        ItemID: x.ItemID,
        UnitID: x.UnitID,
        ConversionRate: x.ConversionRate,
        IsMainUnit: x.IsMainUnit,
        updated_by: userToken,
      };
    });
    setOpenEditModal(true);
  }

  function handleEditCancel() {
    setEditFormData(null);
    setOpenEditModal(false);
    setConfirmEditLoading(false);
  }

  // end edit

  // others

  const columns = [
    {
      title: "حذف",
      render: (_, record) => (
        <Button
          danger
          color="danger"
          type="text"
          onClick={() => deleteData(record.ItemUnitID, true)}
          id={record.ItemUnitID}
          loading={isloading}
          icon=<DeleteFilled />
        >
          حذف
        </Button>
      ),
    },
    {
      title: "تعديل",
      render: (_, record) => (
        <Button
          color="primary"
          className={`color-primary-${theme}`}
          type="text"
          onClick={() => handleEdit(record, true)}
          id={record.ItemUnitID}
          disable={disable}
          loading={isloading}
          icon=<EditFilled />
        >
          تعديل
        </Button>
      ),
    },
    {
      title: "معامل التحويل",
      dataKey: "ConversionRate",
      render: (_, record) => <span>{Math.round(record.ConversionRate)}</span>,
    },
    {
      title: "نوع الوحدة",
      dataKey: "IsMainUnit",
      render: (_, record) => (
        <span>
          {record.IsMainUnit === 1 || record.IsMainUnit === "1"
            ? "رئيسية"
            : "فرعية"}
        </span>
      ),
    },
    { title: "اسم الوحدة", dataKey: "UnitName" },
    { title: "اسم الصنف", dataKey: "Name" },
    { title: "رقم وحدة الصنف", dataKey: "ItemUnitID" },
  ];

  const options = [
    {
      value: "ItemUnits",
      label: "قائمة وحدات الأصناف",
      pageDirction: "p",
    },
  ];

  return (
    <div
      className={`${
        isloading
          ? "flex flex-row justify-center align-center "
          : " justify-space-between "
      } flex flex-column justify-center media-flex-culomn align-flex-start shadow-${theme} full-width-media border-radius text-align-center background-div-${theme} full-width min-height-300 p-20`}
      style={{ fontSize: "small" }}
    >
      <div className="full-width" style={{ minHeight: "580px" }}>
        <div className="flex flex-row justify-space-between align-center gap-10">
          {isAlert && <AlertComp msg={alertMsg} alert={alertName} />}

          <Modal
            key={1}
            title="إضافة وحدة صنف"
            open={openModal}
            onOk={validationFun}
            confirmLoading={confirmLoading}
            onCancel={() => setOpenModal(false)}
            destroyOnClose
          >
            <form
              className="flex flex-column justify-center align-center gap-20 media-flex-column full-width"
              style={{ padding: "20px" }}
            >
              {isAlert && <div className={`${alertName}`}>{alertMsg}</div>}
              <div className="full-width">
                <span>الصنف</span>
                <SearchItem setFormData={setFormData} />
              </div>
              <div className="full-width">
                <span>الوحدة</span>
                <Select
                  name="UnitID"
                  showSearch
                  className={`full-width height-50 border-radius`}
                  onChange={(e) => {
                    setFormData((prev) => {
                      return {
                        ...prev,
                        UnitID: e,
                      };
                    });
                  }}
                  optionFilterProp="label"
                  placeholder={isloading ? "جارٍ التحميل" : "الوحدة"}
                  options={unitList.map((item) => ({
                    value: item.UnitID,
                    label: `${item.UnitID} - ${item.UnitName}`,
                  }))}
                />
              </div>
              <div className="full-width">
                <Checkbox
                  name="IsMainUnit"
                  defaultChecked={formData?.IsMainUnit}
                  onChange={(e) => {
                    setFormData((prev) => {
                      return {
                        ...prev,
                        IsMainUnit: 1 ? e.target.checked : 0,
                        ConversionRate: e.target.checked ? 1 : 0,
                      };
                    });
                  }}
                  style={{ width: "100%" }}
                >
                  وحدة رئيسية
                </Checkbox>
              </div>
              <div className="full-width">
                <span>معدل التحويل</span>
                <Input
                  type="text"
                  name="ConversionRate"
                  className={`full-width height-50 border-radius background-div-${theme} color-${theme}`}
                  placeholder="معدل التحويل"
                  disabled={formData.IsMainUnit}
                  value={formData?.ConversionRate}
                  defaultValue={
                    formData?.ConversionRate && formData?.ConversionRate
                  }
                  onChange={(e) => {
                    setFormData((prev) => {
                      return {
                        ...prev,
                        ConversionRate: e.target.value,
                      };
                    });
                  }}
                />
              </div>
            </form>
          </Modal>
          <Modal
            key={2}
            title="تعديل صنف"
            open={openEditModal}
            onOk={editValidationFun}
            confirmLoading={confirmEditLoading}
            onCancel={handleEditCancel}
            destroyOnClose={true}
          >
            <form
              className="flex flex-column justify-center align-center gap-20 media-flex-column full-width"
              style={{ padding: "20px" }}
            >
              {isAlert && <div className={`${alertName}`}>{alertMsg}</div>}
              <div className="full-width">
                <span>الصنف</span>
                <SearchItem
                  setFormData={setEditFormData}
                  formData={editFormData}
                />
              </div>
              <div className="full-width">
                <span>الوحدة</span>
                <Select
                  name="UnitID"
                  showSearch
                  className={`full-width height-50 border-radius`}
                  defaultValue={editFormData?.UnitID}
                  onChange={(e) => {
                    setEditFormData((prev) => {
                      return {
                        ...prev,
                        UnitID: e,
                      };
                    });
                  }}
                  optionFilterProp="label"
                  placeholder={isloading ? "جارٍ التحميل" : "الوحدة"}
                  options={unitList.map((item) => ({
                    value: item.UnitID,
                    label: `${item.UnitID} - ${item.UnitName}`,
                  }))}
                />
              </div>
              <div className="full-width">
                <Checkbox
                  name="IsMainUnit"
                  defaultChecked={
                    editFormData?.IsMainUnit === "1" ||
                    editFormData?.IsMainUnit === 1
                  }
                  onChange={(e) => {
                    setEditFormData((prev) => {
                      return {
                        ...prev,
                        IsMainUnit: 1 ? e.target.checked : 0,
                        ConversionRate: e.target.checked ? 1 : 0,
                      };
                    });
                  }}
                  style={{ width: "100%" }}
                >
                  وحدة رئيسية
                </Checkbox>
              </div>
              <div className="full-width">
                <span>معدل التحويل</span>
                <Input
                  type="text"
                  name="ConversionRate"
                  className={`full-width height-50 border-radius background-div-${theme} color-${theme}`}
                  placeholder="معدل التحويل"
                  disabled={
                    editFormData?.IsMainUnit === "1" ||
                    editFormData?.IsMainUnit === 1
                  }
                  defaultValue={editFormData?.ConversionRate}
                  onChange={(e) => {
                    setEditFormData((prev) => {
                      return {
                        ...prev,
                        ConversionRate: e.target.value,
                      };
                    });
                  }}
                />
              </div>
            </form>
          </Modal>
        </div>
        <MyTable
          setIsloading={setIsloading}
          isRefresh={isRefresh}
          isloading={isloading}
          columns={columns}
          page="itemunits"
          options={options}
        />
      </div>
    </div>
  );
};

export default ItemUnitsComp;
