import React, { memo, useContext } from "react";
import { Segmented, Statistic, Card, Table, Button } from "antd";
import { AuthCountext } from "../AuthCountext";
import AlertComp from "../subcomponent/AlertComp";
import { Link } from "react-router-dom";
import { EditFilled, FilePdfFilled } from "@ant-design/icons";
import { createPdf } from "./PdfReport";
import FetchLogo from "./FetchLogo";
import FetchBranch from "./FetchBranch";


const ViewComponent = ({
  columns,
  formData,
  handleSubmit,
  isAlert,
  alertMsg,
  alertName,
  isloading,
  title,
  orderType,
  id,
  edit,
  reportName,
}) => {
  const { theme, userName } = useContext(AuthCountext);
  const logo = FetchLogo();
  const branchData = FetchBranch();

  const options = [
    {
      value: edit,
      label: formData[reportName],
      pageDirction: "p",
    },
  ];

  
  return (
    <div
      className={`
        flex flex-column justify-center media-flex-culomn align-center shadow-${theme} full-width-media border-radius text-align-center background-div-${theme} min-height-300 p-20`}
      style={{ fontSize: "small", width: "100%" }}
    >
      {isAlert && <AlertComp msg={alertMsg} alert={alertName} />}
      <div
        className="flex flex-row justify-center align-start full-width"
        style={{ minHeight: "580px", position: "relative" }}
      >
        <div
          className="flex flex-row  position-absolute"
          style={{ left: "0", top: "0", zIndex: "9" }}
        >
          <Button
            color="primary"
            className={`color-primary-${theme}`}
            style={{ left: "0", top: "0", zIndex: "9" }}
            type="text"
            id={formData[id]}
            onClick={() => {
              createPdf(
                formData.invoicedetails,
                columns.toReversed(),
                null,
                null,
                branchData,
                userName,
                logo,
                false,
                edit,
                options
              );
            }}
            loading={isloading}
            icon=<FilePdfFilled />
          >
            تنزيل
          </Button>
          <Link to={`/inventory/${edit}/${formData[id]}`}>
            <Button
              color="primary"
              className={`color-primary-${theme}`}
              type="text"
              id={formData[id]}
              loading={isloading}
              icon=<EditFilled />
            >
              تعديل
            </Button>
          </Link>
        </div>
        <div
          className="flex flex-column gap-10 full-width media-flex-culomn"
          style={{ padding: "20px" }}
        >
          <h1>{title}</h1>
          <div
            className="flex flex-column flex-wrap justify-center full-width align-start gap-20 media-flex-column"
            onSubmit={handleSubmit}
          >
            <div className="flex flex-row full-width justify-space-between media-flex-culomn">
              <div className="flex flex-column gap-5 align-start">
                <span>حالة الطلبية</span>
                <Segmented
                  options={[
                    {
                      value: "Pending",
                      label: "قيد الانتظار",
                    },
                    {
                      value: "Completed",
                      label: "مكتملة",
                    },
                    {
                      value: "Cancelled",
                      label: "تم الإلغاء",
                    },
                  ]}
                  value={formData.Status}
                />
              </div>
              <div className="flex flex-column gap-5 align-start">
                <span>حالة الدفع</span>
                <Segmented
                  options={[
                    {
                      value: "Pending",
                      label: "قيد الانتظار",
                    },
                    {
                      value: "Paid",
                      label: "مدفوعة",
                    },
                  ]}
                  value={formData.PaymentStatus}
                />
              </div>
            </div>
            <div className="flex flex-row justify-space-between gap-10 full-width media-flex-culomn">
              <div
                className="flex flex-column gap-10 full-width media-flex-culomn"
                style={{ flexGrow: "1" }}
              >
                <div className="flex flex-row gap-10 align-start">
                  {orderType === "PurchaseOrder" ? (
                    <>
                      <div className="flex flex-row gap-10 align-start">
                        <span style={{ fontWeight: "bold" }}> الرقم</span>
                        <span style={{ textAlign: "start" }}>
                          {formData.PurchaseOrderID}
                        </span>
                      </div>
                      <div className="flex flex-row gap-10 align-start">
                        <span style={{ fontWeight: "bold" }}> رقم المستند</span>
                        <span style={{ textAlign: "start" }}>
                          {formData.DocNumber}
                        </span>
                      </div>
                    </>
                  ) : (
                    <>
                      <span style={{ fontWeight: "bold" }}> الرقم</span>
                      <span style={{ textAlign: "start" }}>
                        {formData.InvoiceID}
                      </span>
                    </>
                  )}
                </div>
                <div className="flex flex-row gap-10 align-start">
                  {orderType === "PurchaseOrder" ? (
                    <>
                      <span style={{ fontWeight: "bold" }}> المورد</span>
                      <span style={{ textAlign: "start" }}>
                        {formData.supplierName}
                      </span>
                    </>
                  ) : (
                    <>
                      <span style={{ fontWeight: "bold" }}> العميل</span>
                      <span style={{ textAlign: "start" }}>
                        {formData.buyerName}
                      </span>
                    </>
                  )}
                </div>

                <div
                  className="flex flex-row gap-10 align-start"
                  style={{ width: "150px" }}
                >
                  <span style={{ fontWeight: "bold" }}>التاريخ</span>
                  {orderType === "PurchaseOrder" ? (
                    <span>{formData.OrderDate}</span>
                  ) : (
                    <span>{formData.InvoiceDate}</span>
                  )}
                </div>
              </div>
              <div className="flex flex-row gap-10 justify-space-between media-flex-culomn">
                <Card size="small" bordered={false}>
                  <Statistic title="الضريبة" value={formData?.TotalVat} />
                </Card>
                <Card size="small" bordered={false}>
                  <Statistic title="الإجمالي" value={formData?.TotalAmount} />
                </Card>
              </div>
            </div>
            <div
              className={`full-width over-flow-scroll over-flow-scroll-${theme} media-flex-culomn`}
            >
              <Table
                className='full-width'
                style={{minWidth: '600px'}}
                columns={columns}
                dataSource={formData.invoicedetails}
                pagination={false}
                loading={isloading}
                scroll={{
                  y: 55 * 5,
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default memo(ViewComponent);
