import React, { useState, useContext, useEffect, useCallback } from "react";
import { useImmer } from "use-immer";
import { AuthCountext } from "../AuthCountext";
import { useParams } from "react-router-dom";
import ViewComponent from "./ViewComponent";

const todyDate = new Date().toLocaleDateString();

const PurchaseOrdersVeiwComp = () => {
  const { ACCOUNNTING_URL, userToken, branchId } = useContext(AuthCountext);
  const { id } = useParams();
  const [isloading, setIsloading] = useState(true);
  const [formData, setFormData] = useImmer({
    SupplierID: null,
    Status: "Pending",
    TotalAmount: 1,
    TotalVat: 1,
    PaymentStatus: "Pending",
    OrderDate: null,
    CreatedBy: userToken,
    WarehouseID: 1,
    invoicedetails: [
      {
        id: Date.now(), // Unique ID
        ItemID: null,
        UnitID: null,
        Quantity: 1,
        UnitPrice: 1,
        TotalPrice: 1,
        Vat: 1,
        ConversionRate: 1,
        type: "purchase",
      },
    ],
  });

  const fetchData = async () => {
    setIsloading(true);
    try {
      const response = await fetch(
        `${ACCOUNNTING_URL}/purchaseorders/${id}/${branchId}`,
        {
          method: "GET",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "x-api-key": userToken,
          },
        }
      );
      const json = await response.json();
      if (json.msg !== "No data") {
        console.log(json);
        setFormData((draft) => {
          draft.updated_by = userToken;
          draft.updated_at = todyDate;
          draft.SupplierID = json?.purchase.SupplierID;
          draft.PurchaseOrderID = json?.purchase.PurchaseOrderID;
          draft.supplierName = json?.purchase.supplierName;
          draft.TotalAmount = json?.purchase.TotalAmount;
          draft.TotalVat = json?.purchase.TotalVat;
          draft.PaymentStatus = json?.purchase.PaymentStatus;
          draft.Status = json?.purchase.Status;
          draft.WarehouseID = json?.purchase.WarehouseID;
          draft.WarehouseName = json?.purchase.WarehouseName;
          draft.OrderDate = json?.purchase.OrderDate;
          draft.invoicedetails = json?.details;
        });
        loader();
      } else {
      }
      loader();
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    fetchData();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ACCOUNNTING_URL]);

  const loader = () => {
    const timer = setTimeout(() => setIsloading(false), 1000);
    return () => {
      clearTimeout(timer);
    };
  };

  loader();

  const roundFunction = useCallback((x) => {
      return Math.round((x + Number.EPSILON) * 100) / 100;
    }, []);

  const columns = [
    {
      title: "الصنف",
      value: "الصنف",
      dataIndex: "Name",
      dataKey: "Name",
    },
    {
      title: "الوحدة",
      value: "الوحدة",
      dataIndex: "UnitName",
      dataKey: "UnitName",
    },
    {
      title: "الكمية",
      value: "الكمية",
      dataIndex: "Quantity",
      dataKey: "Quantity",
      render: (text, data) => {
        return <span>{roundFunction(text * (data?.ConversionRate || 1))}</span>;
      },
    },
    {
      title: "سعر الوحدة",
      value: "سعر الوحدة",
      dataIndex: "UnitPrice",
      dataKey: "UnitPrice",
    },
    {
      title: "الضريبة",
      value: "الضريبة",
      dataIndex: "Vat",
      dataKey: "Vat",
    },
    {
      title: "الاجمالي",
      value: "الاجمالي",
      dataIndex: "TotalPrice",
      dataKey: "TotalPrice",
    },
  ];

  return (
    <ViewComponent
      title={"عرض أمر توريد مخزني - فاتورة مورد"}
      columns={columns}
      formData={formData}
      isloading={isloading}
      orderType={"PurchaseOrder"}
      edit='PurchaseOrdersEdit'
      id='PurchaseOrderID'
      reportName={'supplierName'}
    />
  );
};

export default PurchaseOrdersVeiwComp;
