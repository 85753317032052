import React, { useContext, useEffect, useState } from "react";
import { AuthCountext } from "../AuthCountext";
import { Select } from "antd";

function SearchItem({ setFormData, formData }) {
  const { ACCOUNNTING_URL, userToken, branchId } = useContext(AuthCountext);
  const [items, setItems] = useState([]);
  const [isloading, setIsloading] = useState(false);
  const handleSearch = async (q) => {
    setIsloading(true);
    if (q) {
      try {
        const response = await fetch(
          `${ACCOUNNTING_URL}/items/${branchId}/${q}`,
          {
            method: "GET",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
              "x-api-key": userToken,
            },
          }
        );
        const json = await response.json();

        if (json.length > 0) {
          setItems(json);
        } else {
          setItems([]);
        }
      } catch (error) {
        console.error(error);
      } finally {
        setIsloading(false);
      }
    } else {
        setItems([])
    }
  };

  useEffect(() => {
    handleSearch(formData?.ItemID);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formData]);
  return (
    <Select
      autoFocus
      name="ItemID"
      showSearch
      className={`full-width height-50 border-radius`}
      onSearch={handleSearch}
      onChange={(e) => {
        setFormData((prev) => {
          return {
            ...prev,
            ItemID: e,
          };
        });
      }}
      optionFilterProp="label"
      placeholder={isloading ? "جارٍ التحميل" : "ابحث في الأصناف"}
      defaultValue={formData?.ItemID}
      options={items?.map((item) => ({
        value: item.ItemID,
        label: `${item.ItemID} - ${item.Name}`,
      }))}
    />
  );
}

export default SearchItem;
