import ParentComp from "../ParentComp";
import PurchaseOrdersComp from "./PurchaseOrdersComp";

function PurchaseOrders() {
  return (
    <ParentComp
      title={"أمر شراء"}
      prevPage={"الوارد"}
      linkto={"/inventory/PurchaseOrdersList"}
    >
      <PurchaseOrdersComp />
    </ParentComp>
  );
}

export default PurchaseOrders;
