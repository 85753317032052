import { Button } from "antd";
import ParentComp from "../ParentComp";
import InvoicesListComp from "./InvoicesListComp";
import { useState } from "react";
import { Link } from "react-router-dom";

function InvoicesList() {
  const [openModal, setOpenModal] = useState(false);
  const ParentButton = () => {
    return (
      <Link to="/inventory/invoices">
        <Button type="primary" onClick={() => setOpenModal(true)}>
        إضافة أمر صرف
      </Button>
      </Link>
    );
  };

  
  return (
    <ParentComp
      title={"الصادر"}
      prevPage={"نظام المخازن"}
      linkto={"/inventory"}
      button={<ParentButton />}
    >
      <InvoicesListComp openModal={openModal} setOpenModal={setOpenModal} />
    </ParentComp>
  );
}

export default InvoicesList;
