import { useContext, useEffect, useState } from "react";
import { AuthCountext } from "../AuthCountext";


function FetchAccount() {
  const { ACCOUNNTING_URL, userToken, branchId } = useContext(AuthCountext);
  const [data, setData] = useState([]);
  const [financialPreiod, setFinancialPreiod] = useState([])
  const [accounttypes, setAccounttypes] = useState([])

  const fetchAccount = async () => {
    try {
      const response = await fetch(`${ACCOUNNTING_URL}/account/${branchId}`, {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "x-api-key": userToken,
        },
      });
      const json = await response.json();

      if (json.length > 0) {
        setData(json);
      } else {
        setData([]);
      }
    } catch (error) {
      console.error(error);
    }
  };

  

  const fetchFinancialPeriod = async () => {
    try {
      const response = await fetch(`${ACCOUNNTING_URL}/financialperiod/${branchId}`, {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "x-api-key": userToken,
        },
      });
      const json = await response.json();

      if (json.length > 0) {
        setFinancialPreiod(json);
      } else {
        setFinancialPreiod([]);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const fetchAccountType = async () => {
    try {
      const response = await fetch(`${ACCOUNNTING_URL}/accounttypes/${branchId}`, {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "x-api-key": userToken,
        },
      });
      const json = await response.json();

      if (json.length > 0) {
        setAccounttypes(json);
      } else {
        setAccounttypes([]);
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    fetchAccount();
    fetchFinancialPeriod()
    fetchAccountType()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {data, financialPreiod, accounttypes};
}

export default FetchAccount;
