import React, { useState, useContext, useEffect, useCallback } from "react";
import { useImmer } from "use-immer";
import { Input } from "antd";
import { DeleteOutlined } from "@ant-design/icons";
import { AuthCountext } from "../AuthCountext";
import FetchInventory from "./FetchInventoryData";
import GetItemsUnits from "./GetItemsUnits";
import GetItemStockQuantity from "./GetItemStockQuantity";
import OrderComponent from "./OrderComponent";
import FetchItems from "./FetchItems";
import GetItemPrice from "./GetItemPrice";
import { debounce } from "lodash";

const PurchaseOrdersComp = () => {
  const { theme, ACCOUNNTING_URL, userToken } = useContext(AuthCountext);
  const [isloading, setIsloading] = useState(true);
  const suppliersList = FetchInventory().suppliers;
  const [alertMsg, setAlertMsg] = useState(null);
  const [alertName, setAlertName] = useState(null);
  const [isAlert, setIsAlert] = useState(false);
  const [disableSaveButton, setDisableSaveButton] = useState(false);
  const [formData, setFormData] = useImmer({
    SupplierID: null,
    Status: "Pending",
    TotalAmount: 1,
    TotalVat: 1,
    PaymentStatus: "Pending",
    OrderDate: null,
    CreatedBy: userToken,
    WarehouseID: 1,
    invoicedetails: [
      {
        id: Date.now(), // Unique ID
        ItemID: null,
        UnitID: null,
        Quantity: 1,
        UnitPrice: 1,
        TotalPrice: 1,
        Vat: 1,
        ConversionRate: 1,
        type: "purchase",
      },
    ],
  });

  const presetFormData = {
    SupplierID: null,
    Status: "Pending",
    TotalAmount: 1,
    TotalVat: 1,
    PaymentStatus: "Pending",
    OrderDate: null,
    CreatedBy: userToken,
    WarehouseID: 1,
    invoicedetails: [
      {
        id: Date.now(), // Unique ID
        ItemID: null,
        UnitID: null,
        Quantity: 1,
        UnitPrice: 1,
        TotalPrice: 1,
        Vat: 1,
        ConversionRate: 1,
        type: "purchase",
      },
    ],
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const calculateTotalAmount = useCallback(
    debounce(() => {
      const totalPrice = formData.invoicedetails.reduce(
        (sum, item) => sum + item.TotalPrice,
        0
      );
      const totalVat = formData.invoicedetails.reduce(
        (sum, item) => sum + item.Vat,
        0
      );
      setFormData((prev) => ({
        ...prev,
        TotalAmount: totalPrice,
        TotalVat: totalVat,
      }));
    }, 300),
    [formData.invoicedetails]
  );

  const handleAddfield = useCallback(() => {
    setFormData((draft) => {
      draft.invoicedetails = [
        ...draft.invoicedetails,
        {
          id: Date.now(), // Unique ID
          ItemID: null,
          UnitID: null,
          Quantity: 1,
          UnitPrice: 1,
          TotalPrice: 1,
          Vat: 1,
          ConversionRate: 1,
          type: "purchase",
        },
      ];
    });
    validinvoicedetails();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formData.invoicedetails]);

  const handleRemovefield = useCallback(
    (id) => {
      setFormData((draft) => {
        draft.invoicedetails = draft.invoicedetails.filter(
          (item) => item.id !== id
        );
      });
    },
    [setFormData]
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const getSum = useCallback(
    debounce((x) => {
      let sum = 0;
      for (let i = 0; i < formData.invoicedetails.length; i++) {
        const element = formData.invoicedetails[i][x];
        sum += parseFloat(element);
      }
      return sum;
    }, 300),
    [formData.invoicedetails]
  );

  // fetch

  // end fetch

  // insert data

  const addData = async () => {
    // setDisable(true);
    setIsloading(true);
    try {
      const response = await fetch(`${ACCOUNNTING_URL}/purchaseorders`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "x-api-key": userToken,
        },
        body: JSON.stringify(formData),
      });
      const json = await response.json();
      console.log(json);
      setIsAlert(true);
      setAlertMsg("تمت اضافة السجل بنجاح");
      setAlertName("alert-success");
      alertShow();
    } catch (error) {
      console.error("Fetch failed:", error);
      setAlertMsg("Failed to fetch data");
      setAlertName("alert-danger");
      setIsAlert(true);
    } finally {
      setIsloading(false);
    }
  };

  const validinvoicedetails = () => {
    let x = 0;
    for (let index = 0; index < formData.invoicedetails.length; index++) {
      const item = formData.invoicedetails[index];

      x += item.ItemID !== null ? 1 : 0;
    }
    if (x === formData.invoicedetails.length) {
      return true;
    } else {
      return false;
    }
  };

  const validationFun = () => {
    if (
      formData.company_id !== null &&
      formData.OrderDate !== null &&
      formData.SupplierID !== null &&
      getSum("UnitPrice") + getSum("TotalPrice") !== 0 &&
      validinvoicedetails()
    ) {
      addData();
    } else {
      console.log("no valid");
      setIsAlert(true);
      setAlertMsg("ادخل جميع الحقول");
      setAlertName("alert-warning");
      alertShow();
      setDisableSaveButton(false);
    }
  };

  function handleSubmit(event) {
    setDisableSaveButton(true);
    event.preventDefault();
    validationFun();
  }

  // end insert data

  // others

  const alertShow = () => {
    const timer = setTimeout(() => setIsAlert(false), 3000);
    return () => {
      clearTimeout(timer);
    };
  };

  const loader = () => {
    const timer = setTimeout(() => setIsloading(false), 1000);
    return () => {
      clearTimeout(timer);
    };
  };

  loader();

  useEffect(() => {
    calculateTotalAmount();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formData.invoicedetails]);

  const columns = [
    {
      title: "الصنف",
      dataIndex: "ItemID",
      render: (_, item, index) => (
        <FetchItems
          data={formData}
          setData={setFormData}
          item={item}
          index={index}
          isloading={isloading}
          price='UnitPrice'
        />
      ),
    },
    {
      title: "الوحدة",
      dataIndex: "UnitID",
      render: (_, item, index) => (
        <div style={{ width: "200px" }}>
          <GetItemsUnits
            item={item}
            ItemID={formData.invoicedetails[index].ItemID}
            setFormData={setFormData}
            index={index}
            price='UnitPrice'
          />
        </div>
      ),
    },
    {
      title: "الكمية",
      dataIndex: "Quantity",
      render: (_, item, index) => (
        <div style={{ width: "200px" }}>
          <GetItemStockQuantity
            item={item}
            ItemID={formData.invoicedetails[index].ItemID}
            setFormData={setFormData}
            index={index}
            WarehouseID={formData.WarehouseID}
            price='UnitPrice'
          />
        </div>
      ),
    },
    {
      title: "سعر الوحدة",
      dataIndex: "UnitPrice",
      render: (_, item, index) => (
        <div style={{ width: "200px" }}>
          <GetItemPrice
            item={item}
            ItemID={formData.invoicedetails[index].ItemID}
            setFormData={setFormData}
            index={index}
            WarehouseID={formData.WarehouseID}
            price="UnitPrice"
          />
        </div>
      ),
    },
    {
      title: "الضريبة",
      dataIndex: "Vat",
      render: (_, item, index) => (
        <Input
          value={item.Vat}
          type="text"
          name="TotalPrice"
          style={{ width: "150px" }}
          className={`height-40 border-radius background-div-${theme} color-${theme}`}
          placeholder="الإجمالي"
          disabled
        />
      ),
    },
    {
      title: "الاجمالي",
      dataIndex: "ItemID",
      render: (_, item, index) => (
        <Input
          value={item.TotalPrice}
          type="text"
          name="TotalPrice"
          style={{ width: "150px" }}
          className={`height-40 border-radius background-div-${theme} color-${theme}`}
          placeholder="الإجمالي"
          disabled
        />
      ),
    },
    {
      title: "-",
      dataIndex: "-",
      render: (_, item, index) => (
        <div
          className="flex justify-center align-center cursor-pointer minus"
          onClick={() => handleRemovefield(item.id)}
          style={{
            display: formData.invoicedetails.length === 1 && "none",
          }}
        >
          <DeleteOutlined className="cursor-pointer font-hover" />
        </div>
      ),
    },
  ];

  return (
    <OrderComponent
      title={"امر توريد مخزني - فاتورة مورد"}
      columns={columns}
      formData={formData}
      setFormData={setFormData}
      handleAddfield={handleAddfield}
      handleSubmit={handleSubmit}
      isAlert={isAlert}
      alertMsg={alertMsg}
      alertName={alertName}
      isloading={isloading}
      list={suppliersList}
      disableSaveButton={disableSaveButton}
      setDisableSaveButton={setDisableSaveButton}
      presetFormData={presetFormData}
      orderType={"PurchaseOrder"}
    />
  );
};

export default PurchaseOrdersComp;
