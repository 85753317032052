import React, { useState, useContext, useEffect, useRef } from "react";
import { AuthCountext } from "../AuthCountext";
import { Link, useParams } from "react-router-dom";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import Loader from "../Loader";
import Image from "../../assets/images/Image";
import { useReactToPrint } from "react-to-print";
import { Button } from "antd";
import {
  EditFilled,
  FilePdfOutlined,
  PrinterOutlined,
} from "@ant-design/icons";
import jsPDF from "jspdf";
import { callAddFont } from "./assets/font/Amiri-Regular-normal";
import { createPdf } from "../inventory/PdfReport";
import FetchLogo from "../inventory/FetchLogo";
import FetchBranch from "../inventory/FetchBranch";

dayjs.extend(customParseFormat);
jsPDF.API.events.push(["addFonts", callAddFont]);

const ShowTransactionsComp = () => {
  const { theme, ACCOUNNTING_URL, SERVER_URL, userToken, branchId, userName } =
    useContext(AuthCountext);
  const [isloading, setIsloading] = useState(true);
  const { id } = useParams();
  const [data, setData] = useState([]);
  const contentRef = useRef(null);
  const reactToPrintFn = useReactToPrint({
    ignoreGlobalStyles: false,
    contentRef,
  });

  const logo = FetchLogo();
  const branchData = FetchBranch();

  const options = [
    {
      value: "ledger",
      label: `استعراض قيد يومية - ${data[0]?.description}`,
      pageDirction: "p",
    },
  ];
  const fetchData = async () => {
    setIsloading(true);
    try {
      const response = await fetch(
        `${ACCOUNNTING_URL}/ledger_entries/${id}/${branchId}`,
        {
          method: "GET",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "x-api-key": userToken,
          },
        }
      );
      const json = await response.json();
      if (json.msg !== "No data") {
        setData(json);

        loader();
      } else {
        setData([]);
      }
      loader();
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    fetchData();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ACCOUNNTING_URL]);

  const getSum = (x) => {
    let sum = 0;
    for (let i = 0; i < data.length; i++) {
      const element = data[i][x];
      sum += parseFloat(element);
    }
    return sum;
  };

  const loader = () => {
    const timer = setTimeout(() => setIsloading(false), 1000);
    return () => {
      clearTimeout(timer);
    };
  };

  const columns = [
    { title: "دائن", dataKey: "debit" },
    { title: "مدين", dataKey: "credit" },
    { title: "الوصف", dataKey: "description" },
    {
      title: "الحساب",
      dataKey: "account_name",
    },
    { title: "التاريخ", dataKey: "date" },
    { title: "رقم السجل", dataKey: "transaction_id" },
  ];

  return (
    <div
      className={`
        flex flex-column justify-center media-flex-culomn align-center shadow-${theme} full-width-media border-radius text-align-center background-div-${theme} min-height-300 p-20`}
      style={{ fontSize: "small", width: "80%" }}
    >
      <div
        className="flex flex-row justify-center align-start full-width"
        style={{ minHeight: "580px" }}
      >
        <div className="flex flex-column gap-10 align-center full-width">
          <div className="flex flex-row justify-space-between align-center full-width">
            <h1>قيد يومية</h1>
            <div className="flex gap-5">
              <Link
                to={`/accounting/edit-transactions/${data[0]?.transaction_id}`}
              >
                <Button
                  color="primary"
                  className={`color-primary-${theme}`}
                  type="text"
                  id={data[0]?.transaction_id}
                  loading={isloading}
                  icon=<EditFilled />
                >
                  تعديل
                </Button>
              </Link>
              <Button onClick={reactToPrintFn}>
                <PrinterOutlined />
              </Button>
              <Button
                onClick={() =>
                  createPdf(
                    data,
                    columns,
                    null,
                    null,
                    branchData,
                    userName,
                    logo,
                    false,
                    options[0].value,
                    options
                  )
                }
              >
                <FilePdfOutlined />
              </Button>
            </div>
          </div>
          {isloading ? (
            <Loader size="large" />
          ) : (
            <div
              className="flex flex-column flex-wrap justify-center full-width align-start gap-20 media-flex-column"
              style={{ direction: "rtl", padding: "20px", maxWidth: "800px" }}
              ref={contentRef}
            >
              <div className="flex flex-row justify-space-between full-width">
                <div
                  className={`flex flex-row gap-10 height-50 border-radius background-div-${theme} color-${theme}`}
                  style={{ width: "50%" }}
                >
                  <span>البيان: </span>
                  <span>{data[0]?.description}</span>
                </div>
                <div
                  className={`flex flex-row gap-10 height-50 border-radius background-div-${theme} color-${theme}`}
                  style={{ width: "50%", justifyContent: "center" }}
                >
                  <span>التاريخ: </span>
                  <span>{data[0]?.date}</span>
                </div>
              </div>
              <div className="flex flex-column full-width gap-10">
                <table
                  id="table"
                  className={`full-width table`}
                  style={{
                    borderCollapse: "collapse",
                    borderBottom: "none",
                  }}
                >
                  <thead className={`border-1-${theme}`}>
                    <tr style={{ height: "50px" }}>
                      <th
                        className={`border-1-${theme}`}
                        style={{ textAlign: "center", padding: "5px" }}
                      >
                        اسم الحساب
                      </th>
                      <th
                        className={`border-1-${theme}`}
                        style={{ textAlign: "center", padding: "5px" }}
                      >
                        دائن
                      </th>
                      <th
                        className={`border-1-${theme}`}
                        style={{ textAlign: "center", padding: "5px" }}
                      >
                        مدين
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {data?.map((item, index) => {
                      return (
                        <tr
                          className={`border-1-${theme}`}
                          style={{ height: "50px" }}
                        >
                          <td
                            className={`border-1-${theme}`}
                            style={{ textAlign: "start", padding: "5px" }}
                          >{`${item.account_code} - ${item.account_name}`}</td>
                          <td
                            className={`border-1-${theme}`}
                            style={{ textAlign: "start", padding: "5px" }}
                          >
                            {item.credit > 0 ? item.credit : ""}
                          </td>
                          <td
                            className={`border-1-${theme}`}
                            style={{ textAlign: "start", padding: "5px" }}
                          >
                            {item.debit > 0 ? item.debit : ""}
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                  <tfoot>
                    <tr
                      className="height-50"
                      style={{
                        background:
                          getSum("credit") === getSum("debit")
                            ? "#04AA6D"
                            : "#FF3E3E",
                        border:
                          getSum("credit") === getSum("debit")
                            ? "#04AA6D 1px solid"
                            : "#FF3E3E 1px solid",
                        color: "#FAF9F6",
                        height: "50px",
                      }}
                    >
                      <th style={{ textAlign: "center", padding: "5px" }}>
                        {getSum("credit") === getSum("debit")
                          ? "قيد متزن"
                          : "قيد غير متزن"}
                      </th>
                      <th style={{ textAlign: "center", padding: "5px" }}>
                        {getSum("credit")}
                      </th>
                      <th style={{ textAlign: "center", padding: "5px" }}>
                        {getSum("debit")}
                      </th>
                    </tr>
                  </tfoot>
                </table>
              </div>

              <div
                className="flex flex-rwo justify-space-between full-width"
                style={{ fontSize: "10px" }}
              >
                <div className="flex flex-column align-start p-10">
                  <div className="flex flex-row align-center gap-10">
                    <span>انشأه : </span>
                    <span>{data[0]?.created_by}</span>
                    <Image
                      contain={"img-contain"}
                      image={`${SERVER_URL}/${data[0]?.created_by_avatar}`}
                      h={20}
                      alt={data[0]?.created_by}
                      w={20}
                    />
                  </div>
                  <div>
                    <span>بتاريخ : </span>
                    <span>{data[0]?.created_at}</span>
                  </div>
                </div>
                {data[0]?.updated_by && (
                  <div className="flex flex-column align-start p-10">
                    <div className="flex flex-row align-center gap-10">
                      <span>عدله : </span>
                      <span>{data[0]?.updated_by}</span>
                      <Image
                        contain={"img-contain"}
                        image={`${SERVER_URL}/${data[0]?.updated_by_avatar}`}
                        h={20}
                        alt={data[0]?.updated_by}
                        w={20}
                      />
                    </div>
                    <div>
                      <span>بتاريخ : </span>
                      <span>{data[0]?.updated_at}</span>
                    </div>
                  </div>
                )}
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ShowTransactionsComp;
