import React, { useContext, useState } from "react";
import { AuthCountext } from "./AuthCountext";
import * as dayjs from "dayjs";
import * as isLeapYear from "dayjs/plugin/isLeapYear"; // import plugin
import "dayjs/locale/ar"; // import locale
import { EyeFilled, EyeInvisibleOutlined } from "@ant-design/icons";
import { Card, Typography } from "antd";

const extend = dayjs.extend;
extend(isLeapYear); // use plugin
dayjs.locale("ar"); // use locale
const relativeTime = require("dayjs/plugin/relativeTime");
dayjs.extend(relativeTime);

function EmpolyeeDivInfo({ data, title, labels, width }) {
  const { theme } = useContext(AuthCountext);
  const [coverSalary, setCoverSalary] = useState(true);
  const { Title } = Typography;
  const hide = () => {
    setCoverSalary(!coverSalary);
  };
  return (
    <Card
      className={`flex flex-column align-start justify-start full-width p-10 border-radius`}
      style={{ width: width < 900 ? "100%" : "360px" }}
      loading={data.length === 0}
    >
      <Title level={3}>{title}</Title>
      <ul
        className="flex flex-column"
        
      >
        {labels?.map((item, index) => (
          <li className="flex flex-row justify-start gap-10 align-center">
            <span className="font-bold" style={{opacity: '0.7'}}>{item.label}: </span>

            {item.type === "salary" ? (
              <div className="flex flex-row justify-space-between gap-10 align-center width-70">
                <span>
                  {coverSalary
                    ? "*".repeat(data[item.value]?.toString().length)
                    : data[item.value]}
                </span>
                <div className="flex align-center" onClick={hide}>
                  {coverSalary ? <EyeFilled /> : <EyeInvisibleOutlined />}
                </div>
              </div>
            ) : (
              <span>{data[item.value]}</span>
            )}
            {item.type === "date" && (
              <div
                className={`opacity-8 p-inline-5 ltr border-radius font-small border-1-${theme} align-center text-align-center flex`}
                style={{ fontSize: "8px", margin: "1.5px" }}
              >
                {data[item.value]
                  ? dayjs(data[item.value]).fromNow()
                  : "غير محدد"}
              </div>
            )}
          </li>
        ))}
      </ul>
    </Card>
  );
}

export default EmpolyeeDivInfo;
