import React from "react";
import EmployeeInfo from "./subcomponent/EmployeeInfo";

function EmployeeInformations({ employeeInfo, width }) {
  return (
    <div className="flex flex-column align-start">
      <div
        style={{
          alignItems: "self-start",
          display: "flex",
          flexDirection: "row",
          flexWrap: "wrap",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            flexWrap: "wrap",
            gap: "20px",
            justifyContent: width < 900 ? "center" : "start" 
          }}
        >
          {employeeInfo?.map((item) => (
            <EmployeeInfo
              id={item.id}
              info={item.info}
              name={item.name}
              kind={item.kind}
              key={item.id}
              width={width}
            />
          ))}
        </div>
      </div>
    </div>
  );
}

export default EmployeeInformations;
