import React, { useState, useContext } from "react";
import { Modal, Input, Select, Checkbox, Button } from "antd";

import { AuthCountext } from "../AuthCountext";

import FetchAccount from "./FetchAccount";
import { DeleteFilled, EditFilled, FundViewOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";
import TableComponent from "../TableComponent";

const AccountComp = ({ openModal, setOpenModal }) => {
  const { theme, ACCOUNNTING_URL, setaccountData, userToken, branchId } =
    useContext(AuthCountext);
  const accountsList = FetchAccount().data;
  const accounttypes = FetchAccount().accounttypes;
  const [isloading, setIsloading] = useState(true);
  const [isRefresh, setIsRefresh] = useState(false);

  const [alertMsg, setAlertMsg] = useState(null);
  const [alertName, setAlertName] = useState(null);
  const [isAlert, setIsAlert] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [confirmEditLoading, setConfirmEditLoading] = useState(false);
  const [openEditModal, setOpenEditModal] = useState(false);

  // const [addButtonLoading, setAddButtonLoading] = useState(false);

  const [formData, setFormData] = useState({
    account_id: null,
    account_name: "",
    account_type_id: "",
    balance_type: "",
    is_parent: null,
    parent_id: null,
    company_id: branchId,
    created_by: userToken,
  });

  // fetch

  // end fetch

  // insert data

  const addData = async () => {
    try {
      const response = await fetch(`${ACCOUNNTING_URL}/account`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "x-api-key": userToken,
        },
        body: JSON.stringify(formData),
      });
      const json = await response.json();
      console.log(json);
      setIsAlert(true);
      setAlertMsg("تمت اضافة السجل بنجاح");
      setAlertName("alert-success");
      alertShow();
      setaccountData(true);
    } catch (error) {
      console.error(error);
    }
  };

  const validationFun = () => {
    if (
      formData.account_id !== null ||
      formData.account_name !== "" ||
      formData.account_type_id !== "" ||
      formData.balance_type !== ""
    ) {
      addData();
      setConfirmLoading(true);
      setTimeout(() => {
        setOpenModal(false);
        setConfirmLoading(false);
      }, 2000);
    } else {
      console.log("no valid");
      setIsAlert(true);
      setAlertMsg("ادخل جميع الحقول");
      setAlertName("alert-warning");
      alertShow();
    }
  };

  // function handleSubmit(event) {
  //   // event.preventDefault();
  //   validationFun();
  // }

  // end insert data

  // delete
  const deleteData = async (id) => {
    try {
      const response = await fetch(`${ACCOUNNTING_URL}/account/delete/${id}`, {
        method: "DELETE",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "x-api-key": userToken,
        },
      });
      const json = await response.json();
      setIsRefresh(!isRefresh);
      setIsAlert(true);
      setAlertMsg("تم حذف السجل بنجاح");
      setAlertName("alert-success");
      alertShow();
      if (json.errore.errorInfo[0] === "23000") {
        setIsAlert(true);
        setAlertMsg("لا يمكنك حذف السجل لارتباطه بسجلات اخرى.");
        setAlertName("alert-danger");
      }
      alertShow();
    } catch (error) {
      console.error(error);
    }
  };

  const alertShow = () => {
    const timer = setTimeout(() => setIsAlert(false), 3000);
    return () => {
      clearTimeout(timer);
    };
  };

  // end delete

  // edit
  const [editFormData, setEditFormData] = useState({
    account_id: null,
    account_name: "",
    account_type_id: "",
    balance_type: "",
    is_parent: null,
    parent_id: null,
    company_id: branchId,
    updated_by: userToken,
    updated_at: new Date(),
  });

  const editData = async (id) => {
    try {
      const response = await fetch(`${ACCOUNNTING_URL}/account/`, {
        method: "PUT",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "x-api-key": userToken,
        },
        body: JSON.stringify(editFormData),
      });
      const json = await response.json();
      console.log(json);
      setIsAlert(true);
      setAlertMsg("تم تعديل السجل بنجاح");
      setAlertName("alert-success");
      alertShow();
      setIsRefresh(!isRefresh);
      setConfirmEditLoading(true);
      setTimeout(() => {
        setOpenEditModal(false);
        setConfirmEditLoading(false);
      }, 1000);
    } catch (error) {
      console.error(error);
    }
  };

  const editValidationFun = () => {
    if (editFormData.id !== null && editFormData.title !== null) {
      editData(editFormData.id);
    } else {
      console.log("no valid");
      setIsAlert(true);
      setAlertMsg("ادخل جميع الحقول");
      setAlertName("alert-warning");
      setOpenEditModal(false);
      alertShow();
    }
  };
  // function handleEditSubmit(event) {
  //   event.preventDefault();
  //   editValidationFun();
  //   // setDisabledAria(false);
  // }

  function handleEdit(x, y) {
    setEditFormData((prev) => {
      return {
        ...prev,
        id: x.id,
        created_at: x.created_at,
        created_by: x.created_by,
        account_id: x.account_id,
        account_name: x.account_name,
        account_type_id: x.account_type_id,
        balance_type: x.balance_type,
        is_parent: x.is_parent,
        parent_id: x.parent_id,
        is_archived: x.is_archived,
        company_id: branchId,
        updated_by: userToken,
      };
    });
    setOpenEditModal(true);
  }

  function handleEditCancel() {
    setEditFormData(null);
    setOpenEditModal(false);
    setConfirmEditLoading(false);
  }

  // end edit

  // others

  const parentAccount = (x) => {
    return accountsList.filter((item) => item.id === x);
  };

  const renderAccounts = (x, y, z) => {
    return x.parent_id === null ? (
      <h3 style={{ fontWeight: "bold" }}>{x[y] || z}</h3>
    ) : x.is_parent === "1" ? (
      <span style={{ fontStyle: "italic", fontWeight: "bold" }}>
        {x[y] || z}
      </span>
    ) : (
      <span>{x[y] || z}</span>
    );
  };

  const columns = [
    {
      title: "حذف",
      render: (_, record) => (
        record.parent_id !== null && <Button
          danger
          color="danger"
          type="text"
          onClick={() => deleteData(record.id, true)}
          id={record.transaction_id}
          loading={isloading}
          icon=<DeleteFilled />
        >
          حذف
        </Button>
      ),
    },
    {
      title: "تعديل",
      render: (_, record) => (
        record.parent_id !== null && <>
          <Link to={``}>
            <Button
              color="primary"
              className={`color-primary-${theme}`}
              type="text"
              id={record.id}
              loading={isloading}
              icon=<FundViewOutlined />
            >
              عرض
            </Button>
          </Link>
          <Button
            color="primary"
            className={`color-primary-${theme}`}
            type="text"
            onClick={() => handleEdit(record, true)}
            id={record.transaction_id}
            loading={isloading}
            icon=<EditFilled />
          >
            تعديل
          </Button>
        </>
      ),
    },
    {
      title: "مؤرشف",
      dataKey: "is_parent",
      render: (_, record) => (
        record.parent_id !== null && <div
          style={{
            width: record.is_archived === ("0" || 0) ? "70px" : "50px",
            padding: "5px",
            textAlign: "center",
            fontSize: "10px",
            color: "white",
            borderRadius: 15,
            background:
              record.is_archived === ("0" || 0) ? "#069460" : "tomato",
          }}
        >
          {record.is_archived === ("0" || 0) ? "غير مؤرشف" : "مؤرشف"}
        </div>
      ),
      width: "100",
    },
    {
      title: "رئيسي",
      dataKey: "is_parent",
      render: (_, record) => (
        record.parent_id !== null && <div
          style={{
            width: "50px",
            padding: "5px",
            textAlign: "center",
            fontSize: "10px",
            color: "white",
            borderRadius: 15,
            background: record.is_parent === ("0" || 0) ? "#034377" : "#37718E",
          }}
        >
          {record.is_parent === "0" ? "فرعي" : "رئيسي"}
        </div>
      ),
    },
    {
      title: "نوع الحساب",
      dataKey: "is_parent",
      render: (_, record) => (
        record.parent_id !== null && <div
          style={{
            width: "50px",
            padding: "5px",
            textAlign: "center",
            fontSize: "10px",
            color: "white",
            borderRadius: 15,
            background: record.balance_type === "D" ? "#685044" : "#27474E",
          }}
        >
          {record.balance_type === "D" ? "مدين" : "دائن"}
        </div>
      ),
    },
    {
      title: "أصل الحساب",
      dataKey: "type_name",
      render: (_, record) => (
        record.parent_id !== null && <div
          style={{
            width: "fit-content",
            padding: "5px",
            textAlign: "center",
            fontSize: "10px",
            color: "white",
            borderRadius: 15,
            background: "#261447",
          }}
        >
          {record.type_name}
        </div>
      ),
    },
    {
      title: "رقم الفرع الرئيسي",
      dataKey: "parent_id",
      render: (_, record) =>
        record.parent_id &&
        renderAccounts(
          record,
          null,
          `${parentAccount(record.parent_id)[0]?.account_id} - ${
            parentAccount(record.parent_id)[0]?.account_name
          }`
        ),
    },
    {
      title: "الاسم",
      dataKey: "account_name",
      render: (_, record) => renderAccounts(record, "account_name"),
    },

    {
      title: "رقم الحساب",
      dataKey: "account_id",
      render: (_, record) => renderAccounts(record, "account_id"),
      sorter: (a, b) => a.account_id - b.account_id,
    },
  ];

  const options = [
    {
      value: "account",
      label: "شجرة الحسابات",
      pageDirction: "p",
    },
  ];

  return (
    <>
      <Modal
        key={1}
        title="إضافة حساب"
        open={openModal}
        onOk={validationFun}
        confirmLoading={confirmLoading}
        onCancel={() => setOpenModal(false)}
      >
        <form
          className="flex flex-column justify-center align-center gap-20 media-flex-column full-width"
          style={{ padding: "20px" }}
        >
          {isAlert && <div className={`${alertName}`}>{alertMsg}</div>}
          <div className="full-width">
            <span>أصل الحساب</span>
            <Select
              name="account_type_id"
              showSearch
              className={`full-width height-50 border-radius`}
              onChange={(e) => {
                setFormData((prev) => {
                  return {
                    ...prev,
                    account_type_id: e,
                  };
                });
              }}
              optionFilterProp="label"
              placeholder={isloading ? "جارٍ التحميل" : "أصل الحساب"}
              options={accounttypes.map((item) => ({
                value: item.id,
                label: `${item.description} - ${item.type_name}`,
              }))}
            />
          </div>
          <div className="full-width">
            <span>رقم الحساب</span>
            <Input
              type="text"
              name="account_id"
              className={`full-width height-50 border-radius background-div-${theme} color-${theme}`}
              placeholder="رقم الحساب"
              onChange={(e) => {
                setFormData((prev) => {
                  return {
                    ...prev,
                    account_id: e.target.value,
                  };
                });
              }}
            />
          </div>
          <div className="full-width">
            <span>اسم الحساب</span>
            <Input
              type="text"
              name="account_name"
              className={`full-width height-50 border-radius background-div-${theme} color-${theme}`}
              placeholder="اسم الحساب"
              onChange={(e) => {
                setFormData((prev) => {
                  return {
                    ...prev,
                    account_name: e.target.value,
                  };
                });
              }}
            />
          </div>
          <div className="full-width">
            <span>طبيعة الحساب</span>
            <Select
              name="balance_type"
              showSearch
              className={`full-width height-50 border-radius`}
              onChange={(e) => {
                setFormData((prev) => {
                  return {
                    ...prev,
                    balance_type: e,
                  };
                });
              }}
              optionFilterProp="label"
              placeholder={isloading ? "جارٍ التحميل" : "طبيعة الحساب"}
              options={[
                { value: "C", label: "دائن" },
                { value: "D", label: "مدين" },
              ]}
            />
          </div>
          <div className="full-width">
            <Checkbox
              name="is_parent"
              onChange={(e) => {
                setFormData((prev) => {
                  return {
                    ...prev,
                    is_parent: 1 ? e.target.checked : 0,
                  };
                });
              }}
              style={{ width: "100%" }}
            >
              حساب رئيسي
            </Checkbox>
          </div>
          <div className="full-width">
            <span>الحساب الرئيسي</span>
            <Select
              name="parent_id"
              showSearch
              className={`full-width height-50 border-radius`}
              onChange={(e) => {
                setFormData((prev) => {
                  return {
                    ...prev,
                    parent_id: e,
                    updated_by: userToken,
                    updated_at: "2024-11-12",
                  };
                });
              }}
              optionFilterProp="label"
              placeholder={isloading ? "جارٍ التحميل" : "الحساب الرئيسي"}
              options={accountsList.map((item) => ({
                disabled:
                  item.is_parent === "0" ||
                  item.is_parent === 0 ||
                  item.is_parent === null
                    ? true
                    : false,
                value: item.id,
                label: `${item.account_id} - ${item.account_name}`,
              }))}
            />
          </div>
        </form>
      </Modal>
      <Modal
        key={2}
        title="تعديل حساب"
        open={openEditModal}
        onOk={editValidationFun}
        confirmLoading={confirmEditLoading}
        onCancel={handleEditCancel}
        destroyOnClose={true}
      >
        <form
          className="flex flex-column justify-center align-center gap-20 media-flex-column full-width"
          style={{ padding: "20px" }}
        >
          {isAlert && <div className={`${alertName}`}>{alertMsg}</div>}
          <div className="full-width">
            <span>أصل الحساب</span>
            <Select
              name="account_type_id"
              showSearch
              className={`full-width height-50 border-radius`}
              onChange={(e) => {
                setFormData((prev) => {
                  return {
                    ...prev,
                    account_type_id: e,
                  };
                });
              }}
              optionFilterProp="label"
              defaultValue={editFormData && editFormData?.account_type_id}
              placeholder={isloading ? "جارٍ التحميل" : "أصل الحساب"}
              options={accounttypes.map((item) => ({
                value: item.id,
                label: `${item.description} - ${item.type_name}`,
              }))}
            />
          </div>
          <div className="full-width">
            <span>رقم الحساب</span>
            <Input
              type="text"
              name="account_id"
              className={`full-width height-50 border-radius background-div-${theme} color-${theme}`}
              placeholder="رقم الحساب"
              defaultValue={editFormData && editFormData?.account_id}
              onChange={(e) => {
                setEditFormData((prev) => {
                  return {
                    ...prev,
                    account_id: e.target.value,
                  };
                });
              }}
            />
          </div>
          <div className="full-width">
            <span>اسم الحساب</span>
            <Input
              type="text"
              name="account_name"
              className={`full-width height-50 border-radius background-div-${theme} color-${theme}`}
              placeholder="اسم الحساب"
              defaultValue={editFormData?.account_name}
              onChange={(e) => {
                setEditFormData((prev) => {
                  return {
                    ...prev,
                    account_name: e.target.value,
                  };
                });
              }}
            />
          </div>
          <div className="full-width">
            <span>طبيعة الحساب</span>
            <Select
              name="balance_type"
              showSearch
              className={`full-width height-50 border-radius`}
              defaultValue={editFormData?.balance_type}
              onChange={(e) => {
                setEditFormData((prev) => {
                  return {
                    ...prev,
                    balance_type: e,
                  };
                });
              }}
              optionFilterProp="label"
              placeholder={isloading ? "جارٍ التحميل" : "طبيعة الحساب"}
              options={[
                { value: "C", label: "دائن" },
                { value: "D", label: "مدين" },
              ]}
            />
          </div>
          <div className="full-width">
            <Checkbox
              name="is_parent"
              defaultChecked={editFormData?.is_parent}
              onChange={(e) => {
                setEditFormData((prev) => {
                  return {
                    ...prev,
                    is_parent: 1 ? e.target.checked : 0,
                  };
                });
              }}
              style={{ width: "100%" }}
            >
              حساب رئيسي
            </Checkbox>
          </div>
          <div className="full-width">
            <span>الحساب الرئيسي</span>
            <Select
              name="parent_id"
              showSearch
              className={`full-width height-50 border-radius`}
              defaultValue={editFormData?.parent_id}
              onChange={(e) => {
                setEditFormData((prev) => {
                  return {
                    ...prev,
                    parent_id: e,
                    updated_by: userToken,
                    updated_at: "2024-11-12",
                  };
                });
              }}
              optionFilterProp="label"
              placeholder={isloading ? "جارٍ التحميل" : "الحساب الرئيسي"}
              options={accountsList.map((item) => ({
                disabled:
                  item.is_parent === "0" ||
                  item.is_parent === 0 ||
                  item.is_parent === null
                    ? true
                    : false,
                value: item.id,
                label: `${item.account_id} - ${item.account_name}`,
              }))}
            />
          </div>
        </form>
      </Modal>
      <TableComponent
        isRefresh={isRefresh}
        isloading={isloading}
        columns={columns}
        options={options}
        setIsloading={setIsloading}
        page="account"
        isAlert={isAlert}
        alertMsg={alertMsg}
        alertName={alertName}
      />
    </>
  );
};

export default AccountComp;
