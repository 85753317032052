import jsPDF from "jspdf";
// eslint-disable-next-line no-unused-vars
import { autoTable } from "jspdf-autotable";
import { callAddFont } from "./assets/font/Amiri-Regular-normal";
jsPDF.API.events.push(["addFonts", callAddFont]);

export const createPdf = (
  data,
  columns,
  startdate,
  enddate,
  branchData,
  userName,
  logo,
  openeing,
  reportType,
  options
) => {
  const createTbaleBody = [
    {
      description: "الرصيد الافتتاحي",
      balance: data[0]?.opening_balance > 0 ? data[0]?.opening_balance : 0.0,
      CreditAmount:
        data[0]?.opening_balance > 0 ? data[0]?.opening_balance : "",
      DebitAmount: data[0]?.opening_balance < 0 ? data[0]?.opening_balance : "",
    },
  ];

  data.forEach((item) => {
    createTbaleBody.push(item);
  });

  var doc = new jsPDF(reportType === 'getPeriodBalanceSheet' ? 'l' : 'p', "px");
  doc.setFont("Amiri-Regular"); // set font
  doc.setFontSize(10);
  var pageSize = doc.internal.pageSize;
  var pageHeight = pageSize.height ? pageSize.height : pageSize.getHeight();
  var arabicText;
  switch (reportType) {
    case "trialbalance":
      arabicText = "ميزان المراجعة";
      break;
    case "IncomeStatement":
      arabicText = "بيان الدخل";
      break;
    case "getPeriodBalanceSheet":
    arabicText = "ميزانية فترة محددة";
    break;
    case "CashFlowStatement":
      arabicText = "بيان التدفق النقدي";
      break;
    case "AccountsPayableAging":
      arabicText = "تقادم الحسابات المستحقة الدفع";
      break;
    case "AccountsReceivableAging":
      arabicText = "تقادم الحسابات المدينة";
      break;
    case "balancesheet":
      arabicText = "الميزانية العمومية";
      break;
    default:
      arabicText = `كشف حساب: ${data[0]?.account_name} للفترة من ${startdate} حتى ${enddate}`;
      break;
  }

  doc.setCreationDate();
  doc.setLanguage("ar");
  doc.setR2L(false);

  doc.autoTable(columns, openeing ? createTbaleBody : data, {
    showFoot: "lastPage", // 'everyPage'|'firstPage'|'never' = 'everyPage''
    showHead: "everyPage", // 'everyPage'|'firstPage'|'never' = 'everyPage''
    theme: "grid", // 'striped', 'grid' or 'plain'
    margin: { top: 90 },

    willDrawPage: function (data) {
      // Header
      doc.setFontSize(10);
      doc.addImage(logo.img, "PNG", pageSize.width / 2 - 20, 10, 40, 40, null, "FAST");
      doc.text(
        [branchData.title, branchData.address, branchData.phone],
        pageSize.width - 30,
        20,
        {
          align: "right",
          lineHeightFactor: "2.5",
        }
      );

      doc.text(
        [branchData.entitle, branchData.enaddress, branchData.phone],
        30,
        20,
        {
          align: "left",
          lineHeightFactor: "2.5",
        }
      );
      doc.line(30, 60, pageSize.width - 30, 60);
      doc.text(arabicText, pageSize.width - 30, 80, {
        align: "right",
      });
    },
    didDrawPage: function (data) {
     
      // Footer
      const totalPages = doc.internal.getNumberOfPages();
      for (let i = 1; i <= totalPages; i++) {
          doc.setPage(i);
          doc.text(
              `صفحة ${i} من ${totalPages}`,
              pageSize.width / 2,
              pageHeight - 10
          );
      }
    },
    styles: {
      font: "Amiri-Regular",
      fontStyle: "normal",
      halgin: "right",
    },
    columnStyles: {
      date: { fontStyle: "bold" },
    },
    bodyStyles: {
      cellPadding: 5,
      fontSize: 10,
      font: "Amiri-Regular", // helvetica, times, courier
      lineColor: 100,
      lineWidth: 0.1,
      fontStyle: "normal", // normal, bold, italic, bolditalic
      overflow: "ellipsize", // visible, hidden, ellipsize or linebreak
      fillColor: 255,
      textColor: 20,
      halign: "right", // left, center, right
      valign: "middle", // top, middle, bottom
      fillStyle: "F", // 'S', 'F' or 'DF' (stroke, fill or fill then stroke)
      rowHeight: 20,
      columnWidth: "auto", // 'auto', 'wrap' or a number
    },
    headerStyles: {
      halign: "center", // left, center, right
      cellPadding: 7,
      fontSize: 10,
      fillColor: 255,
      textColor: 20,
      lineColor: 100,
      lineWidth: 0.1,
    },
    footStyles: {
      halign: "center", // left, center, right
      cellPadding: 7,
      fontSize: 10,
      fillColor: 255,
      textColor: 20,
      lineColor: 100,
      lineWidth: 0.1,
    },
  });
  
  var printDate = new Date().toLocaleDateString();
  doc.setFontSize(8);
  doc.setTextColor(100);
  doc.text("طبع في", pageSize.width - 50, doc.autoTable.previous.finalY + 15, {
    halgin: "right",
  });
  doc.text(
    ": " + printDate,
    pageSize.width - 105,
    doc.autoTable.previous.finalY + 15,
    {
      isInputRtl: false,
      isOutputRtl: true,
      halgin: "right",
    }
  );
  doc.text(
    "طبع بواسطة",
    pageSize.width - 60,
    doc.autoTable.previous.finalY + 30,
    {
      halgin: "right",
    }
  );
  doc.text(
    ": " + userName,
    pageSize.width - 115,
    doc.autoTable.previous.finalY + 30,
    {
      isInputRtl: false,
      isOutputRtl: true,
      halgin: "right",
    }
  );
  const fileDate = new Date().toISOString();
  doc.save(fileDate + arabicText + "_.pdf");
};
