import React, { useState, useContext } from "react";
import { useImmer } from "use-immer";
import { Input, Select } from "antd";
import { MinusOutlined, PlusOutlined } from "@ant-design/icons";
import { AuthCountext } from "../AuthCountext";
import FetchAccount from "./FetchAccount";
import TransComponent from "./TransComponent";

const TransactionsComp = () => {
  const { theme, ACCOUNNTING_URL, userToken, branchId } =
    useContext(AuthCountext);
  const [isloading, setIsloading] = useState(true);

  const [alertMsg, setAlertMsg] = useState(null);
  const [alertName, setAlertName] = useState(null);
  const [isAlert, setIsAlert] = useState(false);
  const accountsList = FetchAccount().data;
  const financialPreiod = FetchAccount().financialPreiod;
  const [financialId, setFinancialId] = useState(null);

  const [disableSaveButton, setDisableSaveButton] = useState(false);
  const [formData, setFormData] = useImmer({
    created_by: userToken,
    description: null,
    company_id: branchId,
    financial_period: financialId,
    date: null,
    ledger: [
      {
        account_id: null,
        entry_type: null,
        credit: 0,
        debit: 0,
      },
    ],
  });

  const presetFormData = {
    created_by: userToken,
    description: null,
    company_id: branchId,
    financial_period: financialId,
    date: null,
    ledger: [
      {
        account_id: null,
        entry_type: null,
        credit: 0,
        debit: 0,
      },
    ],
  };

  const handleAddfield = (index) => {
    setFormData((draft) => {
      draft.ledger = [
        ...draft.ledger,
        {
          account_id: null,
          entry_type:
            index === 0 && formData.ledger[index].entry_type === "D"
              ? "C"
              : "D",
          credit: index === 0 ? formData.ledger[index].debit : 0,
          debit: index === 0 ? formData.ledger[index].credit : 0,
        },
      ];
    });
    validLedger();
  };

  const handleRemovefield = (e, index) => {
    console.log(index);
    setFormData((draft) => {
      draft.ledger.splice(index, 1);
    });
  };

  const getSum = (x) => {
    let sum = 0;
    for (let i = 0; i < formData.ledger.length; i++) {
      const element = formData.ledger[i][x];
      sum += parseFloat(element);
    }
    return sum;
  };

  // fetch

  // end fetch

  // insert data

  const addData = async () => {
    setDisableSaveButton(true);
    try {
      const response = await fetch(`${ACCOUNNTING_URL}/transactions`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "x-api-key": userToken,
        },
        body: JSON.stringify(formData),
      });
      const json = await response.json();
      console.log(json);
      setIsAlert(true);
      setAlertMsg("تمت اضافة السجل بنجاح");
      setAlertName("alert-success");
      alertShow();
    } catch (error) {
      console.error(error);
    }
  };

  const validLedger = () => {
    let x = 0;
    for (let index = 0; index < formData.ledger.length; index++) {
      const item = formData.ledger[index];

      x +=
        item.account_id !== null && (item.credit !== 0 || item.debit !== 0)
          ? 1
          : 0;
    }
    console.log("x", x);
    if (x === formData.ledger.length) {
      return true;
    } else {
      return false;
    }
  };

  const validationFun = () => {
    console.log(formData);
    console.log(
      "bb",
      formData.description !== null || formData.description?.length > 3
    );
    if (
      formData.company_id !== null &&
      formData.date !== null &&
      formData.financial_period !== null &&
      formData.description !== null &&
      formData.description.length > 3 &&
      getSum("credit") + getSum("debit") !== 0 &&
      validLedger()
    ) {
      addData();
    } else {
      console.log("no valid");
      setIsAlert(true);
      setAlertMsg("ادخل جميع الحقول");
      setAlertName("alert-warning");
      alertShow();
    }
  };

  function handleSubmit(event) {
    event.preventDefault();
    validationFun();
  }

  // end insert data

  // others

  const alertShow = () => {
    const timer = setTimeout(() => setIsAlert(false), 3000);
    return () => {
      clearTimeout(timer);
    };
  };

  const loader = () => {
    const timer = setTimeout(() => setIsloading(false), 1000);
    return () => {
      clearTimeout(timer);
    };
  };

  loader();

  const validMsg = (x, name) => {
    return (
      !x[name] &&
      isAlert && (
        <span style={{ color: "tomato", fontSize: "10px" }}>
          هذا الحقل مطلوب
        </span>
      )
    );
  };

  const columns = [
    {
      title: "الحساب",
      dataIndex: "account_id",
      render: (_, item, index) => (
        <div className="flex flex-column">
          <Select
            autoFocus
            value={item.account_id}
            name="account_id"
            showSearch
            className={`height-50 border-radius full-width`}
            onChange={(e) =>
              setFormData((draft) => {
                draft.ledger[index].account_id = e;
              })
            }
            optionFilterProp="label"
            placeholder={isloading ? "جارٍ التحميل" : "الحساب"}
            options={accountsList.map((item) => ({
              disabled:
                item.is_parent === "1" || item.is_parent === 1 ? true : false,
              value: item.id,
              label: `${item.account_id} ${item.account_name}`,
            }))}
          />
          {validMsg(item, "account_id")}
        </div>
      ),
      width: 300,
    },
    {
      title: "دائن",
      dataIndex: "credit",
      render: (_, item, index) => (
        <div className="flex flex-column">
          <Input
            value={item.credit}
            type="text"
            name="credit"
            className={`width-200 height-50 border-radius background-div-${theme} color-${theme}`}
            placeholder="دائن"
            onChange={(e) => {
              setDisableSaveButton(false);
              setFormData((draft) => {
                draft.ledger[index].credit = e.target.value;
                draft.ledger[index].debit = 0;
                draft.ledger[index].entry_type = "C";
              });
            }}
          />
          {item.credit === 0 && item.debit === 0 && validMsg(item, "credit")}
        </div>
      ),
      width: 100,
    },
    {
      title: "مدين",
      dataIndex: "debit",
      render: (_, item, index) => (
        <div className="flex flex-column">
          <Input
            value={item.debit}
            type="text"
            name="debit"
            className={`width-200 height-50 border-radius background-div-${theme} color-${theme}`}
            placeholder="مدين"
            onChange={(e) => {
              setDisableSaveButton(false);
              setFormData((draft) => {
                draft.ledger[index].debit = e.target.value;
                draft.ledger[index].credit = 0;
                draft.ledger[index].entry_type = "D";
              });
            }}
          />
          {item.credit === 0 && item.debit === 0 && validMsg(item, "debit")}
        </div>
      ),
      width: 100,
    },
    {
      title: "-",
      dataIndex: "-",
      render: (_, item, index) => (
        <div className="flex justify-space-start gap-10">
          <div
            className="flex justify-center align-center cursor-pointer plus"
            onClick={() => handleAddfield(index)}
          >
            <PlusOutlined />
          </div>

          <div
            className="flex justify-center align-center cursor-pointer minus"
            onClick={(e) => handleRemovefield(e, index)}
            style={{
              display: formData.ledger.length === 1 && "none",
            }}
          >
            <MinusOutlined className="cursor-pointer font-hover" />
          </div>
        </div>
      ),
      width: 150,
    },
  ];

  return (
    <>
      <TransComponent
        title={"اضافة قيد"}
        columns={columns}
        formData={formData}
        financialPreiod={financialPreiod}
        setFinancialId={setFinancialId}
        setFormData={setFormData}
        handleAddfield={handleAddfield}
        handleSubmit={handleSubmit}
        isAlert={isAlert}
        alertMsg={alertMsg}
        alertName={alertName}
        isloading={isloading}
        list={accountsList}
        disableSaveButton={disableSaveButton}
        setDisableSaveButton={setDisableSaveButton}
        presetFormData={presetFormData}
        orderType={"transaction"}
        validMsg={validMsg}
      />
    </>
  );
};

export default TransactionsComp;
