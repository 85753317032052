import ParentComp from "../ParentComp";
import InvoicesEditComp from "./InvoicesEditComp";

function InvoicesEdit() {
  return (
    <ParentComp
      title={"تعديل أمر الصادر"}
      prevPage={"الصادر"}
      linkto={"/Inventory/InvoicesList"}
    >
      <InvoicesEditComp />
    </ParentComp>
  );
}

export default InvoicesEdit;
