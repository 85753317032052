import { Button } from "antd";
import ParentComp from "../ParentComp";
import PurchaseOrdersListComp from "./PurchaseOrdersListComp";
import { useState } from "react";
import { Link } from "react-router-dom";

function PurchaseOrdersList() {
  const [openModal, setOpenModal] = useState(false);
  const ParentButton = () => {
    return (
      <Link to="/inventory/PurchaseOrders">
        <Button type="primary" onClick={() => setOpenModal(true)}>
        إضافة أمر توريد
      </Button>
      </Link>
    );
  };

  
  return (
    <ParentComp
      title={"الوارد"}
      prevPage={"نظام المخازن"}
      linkto={"/inventory"}
      button={<ParentButton />}
    >
      <PurchaseOrdersListComp openModal={openModal} setOpenModal={setOpenModal} />
    </ParentComp>
  );
}

export default PurchaseOrdersList;
