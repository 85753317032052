import React, { useState, useContext } from "react";
import { Button } from "antd";

import { AuthCountext } from "../AuthCountext";
import { DeleteFilled, EditFilled, FundViewOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";
import TableComponent from "../TableComponent";

const PurchaseOrdersListComp = ({ openModal, setOpenModal }) => {
  const { theme, ACCOUNNTING_URL, userToken } = useContext(AuthCountext);
  const [isloading, setIsloading] = useState(true);
  const [isRefresh, setIsRefresh] = useState(false);
  const [alertMsg, setAlertMsg] = useState(null);
  const [alertName, setAlertName] = useState(null);
  const [isAlert, setIsAlert] = useState(false);

  // delete
  const deleteData = async (id) => {
    try {
      const response = await fetch(
        `${ACCOUNNTING_URL}/purchaseorders/delete/${id}`,
        {
          method: "DELETE",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "x-api-key": userToken,
          },
        }
      );
      const json = await response.json();
      setIsRefresh(!isRefresh);
      setIsAlert(true);
      setAlertMsg("تم حذف السجل بنجاح");
      setAlertName("alert-success");
      alertShow();
      if (json.errore.errorInfo[0] === "23000") {
        setIsAlert(true);
        setAlertMsg("لا يمكنك حذف السجل لارتباطه بسجلات اخرى.");
        setAlertName("alert-danger");
      }
      alertShow();
    } catch (error) {
      console.error(error);
    }
  };

  const alertShow = () => {
    const timer = setTimeout(() => setIsAlert(false), 3000);
    return () => {
      clearTimeout(timer);
    };
  };

  // end delete

  // others

  const columns = [
    {
      title: "حذف",
      render: (_, record) => (
        <Button
          danger
          color="danger"
          type="text"
          onClick={() => deleteData(record.PurchaseOrderID, true)}
          id={record.ItemID}
          loading={isloading}
          icon=<DeleteFilled />
        >
          حذف
        </Button>
      ),
    },
    {
      title: "تعديل",
      render: (_, record) => (
        <>
          <Link to={`/inventory/PurchaseOrdersVeiw/${record.PurchaseOrderID}`}>
            <Button
              color="primary"
              className={`color-primary-${theme}`}
              type="text"
              id={record.PurchaseOrderID}
              loading={isloading}
              icon=<FundViewOutlined />
            >
              عرض
            </Button>
          </Link>
          <Link to={`/inventory/PurchaseOrdersEdit/${record.PurchaseOrderID}`}>
            <Button
              color="primary"
              className={`color-primary-${theme}`}
              type="text"
              id={record.PurchaseOrderID}
              loading={isloading}
              icon=<EditFilled />
            >
              تعديل
            </Button>
          </Link>
        </>
      ),
    },
    { title: "حالة الدفع", dataKey: "PaymentStatus" },
    { title: "الحالة", dataKey: "Status" },
    { title: "الإجمالي", dataKey: "TotalAmount" },
    { title: "الضريبة", dataKey: "TotalVat" },
    { title: "المستودع", dataKey: "WarehouseName" },
    { title: "اسم المورد", dataKey: "supplierName" },
    { title: "التاريخ", dataKey: "OrderDate" },
    { title: "رقم الأمر", dataKey: "PurchaseOrderID" },
  ];

  const options = [
    {
      value: "purchaseorders",
      label: "قائمة أوامر التوريد",
      pageDirction: "p",
    },
  ];

  return (
    <TableComponent
      isRefresh={isRefresh}
      isloading={isloading}
      columns={columns}
      options={options}
      setIsloading={setIsloading}
      page="purchaseorders"
      isAlert={isAlert}
      alertMsg={alertMsg}
      alertName={alertName}
    />
  );
};

export default PurchaseOrdersListComp;
