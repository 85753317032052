import React, { useState, useContext, useEffect, useCallback } from "react";
import { useImmer } from "use-immer";
import { Input } from "antd";
import { DeleteOutlined } from "@ant-design/icons";
import { AuthCountext } from "../AuthCountext";
import FetchInventory from "./FetchInventoryData";
import GetItemsUnits from "./GetItemsUnits";
import GetItemStockQuantity from "./GetItemStockQuantity";
import OrderComponent from "./OrderComponent";
import FetchItems from "./FetchItems";
import GetItemPrice from "./GetItemPrice";
import { debounce } from "lodash";
import { useParams } from "react-router-dom";

const todyDate = new Date().toLocaleDateString();

const InvoicesEditComp = () => {
  const { theme, ACCOUNNTING_URL, userToken, branchId } =
    useContext(AuthCountext);
  const { id } = useParams();
  const [isloading, setIsloading] = useState(true);
  const buyersList = FetchInventory().buyers;
  const [alertMsg, setAlertMsg] = useState(null);
  const [alertName, setAlertName] = useState(null);
  const [isAlert, setIsAlert] = useState(false);
  const [disableSaveButton, setDisableSaveButton] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [deleted, setDeleted] = useState([]);
  const [formData, setFormData] = useImmer({
    BuyerID: null,
    Status: "Pending",
    TotalAmount: 0,
    TotalVat: 0,
    PaymentStatus: "Pending",
    InvoiceDate: null,
    CreatedBy: userToken,
    WarehouseID: 1,
    invoicedetails: [
      {
        id: Date.now(), // Unique ID
        ItemID: null,
        UnitID: null,
        Quantity: null,
        PricePerUnit: 0,
        TotalPrice: 0,
        Vat: 0,
        ConversionRate: 1,
      },
    ],
  });

  const fetchData = async () => {
    setIsloading(true);
    try {
      const response = await fetch(
        `${ACCOUNNTING_URL}/invoices/${id}/${branchId}`,
        {
          method: "GET",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "x-api-key": userToken,
          },
        }
      );
      const json = await response.json();
      if (json.msg !== "No data") {
        setFormData((draft) => {
          draft.updated_by = userToken;
          draft.updated_at = todyDate;
          draft.BuyerID = json?.invoice.BuyerID;
          draft.TotalAmount = json?.invoice.TotalAmount;
          draft.TotalVat = json?.invoice.TotalVat;
          draft.PaymentStatus = json?.invoice.PaymentStatus;
          draft.Status = json?.invoice.Status;
          draft.WarehouseID = json?.invoice.WarehouseID;
          draft.InvoiceDate = json?.invoice.InvoiceDate;
          draft.invoicedetails = json?.details;
        });
        loader();
      } else {
      }
      loader();
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    fetchData();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ACCOUNNTING_URL]);

  const presetFormData = {
    BuyerID: null,
    Status: "Pending",
    TotalAmount: 0,
    TotalVat: 0,
    PaymentStatus: "Pending",
    InvoiceDate: null,
    CreatedBy: userToken,
    WarehouseID: 1,
    invoicedetails: [
      {
        id: Date.now(), // Unique ID
        ItemID: null,
        UnitID: null,
        Quantity: null,
        PricePerUnit: 0,
        TotalPrice: 0,
        Vat: 0,
        ConversionRate: 1,
      },
    ],
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const calculateTotalAmount = useCallback(
    debounce(() => {
      const totalPrice = formData.invoicedetails.reduce(
        (sum, item) => sum + parseFloat(item.TotalPrice),
        0
      );
      const totalVat = formData.invoicedetails.reduce(
        (sum, item) => sum + parseFloat(item.Vat),
        0
      );
      setFormData((prev) => ({
        ...prev,
        TotalAmount: totalPrice,
        TotalVat: totalVat,
      }));
    }, 300),
    [formData.invoicedetails]
  );

  const handleAddfield = useCallback(() => {
    setFormData((draft) => {
      draft.invoicedetails = [
        ...draft.invoicedetails,
        {
          id: Date.now(), // Unique ID
          ItemID: null,
          UnitID: null,
          Quantity: 1,
          PricePerUnit: 1,
          TotalPrice: 1,
          Vat: 1,
          ConversionRate: 1,
          type: "invoicedetails",
        },
      ];
    });
    validinvoicedetails();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formData.invoicedetails]);

  const deleteData = async (InvoiceDetailID) => {
    try {
      const response = await fetch(
        `${ACCOUNNTING_URL}/invoices/invoicedetails/${InvoiceDetailID}`,
        {
          method: "DELETE",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "x-api-key": userToken,
          },
        }
      );
      const json = await response.json();
      // setIsRefresh(!isRefresh);
      setIsAlert(true);
      setAlertMsg("تم حذف السجل بنجاح");
      setAlertName("alert-success");
      alertShow();
      if (json.errore.errorInfo[0] === "23000") {
        setIsAlert(true);
        setAlertMsg("لا يمكنك حذف السجل لارتباطه بسجلات اخرى.");
        setAlertName("alert-danger");
      }
      alertShow();
    } catch (error) {
      console.error(error);
    }
  };

  const handleRemovefield = useCallback(
    (InvoiceDetailID) => {
      InvoiceDetailID && deleted.push(InvoiceDetailID);
      setFormData((draft) => {
        draft.invoicedetails = draft.invoicedetails.filter(
          (item) => item.InvoiceDetailID !== InvoiceDetailID
        );
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [setFormData]
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const getSum = useCallback(
    debounce((x) => {
      let sum = 0;
      for (let i = 0; i < formData.invoicedetails.length; i++) {
        const element = formData.invoicedetails[i][x];
        sum += parseFloat(element);
      }
      return sum;
    }, 300),
    [formData.invoicedetails]
  );

  // fetch

  // end fetch

  // insert data

  const updateData = async () => {
    // setDisable(true);
    setIsloading(true);
    try {
      const response = await fetch(`${ACCOUNNTING_URL}/invoices`, {
        method: "PUT",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "x-api-key": userToken,
        },
        body: JSON.stringify(formData),
      });
      const json = await response.json();
      console.log(json);
      setIsAlert(true);
      setAlertMsg("تمت اضافة السجل بنجاح");
      setAlertName("alert-success");
      alertShow();
    } catch (error) {
      console.error("Fetch failed:", error);
      setAlertMsg("Failed to fetch data");
      setAlertName("alert-danger");
      setIsAlert(true);
    } finally {
      setIsloading(false);
    }
  };

  const validinvoicedetails = () => {
    let x = 0;
    for (let index = 0; index < formData.invoicedetails.length; index++) {
      const item = formData.invoicedetails[index];

      x += item.ItemID !== null ? 1 : 0;
    }
    if (x === formData.invoicedetails.length) {
      return true;
    } else {
      return false;
    }
  };

  const validationFun = () => {
    if (
      formData.company_id !== null &&
      formData.OrderDate !== null &&
      formData.SupplierID !== null &&
      getSum("PricePerUnit") + getSum("TotalPrice") !== 0 &&
      validinvoicedetails()
    ) {
      for (var i in deleted) {
        deleteData(deleted[i]);
      }
      updateData();
    } else {
      console.log("no valid");
      setIsAlert(true);
      setAlertMsg("ادخل جميع الحقول");
      setAlertName("alert-warning");
      alertShow();
      setDisableSaveButton(false);
    }
  };

  function handleSubmit(event) {
    setDisableSaveButton(true);
    event.preventDefault();
    validationFun();
  }

  // end insert data

  // others

  const alertShow = () => {
    const timer = setTimeout(() => setIsAlert(false), 3000);
    return () => {
      clearTimeout(timer);
    };
  };

  const loader = () => {
    const timer = setTimeout(() => setIsloading(false), 1000);
    return () => {
      clearTimeout(timer);
    };
  };

  loader();

  useEffect(() => {
    calculateTotalAmount();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formData.invoicedetails]);

  const columns = [
    {
      title: "الصنف",
      dataIndex: "ItemID",
      render: (_, item, index) => (
        <FetchItems
          data={formData}
          setData={setFormData}
          item={item}
          index={index}
          isloading={isloading}
          price={"PricePerUnit"}
        />
      ),
    },
    {
      title: "الوحدة",
      dataIndex: "UnitID",
      render: (_, item, index) => (
        <GetItemsUnits
          item={item}
          ItemID={formData.invoicedetails[index].ItemID}
          setFormData={setFormData}
          index={index}
          price={"PricePerUnit"}
        />
      ),
      width: 200,
    },
    {
      title: "الكمية",
      dataIndex: "Quantity",
      render: (_, item, index) => (
        <GetItemStockQuantity
          item={item}
          ItemID={formData.invoicedetails[index].ItemID}
          setFormData={setFormData}
          index={index}
          WarehouseID={formData.WarehouseID}
          price={"PricePerUnit"}
        />
      ),
      width: 200,
    },
    {
      title: "سعر الوحدة",
      dataIndex: "PricePerUnit",
      render: (_, item, index) => (
        <GetItemPrice
          item={item}
          ItemID={item.ItemID}
          setFormData={setFormData}
          index={index}
          WarehouseID={formData.WarehouseID}
          price={"PricePerUnit"}
          edit={true}
        />
      ),
      width: 200,
    },
    {
      title: "الضريبة",
      dataIndex: "Vat",
      render: (_, item, index) => (
        <Input
          value={item.Vat}
          type="text"
          name="TotalPrice"
          className={`height-40 border-radius background-div-${theme} color-${theme}`}
          placeholder="الإجمالي"
          disabled
          style={{ border: "none" }}
        />
      ),
      width: 150,
    },
    {
      title: "الاجمالي",
      dataIndex: "ItemID",
      render: (_, item, index) => (
        <Input
          value={item.TotalPrice}
          type="text"
          name="TotalPrice"
          className={`height-40 border-radius background-div-${theme} color-${theme}`}
          placeholder="الإجمالي"
          disabled
          style={{ border: "none" }}
        />
      ),
      width: 150,
    },
    {
      title: "-",
      dataIndex: "-",
      render: (_, item, index) => (
        <div
          className="flex justify-center align-center cursor-pointer"
          onClick={() => {
            handleRemovefield(item.InvoiceDetailID);
          }}
          style={{
            display: formData.invoicedetails.length === 1 && "none",
          }}
        >
          <DeleteOutlined
            className="cursor-pointer font-hover"
            style={{ color: "red" }}
          />
        </div>
      ),
    },
  ];

  console.log(formData);

  return (
    <OrderComponent
      title={"تعديل أمر صرف مخزني - فاتورة عميل"}
      columns={columns}
      formData={formData}
      setFormData={setFormData}
      handleAddfield={handleAddfield}
      handleSubmit={handleSubmit}
      isAlert={isAlert}
      alertMsg={alertMsg}
      alertName={alertName}
      isloading={isloading}
      list={buyersList}
      disableSaveButton={disableSaveButton}
      setDisableSaveButton={setDisableSaveButton}
      presetFormData={presetFormData}
      orderType={"invoicedetails"}
      edit={true}
      max={true}
    />
  );
};

export default InvoicesEditComp;
