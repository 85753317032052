import React, { useEffect, useState, useContext } from "react";
import { AuthCountext } from "../AuthCountext";
import { Link } from "react-router-dom";
import { Card, Statistic } from "antd";
import { EyeOutlined, PlusOutlined } from "@ant-design/icons";

function EmployeeInfo({ id, info, name, kind, width }) {
  const { SERVER_URL, userToken } = useContext(AuthCountext);
  const [isloading, setIsloading] = useState(true);
  const [data, setData] = useState([]);
  useEffect(() => {
    const fetchData = async () => {
      setIsloading(true);
      try {
        const response = await fetch(`${SERVER_URL}/info/${id}/${info}`, {
          method: "GET",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "x-api-key": userToken,
          },
        });
        const json = await response.json();
        setData(json);
        setIsloading(false);
      } catch (error) {
        console.error(error);
      }
    };

    fetchData();
  }, [SERVER_URL, info, id, userToken]);

  return (
    <Card
      loading={isloading}
      className="border-radius"
      style={{
        backgroundColor: data > (0 || "0") ? "#f6ffed" : "",
        minWidth: width < 900 ? "120px" : "150px",
        maxWidth: "200px",
      }}
      size="small"
      actions={[
        <Link to={`../${info}/${id}`}>
          <PlusOutlined title="إضافة" />
        </Link>,
        <EyeOutlined title="استعراض" />,
      ]}
    >
      <Statistic
        title={name}
        value={data ? data : "0"}
        suffix={kind}
        valueStyle={{ color: data > (0 || "0") ? "#3f8600" : "", fontSize: "1.2rem" }}
      />
    </Card>
  );
}

export default EmployeeInfo;
