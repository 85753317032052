import React, { useState, useEffect, useContext } from "react";
import { Link, useParams } from "react-router-dom";
import Image from "../assets/images/Image";
import Icon from "./Icon";
import {
  whatsapp,
  call,
  profileDark,
  uploadphoto,
} from "../assets/icons/index";
import { AuthCountext } from "./AuthCountext";
import * as dayjs from "dayjs";
import * as isLeapYear from "dayjs/plugin/isLeapYear"; // import plugin
import "dayjs/locale/ar"; // import locale
import { DatePicker, Switch, Button as Bt, Input, Select } from "antd";
import UploadImage from "./subcomponent/UploadImage";
import ParentComp from "./ParentComp";
import EmployeePlaceHolder from "./subcomponent/EmployeePlaceHolder";
import { EyeOutlined, SaveOutlined, UploadOutlined } from "@ant-design/icons";
import AlertComp from "./subcomponent/AlertComp";
import useMeasure from "react-use-measure";

const extend = dayjs.extend;
extend(isLeapYear); // use plugin
dayjs.locale("ar"); // use locale
const relativeTime = require("dayjs/plugin/relativeTime");
dayjs.extend(relativeTime);

function UpdateEmployee() {
  const { SERVER_URL, branchId, theme, setTitle, userToken } =
    useContext(AuthCountext);
  const params = useParams();
  const [isloading, setIsloading] = useState(false);
  const [data, setData] = useState([]);
  const [conteries, setConteries] = useState(null);
  const [position, setPosition] = useState(null);
  const [alertMsg, setAlertMsg] = useState(null);
  const [alertName, setAlertName] = useState(null);
  const [isAlert, setIsAlert] = useState(false);
  const [branchList, setBranchList] = useState([]);
  const [ref, { width }] = useMeasure();

  const [formData, setFormData] = useState({
    employeeId: null,
    name: null,
    phone: null,
    email: null,
    birthdate: null,
    sex: null,
    fulltime: null,
    employee: null,
    idNumber: null,
    countrycode: null,
    idExpireDate: null,
    passportNumber: null,
    passExpireDate: null,
    photo: null,
    idcopy: null,
    passport: null,
    positionId: null,
    startInPosition: null,
    endInPosition: null,
    amount: null,
    paymethodId: null,
    inwork: null,
    start: null,
    end: null,
    branchId: null,
  });

  // fetch for position Id from table positiontitle
  const fetchPositin = async () => {
    try {
      const response = await fetch(`${SERVER_URL}/positiontitle/`, {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "x-api-key": userToken,
        },
      });
      const json = await response.json();
      setPosition(json);
    } catch (error) {
      console.error(error);
    }
  };

  const getCountries = async () => {
    setIsloading(true);
    try {
      const response = await fetch(`${SERVER_URL}/nationality/countries/`, {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "x-api-key": userToken,
        },
      });
      const json = await response.json();
      setConteries(json);
      loader();
    } catch (error) {
      console.error(error);
    }
  };

  const fetchBrachesData = async () => {
    setIsloading(true);
    try {
      const response = await fetch(`${SERVER_URL}/branch/`, {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "x-api-key": userToken,
        },
      });
      const json = await response.json();
      if (json.length > 0) {
        setBranchList(json);
      } else {
        setBranchList([]);
      }
      setIsloading(false);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    fetchBrachesData();
    getCountries();
    fetchPositin();
    // eslint-saveButtonDisable-next-line react-hooks/exhaustive-deps
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      setIsloading(true);
      try {
        const response = await fetch(
          `${SERVER_URL}/fetchemployee/${params.id}/${branchId}`,
          {
            method: "GET",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
              "x-api-key": userToken,
            },
          }
        );
        const json = await response.json();
        if (json.length > 0) {
          setData(json);
          setFormData((prevFormData) => {
            return {
              ...prevFormData,
              employeeId: json[0].id,
              name: json[0].name,
              phone: json[0].phone,
              email: json[0].email,
              birthdate: json[0].birthdate,
              sex: json[0].sex,
              fulltime: json[0].fulltime,
              employee: json[0].employee,
              idNumber: json[0].idNumber,
              countrycode: json[0].countrycode,
              idExpireDate: json[0].idExpireDate,
              passportNumber: json[0].passportNumber,
              passExpireDate: json[0].passExpireDate,
              photo: json[0].photo,
              idcopy: json[0].idcopy,
              passport: json[0].passport,
              positionId: json[0].positionId,
              startInPosition: json[0].startInPosition,
              endInPosition: json[0].endInPosition,
              amount: json[0].amount,
              paymethodId: json[0].paymethodId,
              inwork: json[0].inwork,
              start: json[0].start,
              end: json[0].end,
              branchId: json[0].branchId,
            };
          });
        }
        loader();
      } catch (error) {
        console.error(error);
      }
    };

    fetchData();
  }, [SERVER_URL, branchId, params.id, userToken]);

  useEffect(() => {
    const timer = setTimeout(() => setIsloading(false), 3000);
    return () => {
      clearTimeout(timer);
    };
  }, []);

  const sendData = async () => {
    try {
      const response = await fetch(`${SERVER_URL}/updateemployee/`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "x-api-key": userToken,
        },
        body: JSON.stringify(formData),
      });
      const json = await response.json();
      console.log(json);
      setIsAlert(true);
      setAlertMsg(`تم تعديل بيانات الموظف ${formData.name} بنجاح`);
      setAlertName("alert-success");
      alertShow();
      loader();
    } catch (error) {
      console.error(error);
      setIsAlert(true);
      setAlertMsg("حدث خطأ ما..");
      setAlertName("alert-warning");
      alertShow();
    }
  };

  const alertShow = () => {
    const timer = setTimeout(() => setIsAlert(false), 1000);
    return () => {
      clearTimeout(timer);
    };
  };

  const loader = () => {
    const timer = setTimeout(() => setIsloading(false), 1000);
    return () => {
      clearTimeout(timer);
    };
  };

  const Place = () => {
    const places = [];
    for (let i = 0; i < 1; i++) {
      places.push(<EmployeePlaceHolder />);
    }
    return places;
  };

  return (
    <>
      <ParentComp
        title={formData.name}
        prevPage={"ادارة الموظفين"}
        linkto={"/employee/"}
      >
        {isAlert && <AlertComp msg={alertMsg} alert={alertName} />}
        <div
          ref={ref}
          style={{
            flexDirection: width < 500 ? "column" : "row",
            padding: "20px",
            paddingBlock: "60px",
          }}
          className={`justify-space-between flex flex-row position-relative justify-center media-flex-culomn align-flex-start shadow-${theme} full-width-media border-radius text-align-center background-div-${theme} full-width min-height-500 gap-20`}
        >
          <Link
            to={`/employee/${formData.employeeId}`}
            style={{
              position: "absolute",
              left: "10px",
              top: "10px",
              zIndex: 2,
              width: "100px",
              overflow: "hidden",
            }}
            className={`before-background-color-${theme} before-text-color-${theme} text-center flex color-${theme} justify-center align-center text-decoration p-5 border-radius`}
          >
            <div
              style={{
                zIndex: 2,
                display: "flex",
                alignItems: "center",
                justifyContent: "space-around",
                width: "100%",
              }}
            >
              <span>استعراض</span>{" "}
              <EyeOutlined style={{ fontSize: "medium" }} title="استعراض" />
            </div>
          </Link>

          {isloading ? (
            <Place />
          ) : data.length > 0 && position && conteries ? (
            <>
              {setTitle(formData.name)}
              <div
                className="flex flex-column justify-space-between gap-20 full-width"
                style={{ minHeight: "580px" }}
              >
                <div
                  key={formData.id}
                  className="flex flex-row gap-20 full-width position-relative media-flex-culomn"
                  style={{ minWidth: width < 900 ? "100%" : "600px" }}
                >
                  <div className="flex flex-column align-start position-relative">
                    <div className="flex flex-column align-start">
                      <Image
                        image={`${SERVER_URL}/${formData.photo}`}
                        w={220}
                        h={290}
                        name={formData.name}
                      />
                    </div>
                  </div>
                  <div className="flex flex-column justify-end">
                    <div
                      className="flex flex-column gap-10 justify-space-between"
                      style={{ height: "100%" }}
                    >
                      <div>
                        <UploadImage
                          // the name in the formData
                          name="photo"
                          // the formData.
                          data={formData}
                          // setFormData.
                          setData={setFormData}
                          // the placeholder you want to show in.
                          placeholder={
                            <Bt
                              style={{ height: "50px" }}
                              icon={<UploadOutlined />}
                            >
                              غير صورة شخصية
                            </Bt>
                          }
                          // the image link witch stored in name attribute.
                          image={formData.photo}
                          // icon
                          icon={theme === "dark" ? profileDark : uploadphoto}
                        />
                      </div>
                      <div className="flex flex-column gap-10">
                        <div className="text-align-start">
                          <Input
                            type="text"
                            name="name"
                            className={`full-width height-50 border-radius background-div-${theme} color-${theme}`}
                            placeholder="الإسم"
                            defaultValue={formData.name}
                            onChange={(e) =>
                              setFormData((prevFormData) => {
                                return {
                                  ...prevFormData,
                                  name: e.target.value,
                                };
                              })
                            }
                          />
                        </div>
                        <div
                          style={{
                            flexDirection: width < 900 ? "column" : "row",
                          }}
                          className="opacity-8 font-large flex flex-row align-center gap-5"
                        >
                          <Select
                            name="positionId"
                            showSearch
                            className={`full-width height-50 border-radius`}
                            defaultValue={formData.positionId}
                            onChange={(e) =>
                              setFormData((prevFormData) => {
                                return {
                                  ...prevFormData,
                                  positionId: e,
                                };
                              })
                            }
                            optionFilterProp="label"
                            placeholder="الوظيفة"
                            options={position.map((item) => ({
                              value: item.id,
                              label: item.title,
                            }))}
                          />
                          |
                          <Input
                            type="text"
                            name="phone"
                            className={`full-width height-50 border-radius background-div-${theme} color-${theme}`}
                            placeholder="الجوال"
                            defaultValue={formData.phone}
                            onChange={(e) =>
                              setFormData((prevFormData) => {
                                return {
                                  ...prevFormData,
                                  phone: e.target.value,
                                };
                              })
                            }
                          />
                          <Link
                            className="flex align-center"
                            to={`tel:966${formData.phone.slice(1)}`}
                          >
                            <Icon icon={call} w={20} h={20} />
                          </Link>
                          <Link
                            className="flex align-center"
                            to={`https://wa.me/966${formData.phone.slice(1)}`}
                            target="_blank"
                          >
                            <Icon icon={whatsapp} w={20} h={20} />
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="flex flex-row position-relative justify-space-between align-center gap-20 media-flex-culomn media-full-width"
                  style={{ maxWidth: "200px" }}
                >
                  <div
                    className={`flex flex-column align-center justify-start position-relative border-radius media-full-width`}
                    style={{ width: "190px" }}
                  >
                    <div className="flex flex-column">
                      <UploadImage
                        // the name in the formData
                        name="idcopy"
                        // the formData.
                        data={formData}
                        // setFormData.
                        setData={setFormData}
                        // the placeholder you want to show in.
                        placeholder={
                          <Bt
                            style={{ height: "50px" }}
                            icon={<UploadOutlined />}
                          >
                            غير صورة الهوية
                          </Bt>
                        }
                        // the image link witch stored in name attribute.
                        image={formData.idcopy}
                        // icon
                        icon={theme === "dark" ? profileDark : uploadphoto}
                      />
                    </div>
                    <Image
                      image={`${SERVER_URL}/${formData.idcopy}`}
                      w={180}
                      h={150}
                      name={formData.name}
                      contain={true}
                    />
                  </div>
                  <div
                    className={`flex flex-column align-center justify-start position-relative border-radius media-full-width`}
                    style={{ width: "190px" }}
                  >
                    <div className="flex flex-column">
                      <UploadImage
                        // the name in the formData
                        name="passport"
                        // the formData.
                        data={formData}
                        // setFormData.
                        setData={setFormData}
                        // the placeholder you want to show in.
                        placeholder={
                          <Bt
                            style={{ height: "50px" }}
                            icon={<UploadOutlined />}
                          >
                            غير صورة الجواز
                          </Bt>
                        }
                        // the image link witch stored in name attribute.
                        image={formData.passport}
                        // icon
                        icon={theme === "dark" ? profileDark : uploadphoto}
                      />
                    </div>
                    <Image
                      image={`${SERVER_URL}/${formData.passport}`}
                      w={180}
                      h={150}
                      name={formData.name}
                      contain={true}
                    />
                  </div>
                </div>
              </div>
              <div
                className={`flex over-flow-scroll over-flow-scroll-${theme} flex-row flex-wrap justify-start text-align-start full-width gap-20`}
                style={{ height: "650px" }}
              >
                <div
                  className={`flex flex-column align-start justify-start p-10 border-radius`}
                  style={{ width: "360px" }}
                >
                  <h1>بيانات شخصية</h1>
                  <ul className="flex flex-column">
                    <li className="flex flex-column justify-start position-relative">
                      <span
                        style={{ padding: 0, margin: 0, fontSize: "12px" }}
                        className="font-bold"
                      >
                        الراتب:{" "}
                      </span>
                      <div className="flex flex-row justify-space-between gap-10 align-center">
                        <div className="position-relative full-width">
                          <Input
                            type="text"
                            name="amount"
                            className={`full-width height-50 border-radius place-holder background-div-${theme} color-${theme}`}
                            placeholder="الراتب"
                            defaultValue={formData.amount}
                            onChange={(e) =>
                              setFormData((prevFormData) => {
                                return {
                                  ...prevFormData,
                                  amount: e.target.value,
                                };
                              })
                            }
                            size="large"
                            addonAfter={<span>ر.س</span>}
                          />
                        </div>
                      </div>
                    </li>
                    <li className="flex flex-column justify-start position-relative">
                      <span
                        style={{ padding: 0, margin: 0, fontSize: "12px" }}
                        className="font-bold"
                      >
                        الايميل:{" "}
                      </span>
                      <Input
                        type="email"
                        name="email"
                        className={`full-width height-50 border-radius place-holder p-r-10 background-div-${theme} color-${theme}`}
                        placeholder="الايميل"
                        defaultValue={formData.email}
                        onChange={(e) =>
                          setFormData((prevFormData) => {
                            return {
                              ...prevFormData,
                              email: e.target.value,
                            };
                          })
                        }
                      />
                    </li>
                    <li className="flex flex-column justify-start position-relative">
                      <span
                        style={{ padding: 0, margin: 0, fontSize: "12px" }}
                        className="font-bold"
                      >
                        الجنس:{" "}
                      </span>

                      <Select
                        name="sex"
                        className={`full-width height-50 border-radius place-holder background-div-${theme} color-${theme}`}
                        defaultValue={formData.sex}
                        onChange={(e) =>
                          setFormData((prevFormData) => {
                            return {
                              ...prevFormData,
                              sex: e,
                            };
                          })
                        }
                        placeholder="الجنس"
                        options={[
                          {
                            value: "male",
                            label: "ذكر",
                          },
                          {
                            value: "female",
                            label: "أنثى",
                          },
                        ]}
                      />
                    </li>
                    <li className="flex flex-column justify-start position-relative">
                      <span
                        style={{ padding: 0, margin: 0, fontSize: "12px" }}
                        className="font-bold"
                      >
                        الجنسية:{" "}
                      </span>
                      <Select
                        name="countrycode"
                        showSearch
                        className={`full-width height-50 border-radius`}
                        defaultValue={formData.countrycode}
                        onChange={(e) =>
                          setFormData((prevFormData) => {
                            return {
                              ...prevFormData,
                              countrycode: e,
                            };
                          })
                        }
                        optionFilterProp="label"
                        placeholder="الجنسية"
                        options={conteries.map((item) => ({
                          value: item.countrycode,
                          label: item.countryarnationality,
                        }))}
                      />
                    </li>
                    <li className="flex flex-column justify-start position-relative">
                      <span
                        style={{ padding: 0, margin: 0, fontSize: "12px" }}
                        className="font-bold"
                      >
                        تاريخ الميلاد:{" "}
                      </span>

                      <DatePicker
                        style={{
                          width: "100%",
                          height: "50px",
                          borderRadius: "10px",
                        }}
                        size={"large"}
                        placeholder="تاريخ الميلاد"
                        defaultValue={dayjs(formData.birthdate, "YYYY-MM-DD")}
                        onChange={(date, dateString) =>
                          setFormData((prevFormData) => {
                            return {
                              ...prevFormData,
                              birthdate: dateString,
                            };
                          })
                        }
                      />

                      <div
                        className={`opacity-8 p-inline-5 ltr border-radius font-small border-1-${theme} align-center text-align-center flex`}
                        style={{
                          fontSize: "8px",
                          margin: "1.5px",
                          width: "fit-content",
                        }}
                      >
                        {dayjs(formData.birthdate).fromNow()}
                      </div>
                    </li>
                  </ul>
                </div>

                <div
                  className={`flex flex-column align-start justify-start p-10 border-radius`}
                  style={{ width: "360px" }}
                >
                  <h1>الوثائق</h1>
                  <ul className="flex flex-column">
                    <li className="flex flex-column justify-start position-relative">
                      <span
                        style={{ padding: 0, margin: 0, fontSize: "12px" }}
                        className="font-bold"
                      >
                        رقم الهوية:{" "}
                      </span>
                      <Input
                        type="text"
                        name="idNumber"
                        className={`full-width height-50 border-radius background-div-${theme} color-${theme}`}
                        placeholder="رقم الهوية"
                        defaultValue={formData.idNumber}
                        onChange={(e) =>
                          setFormData((prevFormData) => {
                            return {
                              ...prevFormData,
                              idNumber: e.target.value,
                            };
                          })
                        }
                      />
                    </li>
                    <li className="flex flex-column justify-start position-relative">
                      <span
                        style={{ padding: 0, margin: 0, fontSize: "12px" }}
                        className="font-bold"
                      >
                        تاريخ انتهاء الهوية:{" "}
                      </span>

                      <DatePicker
                        style={{
                          width: "100%",
                          height: "50px",
                          borderRadius: "10px",
                        }}
                        size={"large"}
                        placeholder="تاريخ انتهاء الهوية"
                        defaultValue={dayjs(
                          formData.idExpireDate,
                          "YYYY-MM-DD"
                        )}
                        onChange={(date, dateString) =>
                          setFormData((prevFormData) => {
                            return {
                              ...prevFormData,
                              idExpireDate: dateString,
                            };
                          })
                        }
                      />

                      <div
                        className={`opacity-8 p-inline-5 ltr border-radius font-small border-1-${theme} align-center text-align-center flex`}
                        style={{
                          fontSize: "8px",
                          margin: "1.5px",
                          width: "fit-content",
                        }}
                      >
                        {dayjs(formData.idExpireDate).fromNow()}
                      </div>
                    </li>
                    <li className="flex flex-column justify-start position-relative">
                      <span
                        style={{ padding: 0, margin: 0, fontSize: "12px" }}
                        className="font-bold"
                      >
                        رقم الجواز:{" "}
                      </span>
                      <Input
                        type="text"
                        name="passportNumber"
                        className={`full-width height-50 border-radius place-holder p-r-10 background-div-${theme} color-${theme}`}
                        placeholder="رقم جواز السفر"
                        defaultValue={formData.passportNumber}
                        onChange={(e) =>
                          setFormData((prevFormData) => {
                            return {
                              ...prevFormData,
                              passportNumber: e.target.value,
                            };
                          })
                        }
                      />
                    </li>
                    <li className="flex flex-column justify-start position-relative">
                      <span
                        style={{ padding: 0, margin: 0, fontSize: "12px" }}
                        className="font-bold"
                      >
                        تاريخ انتهاء الجواز:{" "}
                      </span>

                      <DatePicker
                        style={{
                          width: "100%",
                          height: "50px",
                          borderRadius: "10px",
                        }}
                        size={"large"}
                        placeholder="تاريخ انتهاء الجواز"
                        defaultValue={dayjs(
                          formData.passExpireDate,
                          "YYYY-MM-DD"
                        )}
                        onChange={(date, dateString) =>
                          setFormData((prevFormData) => {
                            return {
                              ...prevFormData,
                              passExpireDate: dateString,
                            };
                          })
                        }
                      />

                      <div
                        className={`opacity-8 p-inline-5 ltr border-radius font-small border-1-${theme} align-center text-align-center flex`}
                        style={{
                          fontSize: "8px",
                          margin: "1.5px",
                          width: "fit-content",
                        }}
                      >
                        {dayjs(formData.passExpireDate).fromNow()}
                      </div>
                    </li>
                  </ul>
                </div>

                <div
                  className={`flex flex-column align-start justify-start p-10 border-radius`}
                  style={{ width: "360px" }}
                >
                  <h1>بيانات الوظيفة</h1>
                  <ul className="flex flex-column">
                    <li className="flex flex-column justify-start position-relative">
                      <span
                        style={{ padding: 0, margin: 0, fontSize: "12px" }}
                        className="font-bold"
                      >
                        تاريخ بداية العمل :{" "}
                      </span>

                      <DatePicker
                        style={{
                          width: "100%",
                          height: "50px",
                          borderRadius: "10px",
                        }}
                        size={"large"}
                        placeholder="تاريخ بداية الوظيفة"
                        defaultValue={
                          formData.startInPosition &&
                          dayjs(formData.startInPosition, "YYYY-MM-DD")
                        }
                        onChange={(date, dateString) =>
                          setFormData((prevFormData) => {
                            return {
                              ...prevFormData,
                              startInPosition: dateString,
                            };
                          })
                        }
                      />

                      <div
                        className={`opacity-8 p-inline-5 ltr border-radius font-small border-1-${theme} align-center text-align-center flex`}
                        style={{
                          fontSize: "8px",
                          margin: "1.5px",
                          width: "fit-content",
                        }}
                      >
                        {dayjs(formData.startInPosition).fromNow()}
                      </div>
                    </li>
                    <li className="flex flex-column justify-start position-relative">
                      <span
                        style={{ padding: 0, margin: 0, fontSize: "12px" }}
                        className="font-bold"
                      >
                        تاريخ نهاية العمل:{" "}
                      </span>

                      <DatePicker
                        style={{
                          width: "100%",
                          height: "50px",
                          borderRadius: "10px",
                        }}
                        size={"large"}
                        placeholder="تاريخ انتهاء الوظيفة"
                        defaultValue={
                          formData.endInPosition &&
                          dayjs(formData.endInPosition, "YYYY-MM-DD")
                        }
                        onChange={(date, dateString) =>
                          setFormData((prevFormData) => {
                            return {
                              ...prevFormData,
                              endInPosition: dateString,
                            };
                          })
                        }
                      />

                      <span>{formData.endInPosition}</span>
                      <div
                        className={`opacity-8 p-inline-5 ltr border-radius font-small border-1-${theme} align-center text-align-center flex`}
                        style={{
                          fontSize: "8px",
                          margin: "1.5px",
                          width: "fit-content",
                        }}
                      >
                        {dayjs(formData.endInPosition).fromNow()}
                      </div>
                    </li>
                  </ul>
                </div>

                <div
                  className={`flex flex-column align-start justify-start p-10 border-radius`}
                  style={{ width: "360px" }}
                >
                  <h1>بيانات العقد</h1>
                  <ul className="flex flex-column">
                    <li className="flex flex-column justify-start position-relative">
                      <span
                        style={{ padding: 0, margin: 0, fontSize: "12px" }}
                        className="font-bold"
                      >
                        حالة العقد :{" "}
                      </span>
                      <div className="flex flex-row align-center justify-start gap-10">
                        <Switch
                          checkedChildren={"جاري"}
                          unCheckedChildren={"منتهي"}
                          defaultChecked={formData.inwork === 1 && true}
                          onChange={(checked) =>
                            checked
                              ? setFormData((prevFormData) => {
                                  return {
                                    ...prevFormData,
                                    inwork: "1",
                                  };
                                })
                              : setFormData((prevFormData) => {
                                  return {
                                    ...prevFormData,
                                    inwork: "0",
                                  };
                                })
                          }
                          style={{
                            width: "60px",
                          }}
                        />
                        <span>
                          {formData.inwork === 1
                            ? "على رأس العمل"
                            : "انتهى عقده"}
                        </span>
                      </div>
                    </li>

                    <li className="flex flex-column justify-start position-relative">
                      <span
                        style={{ padding: 0, margin: 0, fontSize: "12px" }}
                        className="font-bold"
                      >
                        الفرع :{" "}
                      </span>

                      <Select
                        name="positionId"
                        showSearch
                        className={`full-width height-50 border-radius`}
                        defaultValue={formData.branchId}
                        onChange={(e) =>
                          setFormData((prevFormData) => {
                            return {
                              ...prevFormData,
                              branchId: e,
                            };
                          })
                        }
                        optionFilterProp="label"
                        placeholder="الوظيفة"
                        options={branchList.map((item) => ({
                          value: item.id,
                          label: item.title,
                        }))}
                      />
                    </li>
                    <li className="flex flex-column justify-start position-relative">
                      <span
                        style={{ padding: 0, margin: 0, fontSize: "12px" }}
                        className="font-bold"
                      >
                        تاريخ بداية العقد:{" "}
                      </span>

                      <DatePicker
                        style={{
                          width: "100%",
                          height: "50px",
                          borderRadius: "10px",
                        }}
                        size={"large"}
                        placeholder="تاريخ بداية العقد"
                        defaultValue={dayjs(formData.start, "YYYY-MM-DD")}
                        onChange={(date, dateString) =>
                          setFormData((prevFormData) => {
                            return {
                              ...prevFormData,
                              start: dateString,
                            };
                          })
                        }
                      />

                      <div
                        className={`opacity-8 p-inline-5 ltr border-radius font-small border-1-${theme} align-center text-align-center flex`}
                        style={{
                          fontSize: "8px",
                          margin: "1.5px",
                          width: "fit-content",
                        }}
                      >
                        {dayjs(formData.start).fromNow()}
                      </div>
                    </li>
                    <li className="flex flex-column justify-start position-relative">
                      <span
                        style={{ padding: 0, margin: 0, fontSize: "12px" }}
                        className="font-bold"
                      >
                        تاريخ نهاية العقد:{" "}
                      </span>

                      <DatePicker
                        style={{
                          width: "100%",
                          height: "50px",
                          borderRadius: "10px",
                        }}
                        size={"large"}
                        placeholder="تاريخ انتهاء العقد"
                        defaultValue={
                          formData.end && dayjs(formData.end, "YYYY-MM-DD")
                        }
                        onChange={(date, dateString) =>
                          setFormData((prevFormData) => {
                            return {
                              ...prevFormData,
                              end: dateString,
                            };
                          })
                        }
                      />

                      <div
                        className={`opacity-8 p-inline-5 ltr border-radius font-small border-1-${theme} align-center text-align-center flex`}
                        style={{
                          fontSize: "8px",
                          margin: "1.5px",
                          width: "fit-content",
                        }}
                      >
                        {dayjs(formData.end).fromNow()}
                      </div>
                    </li>
                  </ul>
                </div>

                <button
                  className={`save-button shadow-${theme} font-large`}
                  style={{ position: "absolute", left: "120px", top: "10px" }}
                  onClick={() => {
                    sendData();
                  }}
                >
                  حفظ <SaveOutlined />
                </button>
              </div>
            </>
          ) : (
            <h1>لا توجد بيانات</h1>
          )}
        </div>
      </ParentComp>
    </>
  );
}

export default UpdateEmployee;
