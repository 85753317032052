import { Image, Skeleton, Typography } from "antd";
import React, { useContext } from "react";
import { AuthCountext } from "./AuthCountext";
import { Link } from "react-router-dom";
import { PhoneOutlined, WhatsAppOutlined } from "@ant-design/icons";

function EmployeeProfile({ width, data, isLoading }) {
  const { SERVER_URL, theme } = useContext(AuthCountext);
  const { Title } = Typography;
  console.log(width);
  return (
    <div
      className="flex gap-20 full-width position-relative"
      style={{
        flexDirection: width < 750 ? "column" : "row",
      }}
    >
      <div
        className="flex flex-column align-start"
        style={{ alignItems: width < 900 ? "center" : "start" }}
      >
        <div>
          {isLoading ? (
            <Skeleton.Image active style={{ width: 220, height: 290 }} />
          ) : (
            <Image
              src={`${SERVER_URL}/${data.photo}`}
              width={220}
              height={290}
              alt={data.name}
              style={{
                borderRadius: "10px",
                objectFit: "cover",
                overflow: "hidden",
              }}
            />
          )}
        </div>
      </div>
      <div
        className="flex flex-column justify-space-between full-width"
        style={{ alignItems: width < 900 ? "center" : "start" }}
      >
        <div className="flex flex-row justify-start gap-5">
          <div className="flex flex-column">
            {isLoading ? (
              <Skeleton.Image active style={{ width: 100, height: 90 }} />
            ) : (
              <Image
                placeholder={true}
                src={`${SERVER_URL}/${data.idcopy}`}
                width={100}
                height={90}
                alt={data.name}
                style={{ borderRadius: "10px", objectFit: "cover" }}
              />
            )}
            <span style={{ opacity: "0.7" }}>الهوية</span>
          </div>
          <div className="flex flex-column">
            {isLoading ? (
              <Skeleton.Image active style={{ width: 100, height: 90 }} />
            ) : (
              <Image
                src={`${SERVER_URL}/${data.passport}`}
                width={100}
                height={90}
                alt={data.name}
                style={{ borderRadius: "10px", objectFit: "cover" }}
              />
            )}
            <span style={{ opacity: "0.7" }}>الجواز</span>
          </div>
        </div>
        {isLoading ? (
          <Skeleton />
        ) : (
          <div>
            <div className="text-align-start">
              <Title level={3} style={{ margin: "0" }}>
                {data.name}
              </Title>
            </div>
            <div className="opacity-8 font-large flex flex-row align-center gap-5">
              <span style={{ opacity: "0.7", fontWeight: "bold" }}>
                {data.title}
              </span>{" "}
              | <span>{data.phone}</span>
              <Link
                className="flex align-center"
                to={`tel:966${data.phone?.slice(1)}`}
              >
                <PhoneOutlined className={`color-${theme}`} />
              </Link>
              <Link
                className="flex align-center"
                to={`https://wa.me/966${data.phone?.slice(1)}`}
                target="_blank"
              >
                <WhatsAppOutlined className={`color-${theme}`} />
              </Link>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default EmployeeProfile;
