import React, { useContext, useEffect, useState } from "react";
import { AuthCountext } from "./AuthCountext";

function Loader({ size, color }) {
  const { SERVER_URL, userToken, branchId } = useContext(AuthCountext);
  const [data, setData] = useState([]);
  const fetchBranch = async () => {
    try {
      const response = await fetch(`${SERVER_URL}/branch/${branchId}`, {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "x-api-key": userToken,
        },
      });
      const json = await response.json();
      if (json.length > 0) {
        setData(json);
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    fetchBranch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [branchId]);
  let w = 0;
  let borderSize = 0;
  size === "small" ? (w = 15) : size === "medium" ? (w = 50) : (w = 120);
  size === "small" || size === "medium" ? (borderSize = 2) : (borderSize = 4);
  let borderColor;
  color === "white"
    ? (borderColor = "#f5f4f4")
    : color === "gray"
    ? (borderColor = "gray")
    : (borderColor = "#3498db");
  return (
    <div className="flex justify-center align-center">
      <div
        className="loader justify-center align-center"
        style={{
          width: w,
          aspectRatio: 1,
          borderColor: borderColor,
          borderTopColor: "transparent",
          borderWidth: borderSize,
          filter: "invert(0)",
        }}
      ></div>
      {data[0] && (
        <img
          src={`${SERVER_URL}/${data[0]?.logo}`}
          style={{
            maxWidth: w - w * 0.4,
            objectFit: "contain",
            position: "absolute",
            opacity: "0.8",
          }}
          alt={data[0]?.title}
        />
      )}
    </div>
  );
}

export default Loader;
