import { useContext, useEffect, useState } from "react";
import { AuthCountext } from "../AuthCountext";

function FetchBranch() {
  const { userToken, branchId, SERVER_URL } = useContext(AuthCountext);
  const [data, setData] = useState();
  const fetchBranch = async () => {
      try {
        const response = await fetch(`${SERVER_URL}/branch/${branchId}`, {
          method: "GET",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "x-api-key": userToken,
          },
        });
        const json = await response.json();
        if (json.length > 0) {
          setData(json[0]);
        }
      } catch (error) {
        console.error(error);
      }
    };
  
    useEffect(() => {
      fetchBranch();
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [branchId]);
  

  return data;
}

export default FetchBranch;
