import React, { useState } from "react";
import { Button as Bt } from "antd";
import {
  DeleteOutlined,
  EditOutlined,
  FilePdfOutlined,
  PlusOutlined,
  SaveOutlined,
} from "@ant-design/icons";

function Button({
  button,
  onClickMethod,
  buttonId,
  id,
  disable,
  loading,
  title,
  danger,
  type,
  ispending,
  index,
  loadings,
}) {
  const [isHovered, setIsHovered] = useState(false);

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  return (
    <>
      <Bt
        style={{
          marginBlock: "10px",
          backgroundColor:
            button === "pdf" &&
            (isHovered ? "rgba(244, 15, 2, 0.9)" : "rgba(244, 15, 2, 0.8)"),
          borderRadius: button === "pdf" && "50%",
          fontSize: "small",
        }}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        size="large"
        type={type}
        disabled={buttonId === id && disable}
        
        className={`flex flex-row ${
          loadings ? `justify-space-between` : `justify-center`
        } align-center`}
        loading={loading}
        danger={danger}
        onClick={onClickMethod}
        icon={
          button === "delete" ? (
            <DeleteOutlined />
          ) : button === "edit" ? (
            <EditOutlined />
          ) : button === "save" ? (
            <SaveOutlined />
          ) : button === "pdf" ? (
            <FilePdfOutlined />
          ) : (
            <PlusOutlined />
          )
        }
      >
        {title}
      </Bt>
    </>
  );
}

export default Button;
