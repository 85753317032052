import ParentComp from "../ParentComp";
import InvoicesComp from "./InvoicesComp";

function Invoices() {
  return (
    <ParentComp
      title={"أمر بيع - صرف"}
      prevPage={"الصادر"}
      linkto={"/inventory/InvoicesList"}
    >
      <InvoicesComp />
    </ParentComp>
  );
}

export default Invoices;
