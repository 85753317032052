import React, { useContext } from "react";
import { AuthCountext } from "../AuthCountext";

function CutPlaceHolder() {
  const { theme } = useContext(AuthCountext);
  return (
    <ul className={`border-bottom-${theme}-1`} style={{height: '60px', display: 'flex'}}>
      <li className="p-5 flex flex-row align-center justify-start width-5-100">
        <div
          style={{
            width: "100%",
            borderRadius: "5px",
            height: "40PX",
          }}
          className={`place-holder-background-${theme}`}
        ></div>
      </li>
      <li className="p-5 flex flex-row align-center text-align-start justify-start width-20-100 gap-10">
        <div
          style={{
            width: "100%",
            borderRadius: "5px",
            height: "40PX",
          }}
          className={`place-holder-background-${theme}`}
        ></div>
      </li>
      <li className="p-5 flex flex-row align-center justify-start width-10">
        <div
          style={{
            width: "100%",
            borderRadius: "5px",
            height: "40PX",
          }}
          className={`place-holder-background-${theme}`}
        ></div>
      </li>
      <li className="p-5 flex flex-row align-center text-align-start justify-start width-10">
        <div
          style={{
            width: "100%",
            borderRadius: "5px",
            height: "40PX",
          }}
          className={`place-holder-background-${theme}`}
        ></div>
      </li>
      <li className="p-5 flex flex-row align-center justify-start width-10">
        <div
          style={{
            width: "100%",
            borderRadius: "5px",
            height: "40PX",
          }}
          className={`place-holder-background-${theme}`}
        ></div>
      </li>

      <li className="p-5 flex flex-row align-center justify-start width-10">
        <div
          style={{
            width: "100%",
            borderRadius: "5px",
            height: "40PX",
          }}
          className={`place-holder-background-${theme}`}
        ></div>
      </li>
      <li className="p-5 flex flex-row align-center justify-start width-10">
        <div
          style={{
            width: "100%",
            borderRadius: "5px",
            height: "40PX",
          }}
          className={`place-holder-background-${theme}`}
        ></div>
      </li>
    </ul>
  );
}

export default CutPlaceHolder;
