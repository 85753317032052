import ParentComp from "../ParentComp";
import PurchaseOrdersEditComp from "./PurchaseOrdersEditComp";

function PurchaseOrdersEdit() {
  return (
    <ParentComp
      title={"تعديل أمر شراء"}
      prevPage={"الوارد"}
      linkto={"/Inventory/PurchaseOrdersList"}
    >
      <PurchaseOrdersEditComp />
    </ParentComp>
  );
}

export default PurchaseOrdersEdit;
