import ParentComp from "../ParentComp";
import PurchaseOrdersVeiwComp from "./PurchaseOrdersVeiwComp";

function PurchaseOrdersVeiw() {
  return (
    <ParentComp
      title={"استعراض أمر شراء"}
      prevPage={"الوارد"}
      linkto={"/Inventory/PurchaseOrdersList"}
    >
      <PurchaseOrdersVeiwComp />
    </ParentComp>
  );
}

export default PurchaseOrdersVeiw;