import { InputNumber } from "antd";
import React, {
  memo,
  useContext,
  useEffect,
  useState,
  useCallback,
} from "react";
import { AuthCountext } from "../AuthCountext";

const VAT_RATE = 0.15;

function GetItemPrice({
  ItemID,
  WarehouseID,
  item,
  setFormData,
  index,
  price,
  min,
  max,
  edit,
}) {
  const { ACCOUNNTING_URL, userToken } = useContext(AuthCountext);
  const [data, setData] = useState(1); // Default price

  // Helper functions
  const calculateVAT = useCallback((UnitPrice, quantity) => {
    return Math.round(UnitPrice * quantity * VAT_RATE * 100) / 100;
  }, []);

  const calculateTotalPrice = useCallback(
    (UnitPrice, quantity) => {
      const vat = calculateVAT(UnitPrice, quantity);
      return Math.round((UnitPrice * quantity + vat) * 100) / 100;
    },
    [calculateVAT]
  );

  // Fetch item price
  const fetchItemPrice = useCallback(async () => {
    try {
      const response = await fetch(
        `${ACCOUNNTING_URL}/getitemprice/${ItemID}`,
        {
          method: "GET",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "x-api-key": userToken,
          },
        }
      );

      const json = await response.json();
      if (json.length > 0) {
        setData(json[0].PricePerUnit || 1);

        // Initialize form data
        setFormData((draft) => {
          draft.invoicedetails[index][price] =
            draft.invoicedetails[index][price] ||
            json[0].PricePerUnit / draft.invoicedetails[index].ConversionRate;

          draft.invoicedetails[index].Vat =
            draft.invoicedetails[index].Vat ||
            roundFunction(
              (draft.invoicedetails[index][price] ||
                json[0].PricePerUnit /
                  draft.invoicedetails[index].ConversionRate) *
                0.15 *
                draft.invoicedetails[index].Quantity
            );
          draft.invoicedetails[index].TotalPrice =
            draft.invoicedetails[index].TotalPrice ||
            roundFunction(
              (draft.invoicedetails[index][price] ||
                json[0].PricePerUnit /
                  draft.invoicedetails[index].ConversionRate) *
                draft.invoicedetails[index].Quantity +
                draft.invoicedetails[index].Vat
            );
        });
      } else {
        setData(1); // Reset to default if no data found
      }
    } catch (error) {
      console.error("Error fetching item price:", error);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ItemID, item.UnitID]);

  // Effect to fetch item price
  useEffect(() => {
    fetchItemPrice();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ItemID, item.UnitID]);

  // Handle price change
  const handlePriceChange = useCallback(
    (e) => {
      setFormData((draft) => {
        draft.invoicedetails[index][price] = e;
        draft.invoicedetails[index].Vat = calculateVAT(
          e,
          draft.invoicedetails[index].Quantity
        );
        draft.invoicedetails[index].TotalPrice = calculateTotalPrice(
          e,
          draft.invoicedetails[index].Quantity
        );
      });
    },
    [setFormData, index, price, calculateVAT, calculateTotalPrice]
  );

  const roundFunction = useCallback((x) => {
    return Math.round((x + Number.EPSILON) * 100) / 100;
  }, []);

  return (
    <InputNumber
      size="large"
      addonAfter={
        <span style={{ fontSize: "small" }}>
          {roundFunction(data / (item.ConversionRate || 1))}
        </span>
      }
      min={min ? roundFunction(data / item.ConversionRate) : 0}
      max={max ? roundFunction(data / item.ConversionRate) : false}
      placeholder="سعر الوحدة"
      value={roundFunction(parseFloat(item[price]))}
      name={price}
      className="height-40 full-width"
      onChange={handlePriceChange}
    />
  );
}

export default memo(GetItemPrice);
