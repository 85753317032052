import React, { useState, useContext } from "react";
import { Modal, Input, Select, Button } from "antd";

import { AuthCountext } from "../AuthCountext";
import FetchInventory from "./FetchInventoryData";
import AlertComp from "../subcomponent/AlertComp";
import MyTable from "./MyTable";
import { DeleteFilled, EditFilled } from "@ant-design/icons";

const ItemsComp = ({ openModal, setOpenModal }) => {
  const { theme, ACCOUNNTING_URL, setaccountData, userToken, branchId } =
    useContext(AuthCountext);
  const [isloading, setIsloading] = useState(true);
  const [disable, setDisable] = useState(false);
  const [isRefresh, setIsRefresh] = useState(false);
  const [alertMsg, setAlertMsg] = useState(null);
  const [alertName, setAlertName] = useState(null);
  const [isAlert, setIsAlert] = useState(false);

  const [confirmLoading, setConfirmLoading] = useState(false);
  const [confirmEditLoading, setConfirmEditLoading] = useState(false);
  const [openEditModal, setOpenEditModal] = useState(false);
  const categoriesList = FetchInventory().categories;
  const [formData, setFormData] = useState({
    Name: null,
    Description: null,
    CategoryID: null,
    company_id: branchId,
    created_by: userToken,
  });

  // fetch

  // end fetch

  // insert data

  const addData = async () => {
    setDisable(true);
    try {
      const response = await fetch(`${ACCOUNNTING_URL}/items`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "x-api-key": userToken,
        },
        body: JSON.stringify(formData),
      });
      const json = await response.json();
      console.log(json);
      setIsAlert(true);
      setAlertMsg("تمت اضافة السجل بنجاح");
      setAlertName("alert-success");
      alertShow();
      setDisable(false);
      setIsRefresh(!isRefresh);
      setaccountData(true);
    } catch (error) {
      console.error(error);
    }
  };

  const validationFun = () => {
    if (
      formData.CategoryID !== null ||
      formData.Name !== "" ||
      formData.company_id !== "" ||
      formData.Description !== ""
    ) {
      addData();
      setDisable(true);
      setConfirmLoading(true);
      setTimeout(() => {
        setOpenModal(false);
        setConfirmLoading(false);
      }, 2000);
    } else {
      console.log("no valid");
      setIsAlert(true);
      setAlertMsg("ادخل جميع الحقول");
      setAlertName("alert-warning");
      alertShow();
    }
  };

  // end insert data

  // delete
  const deleteData = async (id) => {
    try {
      const response = await fetch(`${ACCOUNNTING_URL}/items/delete/${id}`, {
        method: "DELETE",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "x-api-key": userToken,
        },
      });
      const json = await response.json();
      setIsRefresh(!isRefresh);
      setIsAlert(true);
      setAlertMsg("تم حذف السجل بنجاح");
      setAlertName("alert-success");
      alertShow();
      if (json.errore.errorInfo[0] === "23000") {
        setIsAlert(true);
        setAlertMsg("لا يمكنك حذف السجل لارتباطه بسجلات اخرى.");
        setAlertName("alert-danger");
      }
      alertShow();
    } catch (error) {
      console.error(error);
    }
  };

  const alertShow = () => {
    const timer = setTimeout(() => setIsAlert(false), 3000);
    return () => {
      clearTimeout(timer);
    };
  };

  // end delete

  // edit
  const [editFormData, setEditFormData] = useState({
    ItemID: null,
    Name: null,
    Description: null,
    company_id: branchId,
    updated_by: userToken,
    CategoryID: false,
  });

  const editData = async (id) => {
    try {
      const response = await fetch(`${ACCOUNNTING_URL}/items/`, {
        method: "PUT",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "x-api-key": userToken,
        },
        body: JSON.stringify(editFormData),
      });
      const json = await response.json();
      console.log(json);
      setIsAlert(true);
      setAlertMsg("تم تعديل السجل بنجاح");
      setAlertName("alert-success");
      alertShow();
      setIsRefresh(!isRefresh);
      setConfirmEditLoading(true);
      setTimeout(() => {
        setOpenEditModal(false);
        setConfirmEditLoading(false);
      }, 1000);
    } catch (error) {
      console.error(error);
    }
  };

  const editValidationFun = () => {
    if (
      editFormData.ItemID !== null &&
      editFormData.Name !== null &&
      editFormData.CategoryID !== null
    ) {
      editData(editFormData.ItemID);
    } else {
      console.log("no valid");
      setIsAlert(true);
      setAlertMsg("ادخل جميع الحقول");
      setAlertName("alert-warning");
      setOpenEditModal(false);
      alertShow();
    }
  };
  // function handleEditSubmit(event) {
  //   event.preventDefault();
  //   editValidationFun();
  //   // setDisabledAria(false);
  // }

  function handleEdit(x, y) {
    console.log(x);
    setEditFormData((prev) => {
      return {
        ...prev,
        ItemID: x.ItemID,
        created_by: x.created_by,
        Name: x.Name,
        Description: x.Description,
        CategoryID: x.CategoryID,
        updated_by: userToken,
      };
    });
    setOpenEditModal(true);
  }

  function handleEditCancel() {
    setEditFormData(null);
    setOpenEditModal(false);
    setConfirmEditLoading(false);
  }

  // end edit

  // others

  const columns = [
    {
      title: "حذف",
      render: (_, record) => (
        <Button
          danger
          color="danger"
          type="text"
          onClick={() => deleteData(record.ItemID, true)}
          id={record.ItemID}
          loading={isloading}
          icon=<DeleteFilled />
        >
          حذف
        </Button>
      ),
    },
    {
      title: "تعديل",
      render: (_, record) => (
        <Button
          color="primary"
          className={`color-primary-${theme}`}
          type="text"
          onClick={() => handleEdit(record, true)}
          id={record.ItemID}
          disable={disable}
          loading={isloading}
          icon=<EditFilled />
        >
          تعديل
        </Button>
      ),
    },
    { title: "فئة الصنف", dataKey: "CategoryName" },
    { title: "اسم الصنف", dataKey: "Name" },
    { title: "رقم الصنف", dataKey: "ItemID" },
  ];


  const options = [
    {
      value: "Items",
      label: "قائمة الأصناف",
      pageDirction: "p",
    }
  ]

  return (
    <div
      className={`${
        isloading
          ? "flex flex-row justify-center align-center "
          : " justify-space-between "
      } flex flex-column justify-center media-flex-culomn align-flex-start shadow-${theme} full-width-media border-radius text-align-center background-div-${theme} full-width min-height-300 p-20`}
      style={{ fontSize: "small" }}
    >
      <div className="full-width" style={{ minHeight: "580px" }}>
        <div className="flex flex-row justify-space-between align-center gap-10">
          {isAlert && <AlertComp msg={alertMsg} alert={alertName} />}

          <Modal
            key={1}
            title="إضافة صنف"
            open={openModal}
            onOk={validationFun}
            confirmLoading={confirmLoading}
            onCancel={() => setOpenModal(false)}
          >
            <form
              className="flex flex-column justify-center align-center gap-20 media-flex-column full-width"
              style={{ padding: "20px" }}
            >
              {isAlert && <div className={`${alertName}`}>{alertMsg}</div>}

              <div className="full-width">
                <span>اسم الصنف</span>
                <Input
                  type="text"
                  name="Name"
                  className={`full-width height-50 border-radius background-div-${theme} color-${theme}`}
                  placeholder="اسم الصنف"
                  onChange={(e) => {
                    setFormData((prev) => {
                      return {
                        ...prev,
                        Name: e.target.value,
                      };
                    });
                  }}
                />
              </div>
              <div className="full-width">
                <span>وصف الصنف</span>
                <Input
                  type="text"
                  name="Description"
                  className={`full-width height-50 border-radius background-div-${theme} color-${theme}`}
                  placeholder="وصف الصنف"
                  onChange={(e) => {
                    setFormData((prev) => {
                      return {
                        ...prev,
                        Description: e.target.value,
                      };
                    });
                  }}
                />
              </div>
              <div className="full-width">
                <span>الفئة</span>
                <Select
                  name="CategoryID"
                  showSearch
                  className={`full-width height-50 border-radius`}
                  onChange={(e) => {
                    setFormData((prev) => {
                      return {
                        ...prev,
                        CategoryID: e,
                      };
                    });
                  }}
                  optionFilterProp="label"
                  placeholder={isloading ? "جارٍ التحميل" : "الفئة"}
                  options={categoriesList.map((item) => ({
                    value: item.CategoryID,
                    label: `${item.CategoryID} - ${item.CategoryName}`,
                  }))}
                />
              </div>
            </form>
          </Modal>
          <Modal
            key={2}
            title="تعديل صنف"
            open={openEditModal}
            onOk={editValidationFun}
            confirmLoading={confirmEditLoading}
            onCancel={handleEditCancel}
            destroyOnClose={true}
          >
            <form
              className="flex flex-column justify-center align-center gap-20 media-flex-column full-width"
              style={{ padding: "20px" }}
            >
              {isAlert && <div className={`${alertName}`}>{alertMsg}</div>}
              <div className="full-width">
                <span>اسم الصنف</span>
                <Input
                  type="text"
                  name="Name"
                  className={`full-width height-50 border-radius background-div-${theme} color-${theme}`}
                  defaultValue={editFormData?.Name}
                  placeholder="اسم الصنف"
                  onChange={(e) => {
                    setEditFormData((prev) => {
                      return {
                        ...prev,
                        Name: e.target.value,
                      };
                    });
                  }}
                />
              </div>
              <div className="full-width">
                <span>وصف الصنف</span>
                <Input
                  type="text"
                  name="Description"
                  className={`full-width height-50 border-radius background-div-${theme} color-${theme}`}
                  defaultValue={editFormData?.Description}
                  placeholder="وصف الصنف"
                  onChange={(e) => {
                    setEditFormData((prev) => {
                      return {
                        ...prev,
                        Description: e.target.value,
                      };
                    });
                  }}
                />
              </div>
              <div className="full-width">
                <span>الفئة</span>
                <Select
                  name="CategoryID"
                  showSearch
                  className={`full-width height-50 border-radius`}
                  defaultValue={editFormData?.CategoryID}
                  onChange={(e) => {
                    setEditFormData((prev) => {
                      return {
                        ...prev,
                        CategoryID: e,
                      };
                    });
                  }}
                  optionFilterProp="label"
                  placeholder={isloading ? "جارٍ التحميل" : "الفئة"}
                  options={categoriesList.map((item) => ({
                    value: item.CategoryID,
                    label: `${item.CategoryID} - ${item.CategoryName}`,
                  }))}
                />
              </div>
            </form>
          </Modal>
        </div>

        <MyTable
          setIsloading={setIsloading}
          isRefresh={isRefresh}
          isloading={isloading}
          columns={columns}
          page="items"
          options={options}
        />
      </div>
    </div>
  );
};

export default ItemsComp;
