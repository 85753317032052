import React, { useContext } from "react";
import { Select, DatePicker, Form, Segmented, Table } from "antd";
import { AuthCountext } from "../AuthCountext";
import Button from "../subcomponent/Button";
import WindowSize from "../subcomponent/WindowSize";
import AlertComp from "../subcomponent/AlertComp";

const StockTrackUi = ({
  columns,
  formData,
  setFormData,
  handleSubmit,
  isAlert,
  alertMsg,
  alertName,
  isloading,
  list,
  title,
  disableSaveButton,
  setDisableSaveButton,
  presetFormData,
  setType,
  setIsLoading,
  pdf,
}) => {
  const { theme } = useContext(AuthCountext);
  const windowSize = WindowSize();

  return (
    <div
      className={`
        flex flex-column justify-center media-flex-culomn align-center shadow-${theme} full-width-media border-radius text-align-center background-div-${theme} min-height-300`}
      style={{
        fontSize: "small",
        width: "100%",
        padding: windowSize[0] < 900 ? "0px" : "20px",
      }}
    >
      {isAlert && <AlertComp msg={alertMsg} alert={alertName} />}
      <div
        className="flex flex-row justify-center align-start full-width"
        style={{ minHeight: "580px" }}
      >
        <div
          className="flex flex-column gap-10 full-width media-flex-culomn"
          style={{ padding: "20px" }}
        >
          <h1>{title}</h1>
          
          <Form
            className="flex flex-column flex-wrap justify-center full-width align-start gap-20 media-flex-column"
            onSubmit={handleSubmit}
          >
            <div className="flex flex-row full-width justify-space-between">
              <div className="flex flex-column gap-5 align-start">
                <span>حالة الاستعلام</span>
                <Segmented
                  options={[
                    {
                      value: "allItems",
                      label: "جميع المواد",
                    },
                    {
                      value: "inStock",
                      label: "الموجودة في المستودع فقط",
                    },
                  ]}
                  value={formData.Status}
                  onChange={(e) => {
                    setIsLoading(true);
                    setType(e);
                  }}
                />
              </div>
              <div className="flex flex-column gap-5 align-start">
                <span>حالة الحفظ</span>
                <Segmented
                  options={[
                    {
                      value: 1,
                      label: "حفظ وتعديل المستودع",
                    },
                    {
                      value: 0,
                      label: "حفظ فقط",
                    },
                  ]}
                  value={formData.AdjustInventory}
                  onChange={(e) => {
                    setDisableSaveButton(false);
                    setFormData((draft) => {
                      draft.AdjustInventory = e;
                    });
                  }}
                />
              </div>
            </div>
            <div className="flex flex-row gap-10 full-width media-flex-culomn">
              <div
                className="flex flex-row gap-10 full-width media-flex-culomn"
                style={{ flexGrow: "1" }}
              >
                <div className="flex flex-column gap-5 align-start">
                  <span>اختر المستودع</span>
                  <Select
                    name="SupplierID"
                    showSearch
                    className={`height-50 border-radius`}
                    style={{ width: "300px" }}
                    onChange={(e) => {
                      setDisableSaveButton(false);
                      setFormData((prev) => {
                        return {
                          ...prev,
                          WarehouseID: e,
                        };
                      });
                    }}
                    optionFilterProp="label"
                    placeholder={isloading ? "جارٍ التحميل" : "اختر المستودع"}
                    options={list?.map((item) => ({
                      value: item.WarehouseID,
                      label: item.WarehouseName,
                    }))}
                  />
                </div>
                <div
                  className="flex flex-column gap-5 align-start"
                  style={{ minWidth: "200px", maxWidth: "300px" }}
                >
                  <span>التاريخ</span>
                  <DatePicker
                    // defaultValue={dayjs(item.OrderDate, "YYYY-MM-DD")}
                    style={{
                      height: "50px",
                      borderRadius: "10px",
                      width: "100%",
                    }}
                    size={"large"}
                    placeholder="التاريخ"
                    defaultValue={formData.StockTakingDate}
                    onChange={(OrderDate, OrderDateString) =>
                      setFormData((prevFormData) => {
                        return {
                          ...prevFormData,
                          StockTakingDate: OrderDateString,
                        };
                      })
                    }
                  />
                </div>
              </div>
            </div>
            {pdf}
            <div
              className={`full-width over-flow-scroll over-flow-scroll-${theme} media-flex-culomn`}
            >
              <Table
                loading={isloading}
                style={{ fontSize: "10px" }}
                size="small"
                className="full-width"
                columns={columns}
                dataSource={!isloading && formData.StockTakingDetails}
                pagination={true}
              />
            </div>
            <div
              className="flex flex-row gap-10"
              style={{ marginRight: "auto" }}
            >
              {disableSaveButton && (
                <Button
                  button={"add"}
                  title={"جرد جديد"}
                  type="primary"
                  onClickMethod={() => {
                    setDisableSaveButton(false);
                    setFormData(presetFormData);
                  }}
                  danger={false}
                />
              )}
              <Button
                button={"save"}
                // disable={disableSaveButton}
                title={"حفظ الجرد"}
                type="primary"
                onClickMethod={handleSubmit}
                danger={false}
              />
            </div>
          </Form>
        </div>
      </div>
    </div>
  );
};

export default StockTrackUi;
