import React, { useContext } from "react";
import MyTable from "./inventory/MyTable";
import { AuthCountext } from "./AuthCountext";
import AlertComp from "./subcomponent/AlertComp";

function TableComponent({
  isloading,
  isRefresh,
  setIsloading,
  columns,
  options,
  page,
  isAlert,
  alertMsg,
  alertName,
}) {
  const { theme } = useContext(AuthCountext);
  return (
    <div
      className={`${
        isloading
          ? "flex flex-row justify-center align-center "
          : " justify-space-between "
      } flex flex-column justify-center media-flex-culomn align-flex-start shadow-${theme} full-width-media border-radius text-align-center background-div-${theme} full-width min-height-300 p-20`}
      style={{ fontSize: "small" }}
    >
      <div className="full-width" style={{ minHeight: "580px" }}>
        <div className="flex flex-row justify-space-between align-center gap-10">
          {isAlert && <AlertComp msg={alertMsg} alert={alertName} />}
        </div>

        <MyTable
          setIsloading={setIsloading}
          isRefresh={isRefresh}
          isloading={isloading}
          columns={columns}
          page={page}
          options={options}
        />
      </div>
    </div>
  );
}

export default TableComponent;
