import { utils, writeFile } from "xlsx";

export default function XlsxReport(data, options, reportType) {
  const name = options?.filter((item) => item.value === reportType)[0]?.label;
  const ws = utils.json_to_sheet(data);

  const wb = utils.book_new();
  utils.book_append_sheet(wb, ws, "SheetJS");

  /* generate XLSX file and send to client */
  writeFile(wb, `${name}.xlsx`);

  return;
}
