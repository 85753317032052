import React, { memo, useContext } from "react";
import { Select, DatePicker, Form, Table, Input } from "antd";
import { AuthCountext } from "../AuthCountext";
import Button from "../subcomponent/Button";
import AlertComp from "../subcomponent/AlertComp";
import dayjs from "dayjs";
dayjs.locale("arEG");

const TransComponent = ({
  columns,
  formData,
  setFormData,
  handleSubmit,
  isAlert,
  alertMsg,
  alertName,
  isloading,
  list,
  title,
  disableSaveButton,
  setDisableSaveButton,
  presetFormData,
  orderType,
  handleAddfield,
  financialPreiod,
  setFinancialId,
  validMsg,
}) => {
  const { theme } = useContext(AuthCountext);

  const getSum = (x) => {
    let sum = 0;
    for (let i = 0; i < formData.ledger.length; i++) {
      const element = formData.ledger[i][x];
      sum += parseFloat(element);
    }
    return sum;
  };

  return (
    <div
      className={`
        flex flex-column justify-center media-flex-culomn align-center shadow-${theme} full-width-media border-radius text-align-center background-div-${theme} min-height-300 p-20`}
      style={{ fontSize: "small", width: "100%" }}
    >
      {isAlert && <AlertComp msg={alertMsg} alert={alertName} />}
      <div
        className="flex flex-row justify-center align-start full-width"
        style={{ minHeight: "580px" }}
      >
        <div
          className="flex flex-column gap-10 full-width media-flex-culomn"
          style={{ padding: "20px" }}
        >
          <h1>{title}</h1>
          <Form
            className="flex flex-column flex-wrap justify-center full-width align-start gap-20 media-flex-column"
            onSubmit={handleSubmit}
          >
            <div className="flex flex-row justify-space-between gap-10 full-width media-flex-culomn">
              <div
                className="flex flex-row gap-10 full-width media-flex-culomn"
                style={{ flexGrow: "1" }}
              >
                <div className="flex flex-column gap-5 align-start">
                  <span>التفاصيل</span>
                  <Input
                    type="text"
                    name="الوصف"
                    className={`height-50 border-radius background-div-${theme} color-${theme}`}
                    placeholder="التفاصيل"
                    defaultValue={formData.description}
                    value={formData.description}
                    onChange={(e) => {
                      setDisableSaveButton(false);
                      setFormData((draft) => {
                        draft.description = e.target.value;
                      });
                    }}
                    autoFocus
                  />
                  {validMsg(formData, "description")}
                </div>
                <div
                  className="flex flex-column gap-5 align-start"
                  style={{ width: "200px" }}
                >
                  <span>الفترة المالية</span>
                  <Select
                    name="financial_period"
                    showSearch
                    className={`height-50 border-radius full-width`}
                    value={formData.financial_period}
                    onChange={(e) => {
                      setFormData((prev) => {
                        return {
                          ...prev,
                          financial_period: e,
                        };
                      });
                    }}
                    optionFilterProp="label"
                    placeholder={isloading ? "جارٍ التحميل" : "اختر الفترة"}
                    options={financialPreiod?.map((item) => ({
                      disabled: item.is_open === (0 || "0"),
                      value: item.id,
                      label: item.name,
                    }))}
                  />
                  {validMsg(formData, "financial_period")}
                </div>
                <div
                  className="flex flex-column gap-5 align-start"
                  style={{ width: "150px", marginRight: "auto" }}
                >
                  <span>التاريخ</span>
                  <DatePicker
                    style={{
                      height: "50px",
                      borderRadius: "10px",
                    }}
                    value={formData.date && dayjs(formData?.date, "YYYY-MM-DD")}
                    size={"large"}
                    placeholder="التاريخ"
                    onChange={(date, dateString) =>
                      setFormData((prevFormData) => {
                        return {
                          ...prevFormData,
                          date: dateString,
                        };
                      })
                    }
                  />
                  {validMsg(formData, "date")}
                </div>
              </div>
            </div>
            <div
              className={`full-width over-flow-scroll over-flow-scroll-${theme} media-flex-culomn`}
            >
              <Table
                className="full-width"
                columns={columns}
                dataSource={formData.ledger}
                pagination={false}
                loading={isloading}
                scroll={{
                  y: 55 * 5,
                  x: "max-content",
                }}
                footer={(currentPageData) =>
                  getSum("credit") || getSum("debit") ? (
                    <div
                      className="flex flex-row gap-10 justify-start align-center full-width border-radius height-50"
                      style={{
                        background:
                          getSum("credit") === getSum("debit")
                            ? "#04AA6D"
                            : "#FF3E3E",
                        color: "#FAF9F6",
                      }}
                    >
                      <div style={{ width: "40%" }}>
                        {getSum("credit") === getSum("debit")
                          ? "قيد متزن"
                          : "قيد غير متزن"}
                      </div>
                      <div style={{ width: "15%" }}>{getSum("credit")}</div>
                      <div style={{ width: "15%" }}>{getSum("debit")}</div>
                    </div>
                  ) : (
                    <span>قيد جديد</span>
                  )
                }
              />
            </div>
            <div
              className="flex flex-row gap-10"
              style={{ marginRight: "auto" }}
            >
              {disableSaveButton && (
                <Button
                  button={"add"}
                  title={"قيد جديد"}
                  type="primary"
                  onClickMethod={() => {
                    setDisableSaveButton(false);
                    setFormData(presetFormData);
                  }}
                  danger={false}
                  loading={isloading}
                />
              )}
              <Button
                button={"save"}
                disable={
                  disableSaveButton || getSum("credit") !== getSum("debit")
                }
                title={"حفظ الأمر"}
                type="primary"
                onClickMethod={handleSubmit}
                danger={false}
                loading={isloading}
              />
            </div>
          </Form>
        </div>
      </div>
    </div>
  );
};

export default memo(TransComponent);
