import { Select } from "antd";
import React, {
  memo,
  useContext,
  useState,
  useCallback,
  useEffect,
} from "react";
import { AuthCountext } from "../AuthCountext";

const FetchItems = ({ data, setData, item, index, price }) => {
  const { ACCOUNNTING_URL, userToken, branchId } = useContext(AuthCountext);
  const [items, setItems] = useState([]);
  const [isloading, setIsloading] = useState(false);

  // Cache results to avoid repeated API calls
  const cache = {};

  const fetchItems = async (q) => {
    setIsloading(true);
    if (cache[branchId]) {
      setItems(cache[branchId]);
      return;
    }
    if (q) {
      try {
        const response = await fetch(
          `${ACCOUNNTING_URL}/items/${branchId}/${q}`,
          {
            method: "GET",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
              "x-api-key": userToken,
            },
          }
        );
        const json = await response.json();
        cache[branchId] = json.length > 0 ? json : [];
        setItems(cache[branchId]);
      } catch (error) {
        console.error("Error fetching items:", error);
        setItems([]);
      } finally {
        setIsloading(false);
      }
    } else {
      setItems([]);
    }
  };

  useEffect(() => {
    fetchItems(item.ItemID);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [item.ItemID]);

  const handleChange = useCallback(
    (e) => {
      setData((draft) => {
        draft.invoicedetails[index] = {
          ...draft.invoicedetails[index],
          ItemID: e,
          UnitID: null,
          ConversionRate: 1,
          [price]: null,
          Quantity: 1,
          TotalPrice: null,
          Vat: null,
        };
      });
    },
    [setData, index, price]
  );

  return (
    <Select
      autoFocus
      value={item.ItemID}
      name="ItemID"
      showSearch
      className="height-40 border-radius full-width"
      onSearch={fetchItems}
      onChange={handleChange}
      optionFilterProp="label"
      defaultValue={item?.ItemID}
      style={{minWidth: '200px'}}
      placeholder={isloading ? "جارٍ التحميل" : "ابحث في الأصناف"}
      options={items.map((item) => ({
        disabled: item.is_parent === "1" || item.is_parent === 1,
        value: item.ItemID,
        label: `${item.ItemID} ${item.Name}`,
      }))}
    />
  );
};

export default memo(FetchItems);
