import ParentComp from "../ParentComp";
import InvoicesVeiwComp from "./InvoicesVeiwComp";

function InvoicesVeiw() {
  return (
    <ParentComp
      title={"استعراض أمر صرف"}
      prevPage={"الصادر"}
      linkto={"/Inventory/invoicesList"}
    >
      <InvoicesVeiwComp />
    </ParentComp>
  );
}

export default InvoicesVeiw;