import React, { useContext } from "react";
import { AuthCountext } from "../AuthCountext";
import { Link } from "react-router-dom";
import Loader from "../Loader";
import {v4 as uuidv4} from 'uuid';

function DashboardDiv({ list, isloading, onclick }) {
  const { theme } = useContext(AuthCountext);
  
  return (
    <>
      {list.map((item) => (
        <ul
          className={`flex text-decoration color-${theme} scale-hover-1 media-full-width`}
          style={{
            fontSize: "medium", display: 'flex'
          }}
          key={uuidv4()}
        >
          <Link
            to={item.link && item.link}
            className={`flex flex-row justify-space-between align-center text-decoration color-${theme} scale-hover-1 media-full-width full-width`}
            style={{ width: "20%", minWidth: "200px" }}
          >
            <li
              className={`flex flex-row justify-space-between align-center full-width`}
            >
              <span style={{ zIndex: "10" }}>{item.title}</span>
              {isloading ? (
                <Loader size="small" />
              ) : (
                <span style={{ zIndex: "10" }}>{item.result}</span>
              )}
            </li>
          </Link>
        </ul>
      ))}
    </>
  );
}

export default DashboardDiv;
