import React, { useContext, useEffect, useState } from "react";
import { AuthCountext } from "../AuthCountext";
import Search from "antd/es/input/Search";
import { Button, Pagination, Table } from "antd";
import Column from "antd/es/table/Column";
import { createPdf } from "./PdfReport";
import FetchLogo from "./FetchLogo";
import { FileExcelFilled, FilePdfOutlined } from "@ant-design/icons";
import FetchBranch from "./FetchBranch";
import XlsxReport from "../accounting/XlsxReport";

function MyTable({
  setIsloading,
  isRefresh,
  isloading,
  columns,
  page,
  options,
}) {
  const { theme, ACCOUNNTING_URL, userToken, branchId, userName } =
    useContext(AuthCountext);
  const [currentPage, setCurrentPage] = useState(1);
  const [dataInPage, setDataInPage] = useState(10);
  const [query, setQuery] = useState(null);
  const [total, setTotal] = useState(null);
  const [data, setData] = useState([]);
  const [pdfLoadingButton, setPdfLoadingButton] = useState(false);

  const logo = FetchLogo();
  const branchData = FetchBranch();

  const fetchData = async () => {
    setIsloading(true);
    let url = "";
    if (query) {
      url = `${ACCOUNNTING_URL}/${page}/${currentPage}/${dataInPage}/${branchId}/${query}`;
    } else {
      url = `${ACCOUNNTING_URL}/${page}/${currentPage}/${dataInPage}/${branchId}`;
    }
    try {
      const response = await fetch(url, {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "x-api-key": userToken,
        },
      });
      const json = await response.json();
      if (json.msg !== "No data") {
        if (json.data.length > 0) {
          setData(json.data);
          setTotal(json.total);
        }
      } else {
        setData([]);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setIsloading(false);
    }
  };

  const fetchDataPdf = async () => {
    setPdfLoadingButton(true);

    try {
      const response = await fetch(`${ACCOUNNTING_URL}/${page}/${branchId}`, {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "x-api-key": userToken,
        },
      });
      const json = await response.json();
      if (json.msg !== "No data") {
        createPdf(
          json,
          columns.slice(2, columns.length),
          null,
          null,
          branchData,
          userName,
          logo,
          false,
          options[0].value,
          options
        );
        pdfLoder();
      } else {
      }
    } catch (error) {
      console.error(error);
    } finally {
      setPdfLoadingButton(false);
    }
  };

  const fetchDataXlsx = async () => {
    setPdfLoadingButton(true);

    try {
      const response = await fetch(`${ACCOUNNTING_URL}/${page}/${branchId}`, {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "x-api-key": userToken,
        },
      });
      const json = await response.json();
      if (json.msg !== "No data") {
        XlsxReport(json, options, options[0].value)
        pdfLoder();
      } else {
      }
    } catch (error) {
      console.error(error);
    } finally {
      setPdfLoadingButton(false);
    }
  };

  useEffect(() => {
    fetchData();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isRefresh, ACCOUNNTING_URL, currentPage, dataInPage, query]);

  const pdfLoder = () => {
    const timer = setTimeout(() => setPdfLoadingButton(false), 1000);
    return () => {
      clearTimeout(timer);
    };
  };

  return (
    <div
      className={`flex justify-start align-start flex-column p-10 gap-10 over-flow-scroll over-flow-scroll-${theme}`}
      style={{ overflow: "scroll", width: "100%", minHeight: "500px" }}
    >
      <div
        style={{ width: "100%", paddingBottom: "20px" }}
        className="flex flex-row align-end justify-end media-flex-culomn gap-10"
      >
        <div className="flex flex-column align-start justify-start">
          <span>ابحث</span>
          <Search
            placeholder="ابدأ بالكتابة للبحث..."
            enterButton="بحث"
            style={{ width: "auto" }}
            size="large"
            loading={isloading}
            onChange={(e) => {
              setCurrentPage(1);
              setQuery(e.target.value);
            }}
          />
        </div>
        <div className="flex flex-row gap-5" style={{ marginRight: "auto" }}>
          <Button
            type="primary"
            className="excel-button"
            loading={pdfLoadingButton}
            disabled={data.length === 0}
            onClick={() => {
              fetchDataXlsx();
              setPdfLoadingButton(true);
            }}
            size="medium"
            icon={<FileExcelFilled />}
          >
            اكسل
          </Button>
          <Button
            type="primary"
            loading={pdfLoadingButton}
            style={{ marginRight: "auto", background: "#F40F02" }}
            disabled={data.length === 0}
            onClick={() => {
              fetchDataPdf();
              setPdfLoadingButton(true);
            }}
            size="medium"
            icon={<FilePdfOutlined />}
          >
            تنزيل
          </Button>
        </div>
      </div>
      <Table
        size="small"
        pagination={false}
        className="full-width"
        dataSource={data}
        loading={isloading}
      >
        {columns.toReversed().map((item) => (
          <Column
            title={item.title}
            dataIndex={item.dataKey}
            render={item.render}
            sorter={item.sorter}
          />
        ))}
      </Table>
      <Pagination
        style={{ paddingBlock: "10px" }}
        defaultCurrent={currentPage}
        total={total}
        pageSize={dataInPage}
        showQuickJumper
        showSizeChanger
        onChange={(page, pageSize) => {
          setIsloading(true);
          setDataInPage(pageSize);
          setCurrentPage(page);
        }}
      />
    </div>
  );
}

export default MyTable;
