import React, { useContext, useEffect, useState } from "react";

import { Link, useNavigate } from "react-router-dom";
import { AuthCountext } from "./AuthCountext";
import Loader from "./Loader";
import {
  AccountBookOutlined,
  BarcodeOutlined,
  CalculatorOutlined,
  DollarOutlined,
  FallOutlined,
  HomeOutlined,
  RightOutlined,
  RiseOutlined,
  SettingOutlined,
  SwapOutlined,
  TeamOutlined,
  UserSwitchOutlined,
} from "@ant-design/icons";
import WindowSize from "./subcomponent/WindowSize";
import { Menu, Tooltip } from "antd";

function RightBar() {
  const navagate = useNavigate();
  const {
    userAdmin,
    branchId,
    theme,
    SERVER_URL,
    userToken,
    openRightBar,
    setOpenRightBar,
    isMenu,
    setIsMenu,
  } = useContext(AuthCountext);
  const [data, setData] = useState([]);
  const [isloading, setIsloading] = useState(true);
  const [menu, setMenu] = useState(isMenu === "true");
  const windowSize = WindowSize();

  useEffect(() => {
    menu ? setIsMenu("true") : setIsMenu("false");
  });

  const list = [
    {
      label: "نظام الرواتب",
      key: "/",
      icon: <HomeOutlined />,
      auth: "0",
      children: [
        {
          label: "لوحة البيانات",
          key: "/dashboard",
          icon: <HomeOutlined />,
          auth: "0",
        },
        {
          label: "ادارة الموظفين",
          key: "/employee",
          icon: <TeamOutlined />,
          auth: "0",
        },
        {
          label: "اصدار الرواتب",
          key: "/salary",
          icon: <DollarOutlined />,
          auth: "0",
        },
        {
          label: "الخصومات",
          key: "/salarycut",
          icon: <FallOutlined />,
          auth: "0",
        },
        {
          label: "الاجازات",
          key: "/vacation",
          icon: <UserSwitchOutlined />,
          auth: "0",
        },
        {
          label: "السلف",
          key: "/advancepayment",
          icon: <CalculatorOutlined />,
          auth: "0",
        },
        {
          label: "الزيادات",
          key: "/addition",
          icon: <RiseOutlined />,
          auth: "0",
        },
      ],
    },

    {
      label: "النظام المحاسبي",
      key: "/accounting",
      icon: <AccountBookOutlined />,
      auth: "0",
      children: [
        {
          name: "ledgerEntryeis",
          label: "القيود اليومية",
          key: "/accounting/ledgerEntryeis",
        },
        {
          name: "account",
          label: "إعداد الحسابات",
          key: "/accounting/account",
        },
        {
          name: "account",
          label: "الشجرة المحاسبية",
          key: "/accounting/tree",
        },
        {
          name: "genral-ledger",
          label: "الاستاذ المساعد",
          key: "/accounting/report",
        },
        {
          name: "paymethod",
          label: "التقارير المالية",
          key: "/accounting/balance-sheet",
        },
      ],
    },
    {
      label: "نظام المخازن",
      key: "/inventory",
      icon: <BarcodeOutlined />,
      auth: "0",
      children: [
        {
          name: "warehouses",
          label: "اعداد المخازن",
          key: "/Inventory/warehouses",
        },
        {
          name: "categories",
          label: "الفئات",
          key: "/Inventory/categories",
        },
        {
          name: "units",
          label: "الوحدات",
          key: "/Inventory/units",
        },
        {
          name: "items",
          label: "الأصناف",
          key: "/Inventory/items",
        },
        {
          name: "itemsunits",
          label: "وحدات الأصناف",
          key: "/Inventory/itemsunits",
        },
        {
          name: "suppliers",
          label: "الموردين",
          key: "/Inventory/suppliers",
        },
        {
          name: "buyers",
          label: "العملاء",
          key: "/Inventory/buyers",
        },
        {
          name: "PurchaseOrdersList",
          label: "الوارد",
          key: "/Inventory/PurchaseOrdersList",
        },
        {
          name: "invoices",
          label: "الصادر",
          key: "/inventory/InvoicesList",
        },
        {
          name: "StockTaking",
          label: "جرد",
          key: "/Inventory/StockTaking",
        },
        {
          name: "inventoryReports",
          label: "التقارير",
          key: "/Inventory/inventoryReports",
        },
      ],
    },
    {
      label: "الاعدادات",
      key: "/settings",
      icon: <SettingOutlined />,
      auth: "1",
      children: [
        {
          name: "branch",
          label: "إعداد الفروع",
          key: "/settings/branch",
        },
        {
          name: "maindocs",
          label: "إعداد المستندات",
          key: "/settings/maindocs",
        },
        {
          name: "paymethod",
          label: "إعداد طرق الصرف",
          key: "/settings/paymethod",
        },
        {
          name: "positiontitle",
          label: "إعداد الوظائف",
          key: "/settings/jobs",
        },
        {
          name: "users",
          label: "إدارة المستخدمين",
          key: "/settings/users",
        },
      ],
    },
  ];

  const fetchBranch = async () => {
    setIsloading(true);
    try {
      const response = await fetch(`${SERVER_URL}/branch/${branchId}`, {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "x-api-key": userToken,
        },
      });
      const json = await response.json();
      if (json.length > 0) {
        setData(json);
        setIsloading(false);
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    fetchBranch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [branchId]);

  const items =
    userAdmin !== ("1" || 1)
      ? list.filter((item) => Number(item.auth) === Number(userAdmin))
      : list;

  const onClick = ({ key }) => {
    navagate(key);
  };

  return (
    <div
      style={
        windowSize[0] < 900
          ? openRightBar
            ? { display: "flex", zIndex: 105 }
            : { display: "none" }
          : menu
          ? windowSize[0] <= 1440
            ? { width: "25%" }
            : { width: "15%" }
          : { width: "70px" }
      }
      className={`menu shadow-${theme} border-left-${theme}-1 text-align-center background-div-${theme} color-${theme} media-position-fixed full-height transition over-flow-scroll`}
    >
      <div className="menu-container flex flex-column p-t-10 right-bar position-relative">
        {windowSize[0] > 900 && (
          <Tooltip title="تصغير القائمة">
            <div
              style={{
                position: "absolute",
                left: "0px",
                top: "20px",
                fontSize: "large",
                cursor: "pointer",
                zIndex: 250,
                width: "30px",
                height: "30px",
                borderTopRightRadius: "5px",
                borderBottomRightRadius: "5px",
              }}
              className={`flex justify-center align-center shadow-${theme} background-${theme} color-${theme}`}
              onClick={() => setMenu(!menu)}
            >
              <RightOutlined
                style={{
                  transition: "all ease-in-out 0.3s",
                  rotate: !menu ? "180deg" : "0deg",
                }}
              />
            </div>
          </Tooltip>
        )}

        <div
          className={`flex flex-column justify-center align-center align-text-center p-10 position-relative border-bottom-${theme}-1 media-p`}
          style={{ height: "220px" }}
        >
          {isloading ? (
            openRightBar || !menu ? (
              <Loader size={"small"} />
            ) : (
              <Loader size={"large"} />
            )
          ) : (
            data.map((item, index) => (
              <div
                className={`flex flex-column justify-center align-center align-text-center media-width`}
                style={openRightBar || !menu ? { marginTop: "40px" } : {}}
                key={index}
              >
                {menu &&
                  (openRightBar ? (
                    <img
                      src={`${SERVER_URL}/${item.logo}`}
                      style={{ maxWidth: "150px", objectFit: "contain" }}
                      height={"100px"}
                      alt={item.title}
                    />
                  ) : (
                    <img
                      src={`${SERVER_URL}/${item.logo}`}
                      style={{ maxWidth: "150px", objectFit: "contain" }}
                      height={"100px"}
                      alt={item.title}
                    />
                  ))}
                <h2
                  style={{
                    transition: "all ease-in-out 0.3s",
                    fontSize: menu ? "large" : "small",
                  }}
                  className={`color-${theme} h1-media-font-size media-width`}
                >
                  {item.title}
                </h2>
                <div
                  className="flex flex-row justify-center align-center align-text-center"
                  style={{ position: "absolute", left: "5px", bottom: "5px" }}
                >
                  <Tooltip title="تغيير الفرع">
                    <Link
                      to={"/branches"}
                      className={`${
                        menu && `change-baranch`
                      } text-center flex justify-center align-center text-decoration color-${theme} gap-5`}
                      style={{
                        transition: "all ease-in-out 0.3s",
                        padding: "2px",
                        borderRadius: "8px",
                      }}
                      onClick={() => setOpenRightBar(false)}
                    >
                      <span
                        style={{
                          fontSize: "10px",
                          transition: "all ease-in-out 0.3s",
                          translate: !menu ? "150px" : "0px",
                        }}
                      >
                        تغيير الفرع
                      </span>

                      <SwapOutlined size="large" />
                    </Link>
                  </Tooltip>
                </div>
              </div>
            ))
          )}
        </div>
        <div
          className={`flex flex-column justify-start align-start position-relative text-align-start border-bottom-${theme}-1`}
        >
          <Menu
            onClick={onClick}
            defaultSelectedKeys={[window.location.pathname]}
            defaultOpenKeys={[window.location.pathname]}
            mode="inline"
            items={items}
            inlineCollapsed={isMenu === "true" ? false : true}
            theme={theme}
            disabledOverflow
          />
        </div>
      </div>
    </div>
  );
}

export default RightBar;
