import { Select } from "antd";
import React, {
  memo,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import { AuthCountext } from "../AuthCountext";

function GetItemsUnits({ ItemID, item, setFormData, index, price }) {
  const { ACCOUNNTING_URL, userToken } = useContext(AuthCountext);
  const [itemUnitsList, setItemUnitsList] = useState([]);
  const [isloading, setIsloading] = useState(false);

  const fetchItemsUnits = async () => {
    setIsloading(true);
    try {
      const response = await fetch(
        `${ACCOUNNTING_URL}/getitemUnits/${ItemID}`,
        {
          method: "GET",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "x-api-key": userToken,
          },
        }
      );
      const json = await response.json();
      if (json.length > 0) {
        setItemUnitsList(json);
        setFormData((draft) => {
          draft.invoicedetails[index] = {
            ...draft.invoicedetails[index],
            UnitID : item.UnitID || json[0].UnitID,
          }
        });
      } else {
        setItemUnitsList([]);
        setFormData((draft) => {
          draft.invoicedetails[index].UnitID = json[0].UnitID;
        });
      }
    } catch (error) {
      console.error("Error fetching item units:", error);
    } finally {
      setIsloading(false);
    }
  };

  useEffect(() => {
    fetchItemsUnits();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ItemID, item.UnitID, item.ItemID]);

  const handleChange = useCallback(
    (e) => {
      const selectedUnit = itemUnitsList.find((item) => item.UnitID === e);
      setFormData((draft) => {
        draft.invoicedetails[index].UnitID = e;
        draft.invoicedetails[index][price] = null;
        draft.invoicedetails[index].Vat = null;
        draft.invoicedetails[index].TotalPrice = null;
        draft.invoicedetails[index].ConversionRate =
          selectedUnit?.ConversionRate || 1;
      });
    },
    [itemUnitsList, setFormData, index, price]
  );

  return (
    <Select
      value={item.UnitID}
      name="UnitID"
      showSearch
      style={{ minWidth: "100px" }}
      className="height-40 border-radius full-width"
      onChange={handleChange}
      loading={isloading}
      optionFilterProp="label"
      placeholder={isloading ? "جارٍ التحميل..." : "اختر الوحدة"}
      defaultValue={itemUnitsList[0]?.UnitID}
      options={itemUnitsList.map((unit) => ({
        value: unit.UnitID,
        label: unit.UnitName,
      }))}
    />
  );
}

export default memo(GetItemsUnits);
