/* eslint-disable no-unused-vars */
import React, { useState, useEffect, useContext } from "react";
import { Pagination, Button } from "antd";

import { AuthCountext } from "../AuthCountext";

import CutPlaceHolder from "../subcomponent/CutPlaceHolder";

import { Link } from "react-router-dom";
import {
  DeleteFilled,
  DeleteOutlined,
  EditFilled,
  EditOutlined,
  FundViewOutlined,
  LinkOutlined,
} from "@ant-design/icons";
import AlertComp from "../subcomponent/AlertComp";
import TableComponent from "../TableComponent";

const LedgerEntryeisComp = ({ openModal, setOpenModal }) => {
  const { theme, ACCOUNNTING_URL, userToken, branchId } =
    useContext(AuthCountext);

  const [isloading, setIsloading] = useState(true);
  const [data, setData] = useState([]);

  // const [deleteButtonLoading, setDeleteButtonLoading] = useState(false);
  const [deleltedId, setDeleletedId] = useState(null);
  const [isRefresh, setIsRefresh] = useState(false);

  const [alertMsg, setAlertMsg] = useState(null);
  const [alertName, setAlertName] = useState(null);
  const [isAlert, setIsAlert] = useState(false);
  const [total, setTotal] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [dataInPage, setDataInPage] = useState(10);
  const [f_period, setF_period] = useState(1);

  // fetch

  // const fetchData = async () => {
  //   setIsloading(true);
  //   try {
  //     const response = await fetch(
  //       `${ACCOUNNTING_URL}/ledger_entries/${currentPage}/${dataInPage}/${branchId}/${f_period}`,
  //       {
  //         method: "GET",
  //         headers: {
  //           Accept: "application/json",
  //           "Content-Type": "application/json",
  //           "x-api-key": userToken,
  //         },
  //       }
  //     );
  //     const json = await response.json();
  //     if (json.msg !== "No data") {
  //       if (json.data.length > 0) {
  //         setData(json.data);
  //         setTotal(json.total);
  //       }
  //     } else {
  //       setData([]);
  //     }
  //     loader();
  //   } catch (error) {
  //     console.error(error);
  //   }
  // };

  // useEffect(() => {
  //   fetchData();

  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [isRefresh, ACCOUNNTING_URL, currentPage, dataInPage]);

  // end fetch

  // delete
  const deleteData = async (id) => {
    setDeleletedId(id);
    try {
      const response = await fetch(
        `${ACCOUNNTING_URL}/transactions/delete/${id}`,
        {
          method: "DELETE",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "x-api-key": userToken,
          },
        }
      );
      const json = await response.json();
      setIsRefresh(!isRefresh);
      setDeleletedId(null);
      setIsAlert(true);
      setAlertMsg("تم حذف السجل بنجاح");
      setAlertName("alert-success");
      alertShow();
      if (json.errore.errorInfo[0] === "23000") {
        setIsAlert(true);
        setAlertMsg("لا يمكنك حذف السجل لارتباطه بسجلات اخرى.");
        setAlertName("alert-danger");
      }
      alertShow();
    } catch (error) {
      console.error(error);
    }
  };

  const alertShow = () => {
    const timer = setTimeout(() => setIsAlert(false), 3000);
    return () => {
      clearTimeout(timer);
    };
  };

  // end delete

  // others

  const loader = () => {
    const timer = setTimeout(() => setIsloading(false), 1000);
    return () => {
      clearTimeout(timer);
    };
  };

  const Place = () => {
    const places = [];
    for (let i = 0; i < dataInPage; i++) {
      places.push(<CutPlaceHolder />);
    }
    return places;
  };

  const columns = [
    {
      title: "حذف",
      render: (_, record) => (
        <Button
          danger
          color="danger"
          type="text"
          onClick={() => deleteData(record.transaction_id, true)}
          id={record.transaction_id}
          loading={isloading}
          icon=<DeleteFilled />
        >
          حذف
        </Button>
      ),
    },
    {
      title: "تعديل",
      render: (_, record) => (
        <>
          <Link to={`/accounting/show-transactions/${record.transaction_id}`}>
            <Button
              color="primary"
              className={`color-primary-${theme}`}
              type="text"
              id={record.transaction_id}
              loading={isloading}
              icon=<FundViewOutlined />
            >
              عرض
            </Button>
          </Link>
          <Link to={`/accounting/edit-transactions/${record.transaction_id}`}>
            <Button
              color="primary"
              className={`color-primary-${theme}`}
              type="text"
              id={record.transaction_id}
              loading={isloading}
              icon=<EditFilled />
            >
              تعديل
            </Button>
          </Link>
        </>
      ),
    },
    {
      title: "متزن",
      dataKey: "Balance",
      render: (_, record) => (
        <div
          style={{
            width: "50px",
            padding: "5px",
            textAlign: "center",
            fontSize: "10px",
            color: "white",
            borderRadius: 15,
            background: record.Balance === "Balanced" ? "#069460" : "tomato",
          }}
        >
          {record.Balance === "Balanced" ? "متزن" : "غير متزن"}
        </div>
      ),
      width: "100",
    },
    { title: "دائن", dataKey: "Debit" },
    { title: "مدين", dataKey: "Credit" },
    { title: "الوصف", dataKey: "description" },
    { title: "التاريخ", dataKey: "date" },
    {
      title: "رقم العملية",
      dataKey: "transaction_id",
      sorter: (a, b) => a.transaction_id - b.transaction_id,
    },
  ];

  const options = [
    {
      value: "transactions",
      label: "القيود اليومية",
      pageDirction: "p",
    },
  ];

  return (
    <TableComponent
      isRefresh={isRefresh}
      isloading={isloading}
      columns={columns}
      options={options}
      setIsloading={setIsloading}
      page="transactions"
      isAlert={isAlert}
      alertMsg={alertMsg}
      alertName={alertName}
    />
  );
};

export default LedgerEntryeisComp;
