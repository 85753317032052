import React, { useContext } from "react";
import Icon from "./Icon";
import { AuthCountext } from "./AuthCountext";
import WindowSize from "./subcomponent/WindowSize";
import ThemeSwitch from "../auth/ThemeSwitch";
import SearchBar from "./SearchBar";
import { LogoutOutlined, SearchOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";

function Nav() {
  const { userName, userAvatar, logout, SERVER_URL, theme } = useContext(
    AuthCountext
  );

  const windowSize = WindowSize();

  return (
    <>
      <nav
        style={
          windowSize[0] < 1100
            ? {
                height: "80px",
              }
            : {
                height: "80px",
                width: "100%",
                paddingInline: "50px",
              }
        }
        className={`flex flex-row justify-space-between p-l-10 align-center position-absolute nav color-${theme} background-${theme}`}
      >
        {windowSize[0] < 1100 ? (
          <Link
            to="search"
            className={`color-${theme} text-center flex gap-10 justify-space-between align-center text-decoration p-5 border-radius`}
          >
            <SearchOutlined style={{ fontSize: "large" }} />
          </Link>
        ) : (
          <SearchBar />
        )}
        <div
          style={{ display: "flex", flexDirection: "row" }}
          className="full-width justify-end align-center"
        >
          <div
            style={{ display: "flex", flexDirection: "row" }}
            className="justify-space-between align-center gap-10"
          >
            <ThemeSwitch />
            <div className="over-flow round width-30 height-30 flex justiry-center align-center">
              <Icon icon={`${SERVER_URL}/${userAvatar}`} w={30} h={30} />
            </div>
            {userName.split(" ")[0]}

            <LogoutOutlined
              style={{ fontSize: "large", rotate: "180deg" }}
              onClick={() => logout()}
            />
          </div>
        </div>
      </nav>
    </>
  );
}

export default Nav;
