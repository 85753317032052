import React, { useState, useContext, useEffect } from "react";
import { useImmer } from "use-immer";
import { Input, Select } from "antd";
import { MinusOutlined, PlusOutlined } from "@ant-design/icons";
import { AuthCountext } from "../AuthCountext";
import FetchAccount from "./FetchAccount";
import { useParams } from "react-router-dom";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import TransComponent from "./TransComponent";

const todyDate = new Date().toLocaleDateString();

dayjs.extend(customParseFormat);

const EditTransactionsComp = () => {
  const { theme, ACCOUNNTING_URL, userToken, branchId } =
    useContext(AuthCountext);
  const [isloading, setIsloading] = useState(true);
  const { id } = useParams();
  const [alertMsg, setAlertMsg] = useState(null);
  const [alertName, setAlertName] = useState(null);
  const [isAlert, setIsAlert] = useState(false);
  const accountsList = FetchAccount().data;
  const [data, setData] = useState([]);
  const [isRefresh, setIsRefresh] = useState(false);
  const financialPreiod = FetchAccount().financialPreiod;
  const [disableSaveButton, setDisableSaveButton] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [deleted, setDeleted] = useState([]);

  const [formData, setFormData] = useImmer({
    updated_by: userToken,
    updated_at: todyDate,
    description: data[0]?.description,
    financial_period: data[0]?.financial_period,
    date: data[0]?.date,
    ledger: [
      {
        id: null,
        account_id: null,
        entry_type: null,
        credit: 0,
        debit: 0,
      },
    ],
  });

  const presetFormData = {
    created_by: userToken,
    description: null,
    company_id: branchId,
    financial_period: data[0]?.financial_period,
    date: null,
    ledger: [
      {
        account_id: null,
        entry_type: null,
        credit: 0,
        debit: 0,
      },
    ],
  };

  const fetchData = async () => {
    setIsloading(true);
    try {
      const response = await fetch(
        `${ACCOUNNTING_URL}/ledger_entries/${id}/${branchId}`,
        {
          method: "GET",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "x-api-key": userToken,
          },
        }
      );
      const json = await response.json();
      if (json.msg !== "No data") {
        setData(json);
        setFormData((draft) => {
          draft.updated_by = userToken;
          draft.updated_at = todyDate;
          draft.description = json[0]?.description;
          draft.financial_period = json[0]?.financial_period;
          draft.date = json[0]?.date;
          draft.ledger = json;
        });
        loader();
      } else {
        setData([]);
      }
      loader();
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    fetchData();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleAddfield = (index) => {
    setFormData((draft) => {
      draft.ledger = [
        ...draft.ledger,
        {
          account_id: null,
          entry_type:
            index === 0 && formData.ledger[index].entry_type === "D"
              ? "C"
              : "D",
          credit: index === 0 ? formData.ledger[index].debit : 0,
          debit: index === 0 ? formData.ledger[index].credit : 0,
        },
      ];
    });
    validLedger();
  };

  const handleRemovefield = (e, index, id) => {
    id && deleted.push(id);
    setFormData((draft) => {
      draft.ledger.splice(index, 1);
    });
  };

  const deleteData = async (id) => {
    try {
      const response = await fetch(
        `${ACCOUNNTING_URL}/ledger_entries/delete/${id}`,
        {
          method: "DELETE",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "x-api-key": userToken,
          },
        }
      );
      const json = await response.json();
      // setIsRefresh(!isRefresh);
      setIsAlert(true);
      setAlertMsg("تم حذف السجل بنجاح");
      setAlertName("alert-success");
      alertShow();
      if (json.errore.errorInfo[0] === "23000") {
        setIsAlert(true);
        setAlertMsg("لا يمكنك حذف السجل لارتباطه بسجلات اخرى.");
        setAlertName("alert-danger");
      }
      alertShow();
    } catch (error) {
      console.error(error);
    }
  };

  const getSum = (x) => {
    let sum = 0;
    for (let i = 0; i < formData.ledger.length; i++) {
      const element = formData.ledger[i][x];
      sum += parseFloat(element);
    }
    return sum;
  };

  // fetch

  // end fetch

  // insert data

  const addData = async () => {
    // setDisable(true);
    try {
      const response = await fetch(`${ACCOUNNTING_URL}/transactions`, {
        method: "PUT",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "x-api-key": userToken,
        },
        body: JSON.stringify(formData),
      });
      const json = await response.json();
      console.log(json);
      setIsAlert(true);
      setAlertMsg("تم تعديل السجل بنجاح");
      setAlertName("alert-success");
      alertShow();
      setIsRefresh(!isRefresh);
    } catch (error) {
      console.error(error);
    }
  };

  const validLedger = () => {
    let x = 0;
    for (let index = 0; index < formData.ledger.length; index++) {
      const item = formData.ledger[index];

      x += item.account_id !== null ? 1 : 0;
    }
    if (x === formData.ledger.length) {
      return true;
    } else {
      return false;
    }
  };

  const validationFun = () => {
    if (
      formData.company_id !== null &&
      formData.date !== null &&
      getSum("credit") + getSum("debit") !== 0 &&
      validLedger()
    ) {
      for (var i in deleted) {
        deleteData(deleted[i]);
      }
      addData();
    } else {
      console.log("no valid");
      setIsAlert(true);
      setAlertMsg("ادخل جميع الحقول");
      setAlertName("alert-warning");
      alertShow();
    }
  };

  function handleSubmit(event) {
    event.preventDefault();
    validationFun();
  }


  // end insert data

  // others

  const alertShow = () => {
    const timer = setTimeout(() => setIsAlert(false), 3000);
    return () => {
      clearTimeout(timer);
    };
  };

  const loader = () => {
    const timer = setTimeout(() => setIsloading(false), 2000);
    return () => {
      clearTimeout(timer);
    };
  };

  const validMsg = (x, name) => {
    return (
      !x[name] &&
      isAlert && (
        <span style={{ color: "tomato", fontSize: "10px" }}>
          هذا الحقل مطلوب
        </span>
      )
    );
  };

  const columns = [
    {
      title: "الحساب",
      dataIndex: "account_id",
      render: (_, item, index) => (
        <div className="flex flex-column">
          <Select
            autoFocus
            value={item.account_id}
            name="account_id"
            showSearch
            className={`height-50 border-radius full-width`}
            onChange={(e) =>
              setFormData((draft) => {
                draft.ledger[index].account_id = e;
              })
            }
            optionFilterProp="label"
            placeholder={isloading ? "جارٍ التحميل" : "الحساب"}
            options={accountsList.map((item) => ({
              disabled:
                item.is_parent === "1" || item.is_parent === 1 ? true : false,
              value: item.id,
              label: `${item.account_id} ${item.account_name}`,
            }))}
          />
          {validMsg(item, "account_id")}
        </div>
      ),
      width: 300,
    },
    {
      title: "دائن",
      dataIndex: "credit",
      render: (_, item, index) => (
        <div className="flex flex-column">
          <Input
            value={item.credit}
            type="text"
            name="credit"
            className={`width-200 height-50 border-radius background-div-${theme} color-${theme}`}
            placeholder="دائن"
            onChange={(e) => {
              setDisableSaveButton(false);
              setFormData((draft) => {
                draft.ledger[index].credit = e.target.value;
                draft.ledger[index].debit = 0;
                draft.ledger[index].entry_type = "C";
              });
            }}
          />
          {item.credit === 0 && item.debit === 0 && validMsg(item, "credit")}
        </div>
      ),
      width: 100,
    },
    {
      title: "مدين",
      dataIndex: "debit",
      render: (_, item, index) => (
        <div className="flex flex-column">
          <Input
            value={item.debit}
            type="text"
            name="debit"
            className={`width-200 height-50 border-radius background-div-${theme} color-${theme}`}
            placeholder="مدين"
            onChange={(e) => {
              setDisableSaveButton(false);
              setFormData((draft) => {
                draft.ledger[index].debit = e.target.value;
                draft.ledger[index].credit = 0;
                draft.ledger[index].entry_type = "D";
              });
            }}
          />
          {item.credit === 0 && item.debit === 0 && validMsg(item, "debit")}
        </div>
      ),
      width: 100,
    },
    {
      title: "-",
      dataIndex: "-",
      render: (_, item, index) => (
        <div className="flex justify-space-start gap-10">
          <div
            className="flex justify-center align-center cursor-pointer plus"
            onClick={() => handleAddfield(index)}
          >
            <PlusOutlined />
          </div>

          <div
            className="flex justify-center align-center cursor-pointer minus"
            onClick={(e) => handleRemovefield(e, index, item.id)}
            style={{
              display: formData.ledger.length === 1 && "none",
            }}
          >
            <MinusOutlined className="cursor-pointer font-hover" />
          </div>
        </div>
      ),
      width: 150,
    },
  ];

  return (
    <TransComponent
      title={"تعديل قيد"}
      columns={columns}
      formData={formData}
      setFormData={setFormData}
      handleAddfield={handleAddfield}
      handleSubmit={handleSubmit}
      isAlert={isAlert}
      alertMsg={alertMsg}
      alertName={alertName}
      isloading={isloading}
      list={accountsList}
      financialPreiod={financialPreiod}
      disableSaveButton={disableSaveButton}
      setDisableSaveButton={setDisableSaveButton}
      presetFormData={presetFormData}
      orderType={"transaction"}
      validMsg={validMsg}
    />
  );
};

export default EditTransactionsComp;
