import React, { useState, useEffect, useContext } from "react";
import { Link, useParams } from "react-router-dom";
import { AuthCountext } from "./AuthCountext";
import * as dayjs from "dayjs";
import * as isLeapYear from "dayjs/plugin/isLeapYear"; // import plugin
import "dayjs/locale/ar"; // import locale
import ParentComp from "./ParentComp";
// import EmployeePlaceHolder from "./subcomponent/EmployeePlaceHolder";
import { EditOutlined } from "@ant-design/icons";
import useMeasure from "react-use-measure";
import EmployeeProfile from "./EmployeeProfile";
import EmployeeInformations from "./EmployeeInformations";
import EmpolyeeDivInfo from "./EmpolyeeDivInfo";
import { Button } from "antd";

const extend = dayjs.extend;
extend(isLeapYear); // use plugin
dayjs.locale("ar"); // use locale
const relativeTime = require("dayjs/plugin/relativeTime");
dayjs.extend(relativeTime);

function FecthEmployee() {
  const { SERVER_URL, branchId, theme, setTitle, userToken } = useContext(
    AuthCountext
  );
  const params = useParams();
  const [isloading, setIsloading] = useState(false);
  const [data, setData] = useState([]);
  const [ref, { width }] = useMeasure();

  useEffect(() => {
    const fetchData = async () => {
      setIsloading(true);
      try {
        const response = await fetch(
          `${SERVER_URL}/fetchemployee/${params.id}/${branchId}`,
          {
            method: "GET",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
              "x-api-key": userToken,
            },
          }
        );
        const json = await response.json();
        if (json.length > 0) {
          setData(json[0]);
        } else {
          setData([]);
        }
        loader();
      } catch (error) {
        console.error(error);
      }
    };

    fetchData();
  }, [SERVER_URL, branchId, params.id, userToken]);

  const employeeInfo = [
    {
      id: data.id,
      info: "increase",
      name: "العلاوة",
      kind: "ريال",
    },
    {
      id: data.id,
      info: "overtime",
      name: "الاضافي",
      kind: "ساعة",
    },
    {
      id: data.id,
      info: "incentives",
      name: "الحافز",
      kind: "ريال",
    },
    {
      id: data.id,
      info: "rewards",
      name: "المكافآت",
      kind: "ريال",
    },
    {
      id: data.id,
      info: "advance",
      name: "السلف",
      kind: "ريال",
    },
    {
      id: data.id,
      info: "cut",
      name: "الخصم",
      kind: "ريال",
    },
    {
      id: data.id,
      info: "absence",
      name: "الغياب",
      kind: "ريال",
    },
    {
      id: data.id,
      info: "vacation",
      name: "الاجازات",
      kind: "يوم",
    },
  ];

  const loader = () => {
    const timer = setTimeout(() => setIsloading(false), 1000);
    return () => {
      clearTimeout(timer);
    };
  };

  // const Place = () => {
  //   const places = [];
  //   for (let i = 0; i < 1; i++) {
  //     places.push(<EmployeePlaceHolder />);
  //   }
  //   return places;
  // };

  return (
    <>
      <ParentComp
        title={data.name}
        prevPage={"ادارة الموظفين"}
        linkto={"/employee/"}
      >
        <div
          ref={ref}
          style={{
            flexDirection: width < 500 ? "column" : "row",
            padding: "20px",
            paddingBlock: "60px",
          }}
          className={`${
            isloading
              ? "flex flex-row justify-center align-center "
              : " justify-space-between "
          } flex flex-row position-relative justify-center media-flex-culomn align-flex-start shadow-${theme} full-width-media border-radius text-align-center background-div-${theme} full-width min-height-300 p-20`}
        >
          <Link
            to={`/employee/updateemployee/${data.id}`}
            style={{
              position: "absolute",
              left: "10px",
              top: "10px",
              zIndex: 2,
            }}
          >
            <Button type="primary" shape="circle" icon={<EditOutlined />} />
          </Link>

          {data ? (
            <div className="flex flex-row justify-space-between full-width gap-20 media-flex-culomn">
              {setTitle(data.name)}
              <div
                className="flex flex-column gap-20"
                style={{ minHeight: "580px" }}
                key={data.id}
              >
                <EmployeeProfile width={width} data={data} isloading={isloading} />
                <EmployeeInformations
                  employeeInfo={employeeInfo}
                  width={width}
                />
              </div>
              <div
                className={`flex flex-row flex-wrap justify-start text-align-start full-width gap-20 `}
              >
                <EmpolyeeDivInfo
                  width={width}
                  data={data}
                  title={"بيانات شخصية"}
                  labels={[
                    {
                      label: "الراتب",
                      value: "amount",
                      type: "salary",
                    },
                    {
                      label: "الايميل",
                      value: "email",
                    },
                    {
                      label: "الجنس",
                      value: "sex",
                    },
                    {
                      label: "الجنسية",
                      value: "countryarnationality",
                    },
                    {
                      label: "تاريخ الميلاد",
                      value: "birthdate",
                      type: "date",
                    },
                  ]}
                />

                <EmpolyeeDivInfo
                  width={width}
                  data={data}
                  title={"الوثائق"}
                  labels={[
                    {
                      label: "رقم الهوية",
                      value: "idNumber",
                    },
                    {
                      label: "تاريخ انتهاء الهوية",
                      value: "idExpireDate",
                      type: "date",
                    },
                    {
                      label: "رقم الجواز",
                      value: "passportNumber",
                    },
                    {
                      label: "تاريخ انتهاء الجواز",
                      value: "passExpireDate",
                      type: "date",
                    },
                  ]}
                />

                <EmpolyeeDivInfo
                  width={width}
                  data={data}
                  title={"بيانات الوظيفة"}
                  labels={[
                    {
                      label: "تاريخ بداية العمل",
                      value: "startInPosition",
                      type: "date",
                    },
                    {
                      label: "تاريخ نهاية العمل",
                      value: "endInPosition",
                      type: "date",
                    },
                  ]}
                />
                <EmpolyeeDivInfo
                  width={width}
                  data={data}
                  title={"بيانات العقد"}
                  labels={[
                    {
                      label: "حالة العقد",
                      value: "inwork",
                    },
                    {
                      label: "تاريخ بداية العقد",
                      value: "start",
                      type: "date",
                    },
                    {
                      label: "تاريخ نهاية العقد",
                      value: "end",
                      type: "date",
                    },
                  ]}
                />
              </div>
            </div>
          ) : (
            <h1>لا توجد بيانات</h1>
          )}
        </div>
      </ParentComp>
    </>
  );
}

export default FecthEmployee;
