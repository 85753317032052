import { useContext, useEffect, useState } from "react";
import { AuthCountext } from "../AuthCountext";

function FetchLogo() {
  const { userToken, branchId, SERVER_URL } = useContext(AuthCountext);
  const [logo, setLogo] = useState();
  const getImage = async () => {
    try {
      const response = await fetch(`${SERVER_URL}/imgbase64/${branchId}/img`, {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "x-api-key": userToken,
        },
      });
      const json = await response.json();
      setLogo(json);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    getImage();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return logo;
}

export default FetchLogo;
