import { Table, Typography } from "antd";
import React from "react";

function TableSummary({ columns, data }) {
  const { Text } = Typography;
  const totalFunction = (data, y) => {
    let total = 0;
    data.forEach((x) => {
      total += parseFloat(x[y]);
    });
    return new Intl.NumberFormat().format(total);
  };

  return (
    <Table.Summary.Row>
      {columns.map((item) => (
        <Table.Summary.Cell>
          {item?.total ? (
            <Text style={{ fontWeight: "bold" }}>
              {totalFunction(data, item.dataKey)}
            </Text>
          ) : (
            <></>
          )}
        </Table.Summary.Cell>
      ))}
    </Table.Summary.Row>
  );
}

export default TableSummary;
