import React, { useState, useEffect, useContext } from "react";
import { Pagination, Modal, Input, Select } from "antd";

import AlertComp from "../subcomponent/AlertComp";
import { AuthCountext } from "../AuthCountext";
import CutPlaceHolder from "../subcomponent/CutPlaceHolder";
import Button from "../subcomponent/Button";
import FetchAccount from "../accounting/FetchAccount";

const SuppliersComp = ({ openModal, setOpenModal }) => {
  const { theme, ACCOUNNTING_URL, setaccountData, userToken, branchId } =
    useContext(AuthCountext);
  const [isloading, setIsloading] = useState(true);
  const [data, setData] = useState([]);
  const [disable, setDisable] = useState(false);
  const [deleletedId, setDeleletedId] = useState(null);
  const [isRefresh, setIsRefresh] = useState(false);
  const [editedId, setEditedId] = useState(null);
  const [alertMsg, setAlertMsg] = useState(null);
  const [alertName, setAlertName] = useState(null);
  const [isAlert, setIsAlert] = useState(false);
  const [total, setTotal] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [dataInPage, setDataInPage] = useState(10);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [confirmEditLoading, setConfirmEditLoading] = useState(false);
  const [openEditModal, setOpenEditModal] = useState(false);
  const accountsList = FetchAccount().data;
  const [formData, setFormData] = useState({
    SupplierID: null,
    Name: null,
    ContactName: null,
    Email: true,
    Phone: null,
    Address: null,
    Account_id: null,
    VatNumber: null,
    company_id: branchId,
    created_by: userToken,
  });

  const placeHolders = {
    Name: "اسم المورد",
    ContactName: "اسم مسؤول التواصل",
    Email: "الايميل",
    Phone: "الجوال",
    Address: "العنوان",
    VatNumber: "الرقم الضريبي",
  };

  // fetch

  const fetchData = async () => {
    setIsloading(true);
    try {
      const response = await fetch(
        `${ACCOUNNTING_URL}/suppliers/${currentPage}/${dataInPage}/${branchId}`,
        {
          method: "GET",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "x-api-key": userToken,
          },
        }
      );
      const json = await response.json();
      if (json.msg !== "No data") {
        if (json.data.length > 0) {
          setData(json.data);
          setTotal(json.total);
        }
      } else {
        setData([]);
      }
      loader();
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    fetchData();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isRefresh, ACCOUNNTING_URL, currentPage, dataInPage]);

  // end fetch

  // insert data

  const addData = async () => {
    setDisable(true);
    try {
      const response = await fetch(`${ACCOUNNTING_URL}/suppliers`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "x-api-key": userToken,
        },
        body: JSON.stringify(formData),
      });
      const json = await response.json();
      console.log(json);
      setIsAlert(true);
      setAlertMsg("تمت اضافة السجل بنجاح");
      setAlertName("alert-success");
      alertShow();
      fetchData();
      setDisable(false);
      setaccountData(true);
    } catch (error) {
      console.error(error);
    }
  };

  const validationFun = () => {
    if (
      formData.ContactName !== null ||
      formData.Name !== "" ||
      formData.company_id !== "" ||
      formData.Name !== ""
    ) {
      addData();
      setDisable(true);
      setConfirmLoading(true);
      setTimeout(() => {
        setOpenModal(false);
        setConfirmLoading(false);
      }, 2000);
    } else {
      console.log("no valid");
      setIsAlert(true);
      setAlertMsg("ادخل جميع الحقول");
      setAlertName("alert-warning");
      alertShow();
    }
  };

  // function handleSubmit(event) {
  //   // event.preventDefault();
  //   validationFun();
  // }

  // end insert data

  // delete
  const deleteData = async (id) => {
    setDeleletedId(id);
    try {
      const response = await fetch(
        `${ACCOUNNTING_URL}/suppliers/delete/${id}`,
        {
          method: "DELETE",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "x-api-key": userToken,
          },
        }
      );
      const json = await response.json();
      setIsRefresh(!isRefresh);
      setDeleletedId(null);
      setIsAlert(true);
      setAlertMsg("تم حذف السجل بنجاح");
      setAlertName("alert-success");
      alertShow();
      if (json.errore.errorInfo[0] === "23000") {
        setIsAlert(true);
        setAlertMsg("لا يمكنك حذف السجل لارتباطه بسجلات اخرى.");
        setAlertName("alert-danger");
      }
      alertShow();
    } catch (error) {
      console.error(error);
    }
  };

  const alertShow = () => {
    const timer = setTimeout(() => setIsAlert(false), 3000);
    return () => {
      clearTimeout(timer);
    };
  };

  // end delete

  // edit
  const [editFormData, setEditFormData] = useState({
    SupplierID: null,
    Name: null,
    ContactName: null,
    Email: true,
    Phone: null,
    Address: null,
    Account_id: null,
    VatNumber: null,
    company_id: branchId,
    updated_by: userToken,
  });

  const editData = async (id) => {
    setEditedId(id);
    try {
      const response = await fetch(`${ACCOUNNTING_URL}/suppliers/`, {
        method: "PUT",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "x-api-key": userToken,
        },
        body: JSON.stringify(editFormData),
      });
      const json = await response.json();
      console.log(json);
      setIsAlert(true);
      setAlertMsg("تم تعديل السجل بنجاح");
      setAlertName("alert-success");
      alertShow();
      setEditedId(null);
      setIsRefresh(!isRefresh);
      setConfirmEditLoading(true);
      setTimeout(() => {
        setOpenEditModal(false);
        setConfirmEditLoading(false);
      }, 1000);
    } catch (error) {
      console.error(error);
    }
  };

  const editValidationFun = () => {
    if (
      editFormData.SupplierID !== null &&
      editFormData.Name !== null &&
      editFormData.ContactName !== null
    ) {
      editData(editFormData.SupplierID);
    } else {
      console.log("no valid");
      setIsAlert(true);
      setAlertMsg("ادخل جميع الحقول");
      setAlertName("alert-warning");
      setOpenEditModal(false);
      alertShow();
    }
  };

  function handleEdit(x, y) {
    console.log(x);
    setEditedId(x.SupplierID);
    setEditFormData((prev) => {
      return {
        ...prev,
        SupplierID: x.SupplierID,
        Name: x.Name,
        ContactName: x.ContactName,
        Email: x.Email,
        Phone: x.Phone,
        Address: x.Address,
        Account_id: x.Account_id,
        VatNumber: x.VatNumber,
        updated_by: userToken,
      };
    });
    setOpenEditModal(true);
  }

  function handleEditCancel() {
    setEditedId(null);
    setEditFormData(null);
    setOpenEditModal(false);
    setConfirmEditLoading(false);
  }

  // end edit

  // others

  const loader = () => {
    const timer = setTimeout(() => setIsloading(false), 1000);
    return () => {
      clearTimeout(timer);
    };
  };

  const Place = () => {
    const places = [];
    for (let i = 0; i < dataInPage; i++) {
      places.push(<CutPlaceHolder />);
    }
    return places;
  };

  console.log(formData);
  return (
    <div
      className={`${
        isloading
          ? "flex flex-row justify-center align-center "
          : " justify-space-between "
      } flex flex-column justify-center media-flex-culomn align-flex-start shadow-${theme} full-width-media border-radius text-align-center background-div-${theme} full-width min-height-300 p-20`}
      style={{ fontSize: "small" }}
    >
      <div className="full-width" style={{ minHeight: "580px" }}>
        <div className="flex flex-row justify-space-between align-center gap-10">
          {isAlert && <AlertComp msg={alertMsg} alert={alertName} />}

          <Modal
            key={1}
            title="إضافة مورد"
            open={openModal}
            onOk={validationFun}
            confirmLoading={confirmLoading}
            onCancel={() => setOpenModal(false)}
            destroyOnClose
          >
            <form
              className="flex flex-column justify-center align-center gap-20 media-flex-column full-width"
              style={{ padding: "20px" }}
            >
              {isAlert && <div className={`${alertName}`}>{alertMsg}</div>}
              {Object.entries(placeHolders)?.map(([key, value], index) => (
                <div className="full-width" key={index}>
                  <span>{value}</span>
                  <Input
                    type="text"
                    name={key}
                    className={`full-width height-50 border-radius background-div-${theme} color-${theme}`}
                    placeholder={value}
                    onChange={(e) => {
                      setFormData((prev) => ({
                        ...prev,
                        [key]: e.target.value,
                      }));
                    }}
                  />
                </div>
              ))}
              <div className="full-width">
                <span>الحساب الرئيسي</span>
                <Select
                  name="Account_id"
                  showSearch
                  className={`full-width height-50 border-radius`}
                  onChange={(e) => {
                    setFormData((prev) => {
                      return {
                        ...prev,
                        Account_id: e,
                      };
                    });
                  }}
                  optionFilterProp="label"
                  placeholder={isloading ? "جارٍ التحميل" : "الحساب الرئيسي"}
                  options={accountsList.map((item) => ({
                    disabled:
                      item.is_parent === "1" ||
                      item.is_parent === 1 ||
                      item.is_parent === null
                        ? true
                        : false,
                    value: item.id,
                    label: `${item.account_id} - ${item.account_name}`,
                  }))}
                />
              </div>
            </form>
          </Modal>
          <Modal
            key={2}
            title="تعديل مورد"
            open={openEditModal}
            onOk={editValidationFun}
            confirmLoading={confirmEditLoading}
            onCancel={handleEditCancel}
            destroyOnClose={true}
          >
            <form
              className="flex flex-column justify-center align-center gap-20 media-flex-column full-width"
              style={{ padding: "20px" }}
            >
              {isAlert && <div className={`${alertName}`}>{alertMsg}</div>}
              {Object.entries(placeHolders)?.map(([key, value], index) => (
                <div className="full-width" key={index}>
                  <span>{value}</span>
                  <Input
                    type="text"
                    name={key}
                    className={`full-width height-50 border-radius background-div-${theme} color-${theme}`}
                    defaultValue={editFormData && editFormData[key]}
                    placeholder={value}
                    onChange={(e) => {
                      setEditFormData((prev) => ({
                        ...prev,
                        [key]: e.target.value,
                      }));
                    }}
                  />
                </div>
              ))}
              <div className="full-width">
                <span>الحساب الرئيسي</span>
                <Select
                  name="Account_id"
                  showSearch
                  className={`full-width height-50 border-radius`}
                  defaultValue={editFormData?.Account_id}
                  onChange={(e) => {
                    setEditFormData((prev) => {
                      return {
                        ...prev,
                        Account_id: e,
                      };
                    });
                  }}
                  optionFilterProp="label"
                  placeholder={isloading ? "جارٍ التحميل" : "الحساب الرئيسي"}
                  options={accountsList.map((item) => ({
                    disabled:
                      item.is_parent === "1" ||
                      item.is_parent === 1 ||
                      item.is_parent === null
                        ? true
                        : false,
                    value: item.id,
                    label: `${item.account_id} - ${item.account_name}`,
                  }))}
                />
              </div>
            </form>
          </Modal>
        </div>
        <div
          className={`over-flow-scroll over-flow-scroll-${theme}`}
          style={{ overflow: "scroll", width: "100%", minHeight: "500px" }}
        >
          <ul
            className={`border-bottom-${theme}-3 font-bold`}
            style={{ minWidth: Object.entries(placeHolders).length * 200 + 300 + 'px', width: "100%", display: 'flex' }}
          >
            {Object.entries(placeHolders)?.map(([key, value], index) => (
              <li
                className="flex flex-row align-center justify-start"
                style={{ minWidth: "200px" }}
              >
                {[value]}
              </li>
            ))}

            <li
              className="flex flex-row align-center justify-start"
              style={{ minWidth: "150px", padding: "5px" }}
            >
              تعديل
            </li>
            <li
              className="flex flex-row align-center justify-start"
              style={{ minWidth: "150px", padding: "5px" }}
            >
              حذف
            </li>
          </ul>
          {isloading ? (
            <Place />
          ) : data.length === 0 ? (
            <h1>لا توجد بيانات</h1>
          ) : (
            data.map((item, index) => (
              <>
                <ul
                  className={`border-bottom-${theme}-1`}
                  key={index}
                  style={{ minWidth: Object.entries(placeHolders).length * 200 + 300 + 'px', width: "100%", display: 'flex' }}
                >
                  {Object.entries(placeHolders)?.map(([key, value], index) => (
                    <li
                      className="flex flex-row align-center justify-start"
                      style={{ minWidth: "200px" }}
                    >
                      {item[key]}
                    </li>
                  ))}

                  <li
                    className="flex flex-row align-center justify-start position-relative"
                    style={{ minWidth: "150px" }}
                  >
                    <Button
                      type="primary"
                      button="edit"
                      onClickMethod={() => handleEdit(item, true)}
                      buttonId={editedId}
                      id={item.ItemName}
                      disable={disable}
                      title={"تعديل"}
                      danger={false}
                      loading={isloading}
                    />
                  </li>
                  <li
                    className="flex flex-row align-center justify-start position-relative"
                    style={{ minWidth: "150px" }}
                  >
                    <Button
                      type="primary"
                      button="delete"
                      onClickMethod={() => deleteData(item.ItemName, true)}
                      buttonId={deleletedId}
                      id={item.ItemName}
                      disable={disable}
                      loading={isloading}
                      title={"حذف"}
                      danger={true}
                    />
                  </li>
                </ul>
              </>
            ))
          )}
        </div>
      </div>

      <div className="flex flex-row p-20">
        <Pagination
          defaultCurrent={currentPage}
          total={total}
          pageSize={dataInPage}
          showQuickJumper
          showSizeChanger
          onChange={(page, pageSize) => {
            setDataInPage(pageSize);
            setCurrentPage(page);
          }}
        />
      </div>
    </div>
  );
};

export default SuppliersComp;
