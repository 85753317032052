import React, { useState, useContext, useEffect, useCallback } from "react";
import { useImmer } from "use-immer";
import { AuthCountext } from "../AuthCountext";
import { useParams } from "react-router-dom";
import ViewComponent from "./ViewComponent";

const todyDate = new Date().toLocaleDateString();

const InvoicesVeiwComp = () => {
  const { ACCOUNNTING_URL, userToken, branchId } = useContext(AuthCountext);
  const { id } = useParams();
  const [isloading, setIsloading] = useState(true);
  const [formData, setFormData] = useImmer({
    BuyerID: null,
    Status: "Pending",
    TotalAmount: 0,
    TotalVat: 0,
    PaymentStatus: "Pending",
    InvoiceDate: null,
    CreatedBy: userToken,
    WarehouseID: 1,
    invoicedetails: [
      {
        id: Date.now(), // Unique ID
        ItemID: null,
        UnitID: null,
        Quantity: null,
        PricePerUnit: 0,
        TotalPrice: 0,
        Vat: 0,
        ConversionRate: 1,
      },
    ],
  });

  const fetchData = async () => {
    setIsloading(true);
    try {
      const response = await fetch(
        `${ACCOUNNTING_URL}/invoices/${id}/${branchId}`,
        {
          method: "GET",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "x-api-key": userToken,
          },
        }
      );
      const json = await response.json();
      if (json.msg !== "No data") {
        console.log(json);
        setFormData((draft) => {
          draft.updated_by = userToken;
          draft.updated_at = todyDate;
          draft.BuyerID = json?.invoice.BuyerID;
          draft.InvoiceID = json?.invoice.InvoiceID;
          draft.buyerName = json?.invoice.buyerName;
          draft.TotalAmount = json?.invoice.TotalAmount;
          draft.TotalVat = json?.invoice.TotalVat;
          draft.PaymentStatus = json?.invoice.PaymentStatus;
          draft.Status = json?.invoice.Status;
          draft.WarehouseID = json?.invoice.WarehouseID;
          draft.WarehouseName = json?.invoice.WarehouseName;
          draft.InvoiceDate = json?.invoice.InvoiceDate;
          draft.invoicedetails = json?.details;
        });
        loader();
      } else {
      }
      loader();
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    fetchData();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ACCOUNNTING_URL]);

  const loader = () => {
    const timer = setTimeout(() => setIsloading(false), 1000);
    return () => {
      clearTimeout(timer);
    };
  };

  loader();

  const roundFunction = useCallback((x) => {
    return Math.round((x + Number.EPSILON) * 100) / 100;
  }, []);

  const columns = [
    {
      title: "الصنف",
      value: "الصنف",
      dataIndex: "Name",
      dataKey: "Name",
    },
    {
      title: "الوحدة",
      value: "الوحدة",
      dataIndex: "UnitName",
      dataKey: "UnitName",
    },
    {
      title: "الكمية",
      value: "الكمية",
      dataIndex: "Quantity",
      dataKey: "Quantity",
      render: (text, data) => {
        return <span>{roundFunction(text * (data?.ConversionRate || 1))}</span>;
      },
    },
    {
      title: "سعر الوحدة",
      value: "سعر الوحدة",
      dataIndex: "PricePerUnit",
      dataKey: "PricePerUnit",
    },
    {
      title: "الضريبة",
      value: "الضريبة",
      dataIndex: "Vat",
      dataKey: "Vat",
    },
    {
      title: "الاجمالي",
      value: "الاجمالي",
      dataIndex: "TotalPrice",
      dataKey: "TotalPrice",
    },
  ];

  return (
    <ViewComponent
      title={"عرض أمر صرف مخزني - فاتورة عميل"}
      columns={columns}
      formData={formData}
      isloading={isloading}
      orderType={"invoice"}
      edit="invoicesEdit"
      id="InvoiceID"
      reportName={"buyerName"}
    />
  );
};

export default InvoicesVeiwComp;
