import React, { useContext, useState } from "react";
import { Link } from "react-router-dom";
import { AuthCountext } from "./AuthCountext";
import { HomeOutlined } from "@ant-design/icons";

function ParentComp({ children, title, prevPage, linkto, button }) {
  const { theme, setTitle } = useContext(AuthCountext);
  const [hoverMouse, setHoverMouse] = useState(false);
  const [hoverHomeMouse, setHoverHomeMouse] = useState(false);
  setTitle("نظام الرواتب | " + title);
  return (
    <>
      <div
        className="flex flex-row justify-space-between full-width font-500 media-paddin-top"
        style={{ paddingBlock: "40px", fontSize: "14px" }}
      >
        <div>
          <Link className={`text-decoration color-${theme}`} to={"/"}>
            <HomeOutlined
              className={`${
                hoverHomeMouse ? `hover-background-div-${theme} color-${theme}` : " color-gray"
              } text-decoration color-primary-${theme} `}
              style={{ borderRadius: "2px", padding: "5px" }}
              onMouseOver={() => setHoverHomeMouse(true)}
              onMouseOut={() => setHoverHomeMouse(false)}
            />

            <span> / </span>
          </Link>
          {linkto && linkto !== "/" && (
            <Link className={`text-decoration color-${theme}`} to={linkto}>
              <span
                className={`${
                  hoverMouse ? `hover-background-div-${theme} color-${theme}` : " color-gray"
                } text-decoration`}
                style={{ borderRadius: "2px" }}
                onMouseOver={() => setHoverMouse(true)}
                onMouseOut={() => setHoverMouse(false)}
              >
                {prevPage}
              </span>

              <span> / </span>
            </Link>
          )}
          <span className={`color-${theme} `}>{title}</span>
        </div>
        {button}
      </div>
      <div
        className={`flex flex-column align-center justify-start color-${theme} card-media-width media-align-self full-width`}
        style={{ flexGrow: "1" }}
      >
        {children}
      </div>
    </>
  );
}

export default ParentComp;
